import { Table } from 'antd'
import moment from 'moment'
import { FC, useMemo } from 'react'
import { TBusinessUserPii } from 'src/graphql'
import { TzService } from 'src/services'
import { renderColumns } from './columns'

interface IProps {
  user?: TBusinessUserPii | null
}

export const BusinessKybChangelog: FC<IProps> = ({ user }) => {
  const dataSource = useMemo(
    () => user?.currentKyb?.actionLogs?.sort(
      (log1, log2) => moment(log2.createTime).toDate().getTime() - moment(log1.createTime).toDate().getTime()
    ).map((log) => ({
      'added-time': TzService.format(log.createTime, 'HH:mm YYYY-MM-DD'),
      'added-by': log.subjectEmail,
      action: log.action,
      'previous-status-and-details': [log.prevAttemptState].filter(Boolean).join(' - '),
      'new-status-and-details': [log.currentAttemptState].filter(Boolean).join(' - '),
      comment: log.message
    })) || [],
    [user?.currentKyb?.actionLogs]
  )

  return (
    <section className="fx fx-column fx-extend">
      <Table
        bordered
        rowKey="id"
        className="fx-extend"
        pagination={false}
        dataSource={dataSource}
        columns={renderColumns()}
        scroll={{ x: true }}
      />
    </section>
  )
}
