import qs from 'qs'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { compareObj } from 'src/utils'

export const useQueryParams = <T = any>() => {
  const location = useLocation()
  const [queryParams, setQueryParams] = useState<T>(
    qs.parse(location.search.replace(/^\?+/, '')) as T
  )

  useEffect(() => {
    const params = qs.parse(location.search.replace(/^\?+/, '')) as T
    if (!compareObj(queryParams, params)) {
      setQueryParams(params)
    }
  }, [location.search, queryParams])

  return queryParams
}
