import { Breadcrumb } from 'antd'
import clsx from 'clsx'
import { FC, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { useBehaviorMapper } from 'src/hooks'
import { generate } from 'src/router'
import { BreadcrumbService } from 'src/services'

export const LayoutBreadcrumb: FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const items = useBehaviorMapper(BreadcrumbService.items$)
  const currentUrl = useMemo(() => `${location.pathname}${location.search}`, [location.pathname, location.search])

  return (
    <Breadcrumb className="noselect">
      {items.map(
        (item) => {
          const toUrl = item.route && generate(item.route)
          return (
            <Breadcrumb.Item
              key={item.key}
              className={clsx({ pointer: toUrl && toUrl !== currentUrl })}
              onClick={() => toUrl && toUrl !== currentUrl && navigate(toUrl)}
            >
              {item.icon && <item.icon/>}
              {item.label && <span className={item.icon && 'pl-1'}>{item.label}</span>}
            </Breadcrumb.Item>
          )
        }
      )}
    </Breadcrumb>
  )
}
