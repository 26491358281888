import { IRouterOptions } from 'src/router'
import { Forbidden } from './components/403'
import { NotFound } from './components/404'
import { InternalServerError } from './components/500'
import { Dashboard } from './components/dashboard'
import { Home } from './components/home'
import { EPaths } from './routes.path'

export enum ERoutes {
  HOME = 'HOME',
  ERROR_403 = 'ERROR_403',
  ERROR_404 = 'ERROR_404',
  ERROR_500 = 'ERROR_500',
  DASHBOARD = 'DASHBOARD'
}

export const routes: IRouterOptions = [
  {
    path: EPaths.HOME,
    name: ERoutes.HOME,
    component: Home
  },
  {
    path: EPaths.ERROR_403,
    name: ERoutes.ERROR_403,
    component: Forbidden
  },
  {
    path: EPaths.ERROR_404,
    name: ERoutes.ERROR_404,
    component: NotFound
  },
  {
    path: EPaths.ERROR_500,
    name: ERoutes.ERROR_500,
    component: InternalServerError
  },
  {
    path: EPaths.DASHBOARD,
    name: ERoutes.DASHBOARD,
    component: Dashboard
  }
]

export const CoreModule = {
  ERoutes,
  routes
}
