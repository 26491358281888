import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Button, Form, Input, Modal, Select } from 'antd'
import { ComponentProps, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { PiiApi } from 'src/api'
import { TAdminInvitee, TUserPii } from 'src/graphql'
import { useAsRef, useUnsubscribe } from 'src/hooks'
import { KYC_APPROVE_ATTENTION, KYC_REJECT_ATTENTION } from 'src/modules/market/consts'
import { MessageService } from 'src/services'

enum EAction {
  START_REVIEW = 'start_review',
  ON_HOLD = 'on_hold',
  APPROVE = 'approve',
  DENY = 'deny'
}

type FieldType = {
  action: Parameters<typeof PiiApi.reviewKYCAttempt>[0]['input']['action']
  message: string
}

export const DialogKycActions: FC<Omit<
  ComponentProps<typeof Modal>,
  'onOk' | 'afterClose'
> & {
  user: TUserPii
  action: `${EAction}`
  btnProps?: ComponentProps<typeof Button>
  afterClose?: (saved?: boolean) => any
}> = ({
  user,
  action,
  btnProps,
  ...props
}) => {
  const unsubscribe$ = useUnsubscribe()
  const [loading, setLoading] = useState(false)
  const [invisible, setInvisible] = useState<boolean | TAdminInvitee>(true)
  const [modal, contextHolder] = Modal.useModal()

  useEffect(() => {
    setInvisible(!props.open)
  }, [props.open])

  const afterCloseRef = useAsRef(props.afterClose)

  const actionsOptions = useMemo(() => {
    switch (action) {
      case EAction.START_REVIEW:
        return [
          { value: 'escalate', label: 'Escalate' },
          { value: 'in_progress', label: 'In progress' },
          { value: 'request_info', label: 'Request info' }
        ]
      case EAction.DENY:
        return [
          { value: 'deny_black_list', label: 'Blacklist' },
          { value: 'deny_close', label: 'Close' },
          { value: 'deny_reject', label: 'Reject' }
        ]
      // case EAction.ON_HOLD:
      //   return [
      //     { value: 'escalate', label: 'Escalate' },
      //     { value: 'in_progress', label: 'In progress' },
      //     { value: 'request_info', label: 'Request info' }
      //   ]
      // case EAction.APPROVE:
      //   return [
      //     { value: 'escalate', label: 'Escalate' },
      //     { value: 'in_progress', label: 'In progress' },
      //     { value: 'request_info', label: 'Request info' }
      //   ]
      default:
        return []
    }
  }, [action])

  const messageLabel = useMemo(() => {
    switch (action) {
      case EAction.START_REVIEW:
        return 'Your comment'
      case EAction.DENY:
        return 'Deny reason'
      // case EAction.ON_HOLD:
      // case EAction.APPROVE:
      default:
        return 'Comment'
    }
  }, [action])

  const [form] = Form.useForm<FieldType>()
  useEffect(() => {
    if (!invisible) {
      form.resetFields()
    }
  }, [invisible, form, action])

  const confirm = useCallback(() => new Promise((resolve) => {
    const content = {
      [EAction.APPROVE]: KYC_APPROVE_ATTENTION,
      [EAction.ON_HOLD]: KYC_REJECT_ATTENTION,
      [EAction.DENY]: KYC_REJECT_ATTENTION
    }[action as string]

    const title = {
      [EAction.APPROVE]: 'Are you sure approve KYC?',
      [EAction.ON_HOLD]: 'Are you sure on hold KYC?',
      [EAction.DENY]: 'Are you sure deny KYC?'
    }[action as string]

    if (!content) {
      return resolve(1)
    }

    return modal.confirm({
      title,
      icon: <ExclamationCircleOutlined/>,
      content,
      okText: 'Confirm',
      cancelText: 'Cancel',
      okButtonProps: {
        danger: [EAction.ON_HOLD, EAction.DENY].includes(action as EAction)
      },
      onOk: () => resolve(1),
      onCancel: () => resolve(0)
    })
  }), [action, modal])

  const submit = useCallback(async (values: FieldType) => {
    const attemptID = user?.currentKyc?.id as string
    if (!attemptID) {
      return
    }

    const confirmed = await confirm()
    if (!confirmed) {
      return
    }

    setLoading(true)
    from(PiiApi.reviewKYCAttempt({
      input: {
        attemptID,
        action: [EAction.ON_HOLD, EAction.APPROVE].includes(action as EAction)
          ? action as FieldType['action']
          : values.action,
        message: values.message
      }
    }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          MessageService.error(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(() => {
        MessageService.info('Update successfully')
        setInvisible(true)
        afterCloseRef.current?.(true)
      })
  }, [action, afterCloseRef, confirm, unsubscribe$, user?.currentKyc?.id])

  return (
    <>
      <Button
        type="primary"
        {...btnProps}
        onClick={() => setInvisible(false)}
      >
        {props.children || 'Action'}
      </Button>

      <Modal
        title={props.title || 'Status Review'}
        open={!invisible}
        centered
        closable={!loading} // display X icon
        keyboard={false} // disable close on press ESC
        maskClosable={false} // disable close on click outside
        okButtonProps={{
          disabled: loading,
          danger: [EAction.ON_HOLD, EAction.DENY].includes(action as EAction)
        }}
        cancelButtonProps={{ disabled: loading }}
        okText={{
          [EAction.ON_HOLD]: 'Confirm',
          [EAction.APPROVE]: 'Confirm',
          [EAction.DENY]: 'Confirm'
        }[action as string] || 'Ok'}
        cancelText="Cancel"
        onOk={() => form.submit()}
        onCancel={() => setInvisible(true)}
      >
        {!invisible && (
          <Form
            form={form}
            name="kyc-actions"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            onFinish={submit}
            autoComplete="off"
          >
            {!!actionsOptions.length && (
              <Form.Item<FieldType>
                label="Select status"
                name="action"
                rules={[{ required: true }]}
              >
                <Select allowClear>
                  {actionsOptions.map((option) => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}

            <Form.Item<FieldType>
              label={messageLabel}
              name="message"
              rules={[{ required: true }]}
            >
              <Input.TextArea/>
            </Form.Item>
          </Form>
        )}
      </Modal>

      {contextHolder}
    </>
  )
}
