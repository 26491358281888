import { FC, useEffect } from 'react'
import { useParams } from 'react-router'
import { BreadcrumbService } from 'src/services'
import { EPaths } from '../routes.path'

export const SettingDetail: FC = () => {
  const { id } = useParams<{ id: string }>()

  /**
   * breadcrumb
   */
  useEffect(() => {
    BreadcrumbService.items = [
      {
        route: EPaths.SETTING,
        label: 'Settings'
      },
      {
        route: [EPaths.SETTING_DETAIL, { id }],
        label: 'Detail'
      }
    ]
    return () => {
      BreadcrumbService.items = []
    }
  }, [id])

  return (
    <section>
      <b>Feature under development</b>
    </section>
  )
}
