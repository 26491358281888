import { StakeholderIdentitiesQuery, TStakeholderIdentities, stakeholderIdentitiesQuery } from 'src/graphql'
import { EdgeGraphqlService } from 'src/services'

export class StakeholderApi {
  /**
   * SECTION: Queries
   */

  static stakeholderIdentities(variables: StakeholderIdentitiesQuery['variables']) {
    return EdgeGraphqlService.queryAsPromise<StakeholderIdentitiesQuery>(stakeholderIdentitiesQuery, variables).then(
      (response) => response.stakeholderIdentities.stakeholders as TStakeholderIdentities[]
    )
  }

  /**
   * SECTION: Mutations
   */
}
