import { graphql } from 'relay-runtime'

import { CreateArticleMutation } from './__generated__/CreateArticleMutation.graphql'

export type { CreateArticleMutation }

export const createArticleMutation = graphql`
  mutation CreateArticleMutation($input: CreateArticleInput!) {
    createArticle(input: $input) {
      code
      message
      article {
        ... on Article {
          id
          createTime
          updateTime
          url
          title
          description
          activeStatus
          shortLink {
            ... on ShortLink {
              id
              createTime
              updateTime
              articleID
              url
              activeStatus
            }
          }
        }
      }
    }
  }
`
