import { TzService } from 'src/services'

export const renderColumns = () => [
  {
    title: 'Update time',
    dataIndex: 'updateTime',
    key: 'updateTime',
    render: (value: string) => TzService.format(value, 'HH:mm YYYY-MM-DD'),
    className: 'break-all min-w-200'
  },
  {
    title: 'From state',
    dataIndex: 'fromState',
    key: 'fromState',
    className: 'break-all min-w-150'
  },
  {
    title: 'To state',
    dataIndex: 'toState',
    key: 'toState',
    className: 'break-all min-w-150'
  },
  {
    title: 'Comment',
    dataIndex: 'comment',
    key: 'comment',
    className: 'break-all min-w-200'
  },
  {
    title: 'Reason',
    dataIndex: 'reason',
    key: 'reason',
    className: 'break-all min-w-150'
  },
  {
    title: 'Initiated by',
    dataIndex: 'initiatedBy',
    key: 'initiatedBy',
    className: 'break-all min-w-200'
  },
  {
    title: 'Initiator type',
    dataIndex: 'initiatorType',
    key: 'initiatorType',
    className: 'break-all min-w-200'
  }
]
