import { InfoCircleOutlined } from '@ant-design/icons'
import { Button, Modal, Table } from 'antd'
import { ComponentProps, FC, useEffect, useMemo, useState } from 'react'

export const DialogKycEditedInfo: FC<Omit<
  ComponentProps<typeof Modal>,
  'onOk' | 'afterClose'
> & {
  btnProps?: ComponentProps<typeof Button>
  data?: string | null
}> = ({
  btnProps,
  data,
  ...props
}) => {
  const [invisible, setInvisible] = useState(true)
  useEffect(() => {
    setInvisible(!props.open)
  }, [props.open])

  const fieldChanges = useMemo(() => {
    try {
      const editInfo = JSON.parse(data || '{}')
      return Array.ensure(editInfo?.field_changes, [])
    } catch (e) {
      return []
    }
  }, [data])

  return (
    <>
      <Button
        size="small"
        type="link"
        {...btnProps}
        onClick={() => setInvisible(false)}
      >
        {props.children || <InfoCircleOutlined/>}
      </Button>

      <Modal
        title={props.title || 'Edited Info'}
        open={!invisible}
        centered
        closable // display X icon
        keyboard={false} // disable close on press ESC
        maskClosable={false} // disable close on click outside
        cancelText="Close"
        okButtonProps={{ style: { display: 'none' } }}
        onOk={() => setInvisible(true)}
        onCancel={() => setInvisible(true)}
      >
        <Table
          pagination={false}
          columns={[
            {
              title: 'Field Name',
              dataIndex: 'field_name',
              key: 'field_name',
              className: 'break-all',
              width: 200
            },
            {
              title: 'Old',
              dataIndex: 'old',
              key: 'old',
              className: 'break-all',
              width: 200
            },
            {
              title: 'New',
              dataIndex: 'new',
              key: 'new',
              className: 'break-all',
              width: 200
            }
          ]}
          dataSource={fieldChanges}
        />
      </Modal>
    </>
  )
}
