import { Button, Input, Select, Table } from 'antd'
import { isArray } from 'lodash'
import qs from 'qs'
import { ComponentProps, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Pagination } from 'src/atoms'
import { TUserPiiOverview, UserIdentitiesQuery, userIdentitiesQuery } from 'src/graphql'
import { useBehaviorMapper, useDidMountDebounce } from 'src/hooks'
import { BreadcrumbService, PaginationService, SearchParamKey, SearchParamsService } from 'src/services'
import { CountriesStore } from 'src/store'
import { StringUtils } from 'src/utils'
import { EPaths } from '../../routes.path'
import { renderColumns } from './columns'

const ONE_DAY = 24 * 60 * 60 * 1000

interface IProps {
  accountType?: 'business' | 'individual'
}

export const IndividualKycList: FC<IProps> = ({ accountType = 'individual' }) => {
  /**
   * breadcrumb
   */
  useEffect(() => {
    const route = accountType === 'business'
      ? EPaths.MARKET_BUSINESS_KYC
      : EPaths.MARKET_INDIVIDUAL_KYC
    const label = accountType === 'business'
      ? 'Business KYC'
      : 'Individual KYC'
    BreadcrumbService.items = [{
      route,
      label
    }]
    return () => {
      BreadcrumbService.items = []
    }
  }, [accountType])

  const _paginationService = useMemo(() => new PaginationService<TUserPiiOverview>(userIdentitiesQuery, { usePii: true }), [])
  const loading = useBehaviorMapper(_paginationService.loading$)
  const dataSource = useBehaviorMapper(_paginationService.items$)

  const updateTimeOptions = useMemo(() => [
    <Select.Option key={1} value={new Date(Date.now() - ONE_DAY).toISOString()}>
      Last 24 hours
    </Select.Option>,
    <Select.Option key={2} value={new Date(Date.now() - 7 * ONE_DAY).toISOString()}>
      Last 7 days
    </Select.Option>,
    <Select.Option key={3} value={new Date(Date.now() - 30 * ONE_DAY).toISOString()}>
      Last 1 month
    </Select.Option>
  ], [])

  const kycReviewStateOptions = useMemo(() => {
    return [
      'APPLICATION_NOT_STARTED',
      'APPLICATION_ONGOING',
      'PENDING',
      'IN_PROGRESS',
      'INFO_REQUESTED',
      'ESCALATED',
      'TERMINAL_BLACKLISTED',
      'RESTART_IDENTITY_CHECK_FAILED',
      'RESTART_CLOSED',
      'TERMINAL_IDENTITY_BLACKLISTED',
      'FAILURE_AUTO_TERMINAL_REPEATED',
      'RESTART_REJECTED',
      'SUCCESS_MANUAL',
      'SUCCESS_AUTO',
      'ON_HOLD'
    ].map((value) => (
      <Select.Option key={value} value={value}>
        {value}
      </Select.Option>
    ))
  }, [])

  const kycTagsValueOptions = useMemo(() => {
    return [
      'ID_ATTENTION',
      'DOCUMENT_ATTENTION',
      'ID_MISMATCH',
      'ID_EDITED',
      'POA_MISMATCH',
      'POA_EXPIRED',
      'POA_ATTENTION',
      'AML_HIT',
      'HIGH_RISK',
      'MEDIUM_RISK',
      'LOW_RISK',
      'IP_COUNTRY_MISMATCH',
      'PROHIBITED_COUNTRY',
      'UNSUPPORTED_DOCUMENT_TYPE'
    ].map((value) => (
      <Select.Option key={value} value={value}>
        {value}
      </Select.Option>
    ))
  }, [])

  const countriesOptions = useBehaviorMapper(CountriesStore.countriesOptions$)

  const [filter, setFilter] = useState<{
    kycTags?: string[]
    kycReviewState?: Array<Exclude<Exclude<UserIdentitiesQuery['variables']['where'], null | undefined>['kycReviewState'], null | undefined>>
    nationality?: Array<Exclude<Exclude<UserIdentitiesQuery['variables']['where'], null | undefined>['nationality'], null | undefined>>
    countryOfResidence?: string[]
    updateTimeGTE?: string
    keyword?: string
    adminKeyword?: string
  }>({
    ...qs.parse(SearchParamsService.getSearchParams(SearchParamKey.INDIVIDUAL_KYC), { ignoreQueryPrefix: true })
  })

  const computedWhere = useMemo<UserIdentitiesQuery['variables']['where']>(() => {
    const keywordTrimmed = filter.keyword?.trim()
    const adminKeywordTrimmed = filter.adminKeyword?.trim()
    return ({
      and: [
        {
          updateTimeGTE: filter.updateTimeGTE || undefined,
          kycReviewStateIn: filter.kycReviewState?.length ? filter.kycReviewState : undefined,
          nationalityIn: filter.nationality?.length ? filter.nationality : undefined,
          hasCurrentKycWith: filter.kycTags?.length || filter.countryOfResidence?.length || adminKeywordTrimmed
            ? [{
              and: filter.kycTags?.map((el) => ({
                hasKycTagsWith: [{ value: el as any }]
              })),
              countryOfResidenceIn: filter.countryOfResidence?.length ? filter.countryOfResidence : undefined,
              hasKycActionLogsWith: adminKeywordTrimmed
                ? [{
                  subjectEmailContainsFold: adminKeywordTrimmed,
                  subjectType: 'admin' as any
                }]
                : undefined
            }]
            : undefined
        },
        {
          or: keywordTrimmed
            ? [
              { emailContainsFold: keywordTrimmed },
              { firstNameContainsFold: keywordTrimmed },
              { lastNameContainsFold: keywordTrimmed },
              { accountIDContainsFold: keywordTrimmed }
            ]
            : undefined
        }
      ].filter((conditions) => Object.values(conditions).filter(Boolean).length > 0),
      type: accountType
    })
  }, [filter, accountType])

  const [computedOrderBy, setComputedOrderBy] = useState<UserIdentitiesQuery['variables']['orderBy']>(undefined)

  const fresh = useCallback(
    (params?: UserIdentitiesQuery['variables']) => _paginationService.fetch(params as any),
    [_paginationService]
  )

  useDidMountDebounce(() => {
    fresh({
      after: '',
      before: '',
      where: computedWhere,
      orderBy: computedOrderBy
    })
  }, 500, [computedWhere, computedOrderBy, fresh])

  useEffect(() => {
    fresh({
      after: '',
      before: '',
      where: computedWhere,
      orderBy: computedOrderBy
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onGoDetail = useCallback(() => {
    SearchParamsService.setSearchParams(SearchParamKey.INDIVIDUAL_KYC, qs.stringify(filter, { arrayFormat: 'brackets', skipNulls: true }))
  }, [filter])
  useEffect(() => {
    SearchParamsService.setSearchParams(SearchParamKey.INDIVIDUAL_KYC, '')
  }, [])

  const handleFiltersChange: ComponentProps<typeof Table>['onChange'] = (_, _filters, _sorter) => {
    const sorter = (isArray(_sorter) ? _sorter[0] : _sorter) || {}
    const orderBy = sorter.columnKey && sorter.order
      ? {
        direction: Object.freeze({
          ascend: 'ASC',
          descend: 'DESC'
        })[sorter.order!],
        field: sorter.columnKey
      }
      : undefined
    setComputedOrderBy(orderBy as typeof computedOrderBy)
  }

  return (
    <section className="fx fx-column fx-extend">
      <div className="fx fx-jc-space-between mb-2">
        <div className="fx fx-extend fx-ai-center gap-2 fx-wrap-wrap">
          <Input
            allowClear
            placeholder="Search by name, account ID & email"
            style={{ flex: '0 0 300px' }}
            readOnly={loading}
            value={filter.keyword}
            onChange={(e) => setFilter((prev) => ({
              ...prev,
              keyword: e.target.value
            }))}
          />

          <Select
            allowClear
            style={{ flex: '0 0 200px' }}
            value={filter.updateTimeGTE}
            placeholder="Select last update time range"
            onChange={(updateTimeGTE) => setFilter((prev) => ({
              ...prev,
              updateTimeGTE
            }))}
          >
            {updateTimeOptions}
          </Select>

          <Select
            allowClear
            mode="multiple"
            style={{ flex: '0 0 200px' }}
            placeholder="KYC status"
            value={filter.kycReviewState}
            onChange={(kycReviewState) => setFilter((prev) => ({
              ...prev,
              kycReviewState
            }))}
            maxTagCount="responsive"
          >
            {kycReviewStateOptions}
          </Select>

          <Select
            allowClear
            mode="multiple"
            style={{ flex: '0 0 200px' }}
            placeholder="KYC tags"
            value={filter.kycTags}
            onChange={(kycTags) => setFilter((prev) => ({
              ...prev,
              kycTags
            }))}
            maxTagCount="responsive"
          >
            {kycTagsValueOptions}
          </Select>

          <Select
            allowClear
            showSearch
            mode="multiple"
            style={{ flex: '0 0 200px' }}
            placeholder="Nationality"
            value={filter.nationality}
            onChange={(nationality) => setFilter((prev) => ({
              ...prev,
              nationality
            }))}
            maxTagCount="responsive"
            options={countriesOptions}
            filterOption={(input, option) => (
              StringUtils.containsWithoutDiacritics(option?.label, input) ||
              StringUtils.containsWithoutDiacritics(option?.value, input)
            )}
          />

          <Select
            allowClear
            showSearch
            mode="multiple"
            style={{ flex: '0 0 200px' }}
            placeholder="Country of residence"
            value={filter.countryOfResidence}
            onChange={(countryOfResidence) => setFilter((prev) => ({
              ...prev,
              countryOfResidence
            }))}
            maxTagCount="responsive"
            options={countriesOptions}
            filterOption={(input, option) => (
              StringUtils.containsWithoutDiacritics(option?.label, input) ||
              StringUtils.containsWithoutDiacritics(option?.value, input)
            )}
          />

          <Input
            allowClear
            placeholder="Search by portal user who handled"
            style={{ flex: '0 0 300px' }}
            readOnly={loading}
            value={filter.adminKeyword}
            onChange={(e) => setFilter((prev) => ({
              ...prev,
              adminKeyword: e.target.value
            }))}
          />

          <Button onClick={() => setFilter({})}>Clear</Button>
        </div>
      </div>

      <Table
        bordered
        rowKey="id"
        className="fx-extend"
        pagination={false}
        loading={loading}
        dataSource={dataSource}
        columns={renderColumns({ accountType, onGoDetail })}
        scroll={{ x: true }}
        onChange={handleFiltersChange}
      />

      <Pagination
        className="fx-as-end mt-3"
        disabled={loading}
        canPrev={_paginationService.canPrev}
        canNext={_paginationService.canNext}
        onPrev={() => _paginationService.prev({ where: computedWhere, orderBy: computedOrderBy })}
        onNext={() => _paginationService.next({ where: computedWhere, orderBy: computedOrderBy })}
      />
    </section>
  )
}
