import { Checkbox } from 'antd'
import clsx from 'clsx'
import { FC } from 'react'
import { EAdminPortalPermission, EAdminPortalPermissionTxt } from 'src/interfaces'

interface IProps {
  className?: string
  value?: string[] | null
  onChange?: (value?: string[]) => any
}

export const SelectPermissions: FC<IProps> = (props) => {
  const handleChange = (value: EAdminPortalPermission) => {
    const newValue = [...(props.value || [])] as EAdminPortalPermission[]
    const index = newValue.indexOf(value)

    if (index !== -1) {
      newValue.splice(index, 1)
    } else {
      newValue.push(value)
    }

    props.onChange?.(newValue)
  }

  return (
    <div className={clsx('fx fx-column gap-1', props.className)}>
      <span className="color-dark-text-3">Permissions</span>

      {Object.values(EAdminPortalPermission).map((permission) => (
        <Checkbox
          key={permission}
          checked={props.value?.includes(permission)}
          onChange={() => handleChange(permission)}
        >
          {EAdminPortalPermissionTxt[permission]}
        </Checkbox>
      ))}
    </div>
  )
}
