import { Button, Descriptions, DescriptionsProps, Modal, Space, Table, Typography } from 'antd'
import { ComponentProps, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { DocumentApi } from 'src/api'
import { EDocumentKey, EDocumentVisibility, TAccountDocuments, TAdminPortalUser, TStakeholderIdentities } from 'src/graphql'
import { useBehaviorMapper, useUnsubscribe } from 'src/hooks'
import { AuthService, MessageService } from 'src/services'
import { CountriesStore } from 'src/store'
import { DialogUploadDocuments } from '../dialog-upload-documents'
import { renderColumns } from './columns'

const { Title } = Typography

interface IStakeholder extends TStakeholderIdentities {
  docs: TAccountDocuments[]
}

export const ModalStakeholderDetail: FC<Omit<
  ComponentProps<typeof Modal>,
  'onOk' | 'afterClose'
> & {
  btnProps?: ComponentProps<typeof Button>
  accountID?: string
  stakeholder?: IStakeholder
  onFresh?: () => void
}> = ({
  btnProps,
  accountID,
  stakeholder,
  onFresh,
  ...props
}) => {
  const unsubscribe$ = useUnsubscribe()
  const user = useBehaviorMapper(AuthService.user$)
  const [invisible, setInvisible] = useState<boolean | TAdminPortalUser>(true)
  const [loading, setLoading] = useState(false)

  const individualInfo: DescriptionsProps['items'] = useMemo(() => [
    {
      label: 'Role',
      children: stakeholder?.role
    },
    {
      label: 'First name',
      children: stakeholder?.individual?.firstName
    },
    {
      label: 'Last name',
      children: stakeholder?.individual?.lastName
    },
    {
      label: 'Date of birth',
      children: stakeholder?.individual?.dateOfBirth
    },
    {
      label: 'Full address',
      children: stakeholder?.individual?.fullAddress
    },
    {
      label: 'Country of birth',
      children: CountriesStore.getCountryName(stakeholder?.individual?.countryOfBirth)
    },
    {
      label: 'Country of residence',
      children: CountriesStore.getCountryName(stakeholder?.individual?.countryOfResidence)
    },
    {
      label: 'Nationality',
      children: CountriesStore.getCountryName(stakeholder?.individual?.nationality)
    }
  ], [stakeholder])

  const entityInfo: DescriptionsProps['items'] = useMemo(() => [
    {
      label: 'Role',
      children: stakeholder?.role
    },
    {
      label: 'Company name',
      children: stakeholder?.entity?.companyName
    },
    {
      label: 'Company registration number',
      children: stakeholder?.entity?.companyRegistrationNumber
    },
    {
      label: 'Registered office address',
      children: stakeholder?.entity?.registeredOfficeAddress
    },
    {
      label: 'Date of incorporation',
      children: stakeholder?.entity?.dateOfIncorporation
    },
    {
      label: 'Country of incorporation',
      children: CountriesStore.getCountryName(stakeholder?.entity?.countryOfIncorporation)
    }
  ], [stakeholder])

  const items: DescriptionsProps['items'] = useMemo(() => {
    const items = stakeholder?.role === 'ENTITY' ? entityInfo : individualInfo

    return items.map(el => ({
      ...el,
      labelStyle: {
        width: '50%'
      }
    }))
  }, [entityInfo, individualInfo, stakeholder])

  const onUploadDocuments = useCallback(async (files: File[]) => {
    if (!user?.id || !accountID) {
      MessageService.error('Something went wrong!')
      return
    }

    const promise = (async () => {
      for (const file of files) {
        await DocumentApi.uploadDocument(file as unknown as File, {
          adminPortalUserID: user.id,
          accountID,
          visibility: EDocumentVisibility.STAKEHOLDER,
          documentKey: EDocumentKey.STAKEHOLDER_FILE_STAKEHOLDER_IDS,
          stakeholderID: stakeholder?.stakeholderID
        })
      }
    })()

    setLoading(true)

    from(promise)
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          MessageService.axiosError(error)
          return EMPTY
        }),
        finalize(() => {
          setLoading(false)
        })
      )
      .subscribe(() => {
        MessageService.success('Upload documents successfully')
        onFresh?.()
      })
  }, [unsubscribe$, user?.id, accountID, stakeholder?.stakeholderID, onFresh])

  useEffect(() => {
    setInvisible(!props.open)
  }, [props.open])

  return (
    <>
      <Button
        type="primary"
        {...btnProps}
        onClick={() => setInvisible(false)}
      >
        {props.children || 'Detail'}
      </Button>

      <Modal
        title={props.title || 'Stakeholder detail'}
        open={!invisible}
        centered
        width={1000}
        closable // display X icon
        keyboard // enable close on press ESC
        maskClosable // enable close on click outside
        onCancel={() => setInvisible(true)}
        onOk={() => setInvisible(true)}
        footer={(
          <Button onClick={() => setInvisible(true)}>Close</Button>
        )}
      >
        <Title level={5} className="mt-0">Profile</Title>
        <Descriptions layout="horizontal" size="small" column={1} bordered items={items}/>

        <Space align="center" className="my-4">
          <Title level={5} className="m-0">Documents</Title>

          <DialogUploadDocuments
            confirmLoading={loading}
            btnProps={{ type: 'default' }}
            onOk={(files) => {
              onUploadDocuments(files)
            }}
          >
            Upload
          </DialogUploadDocuments>
        </Space>
        <Table
          bordered
          className="fx-extend"
          pagination={false}
          // loading={loading}
          dataSource={stakeholder?.docs}
          columns={renderColumns()}
          scroll={{ x: true }}
        />
      </Modal>
    </>
  )
}
