import { CopyFilled } from '@ant-design/icons'
import { ClipboardService } from 'src/services'

export const renderColumns = () => [
  {
    title: 'CheckID',
    dataIndex: 'CheckID',
    key: 'CheckID',
    render: (value: string) => (
      <div className="fx gap-1">
        <a href={`https://portal.complycube.com/checks/${value}`} target="_blank" rel="noreferrer">
          {value}
        </a>
        <CopyFilled
          className="pointer"
          onClick={() => ClipboardService.copy(value)}
        />
      </div>
    )
  },
  {
    title: 'Type',
    dataIndex: 'Type',
    key: 'Type'
  },
  {
    title: 'Status',
    dataIndex: 'Status',
    key: 'Status'
  },
  {
    title: 'Outcome',
    dataIndex: 'Outcome',
    key: 'Outcome'
  }
]
