import { diacriticsMap } from 'src/constants'

export class StringUtils {
  static clearUnicode(str: string) {
    return String(str)
      .replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
      .replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
      .replace(/ì|í|ị|ỉ|ĩ/g, 'i')
      .replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
      .replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
      .replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
      .replace(/đ/g, 'd')
      .replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A')
      .replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E')
      .replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I')
      .replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O')
      .replace(/Ù|Ú|Ụ|Ủ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U')
      .replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y')
      .replace(/Đ/g, 'D')
  }

  static clearDiacritics(str: string) {
    // eslint-disable-next-line no-control-regex
    return str.replace(/[^\u0000-\u007E]/g, (a) => diacriticsMap[a] || a)
  }

  static contains(sourceText = '', searchText = '') {
    return sourceText
      .trim()
      .toLowerCase()
      .includes(searchText.trim().toLowerCase())
  }

  static containsWithoutDiacritics(sourceText = '', searchText = '') {
    return StringUtils.contains(
      StringUtils.clearDiacritics(sourceText),
      StringUtils.clearDiacritics(searchText)
    )
  }

  static compareLowerCase(str1?: string, str2?: string) {
    if (typeof str1 !== 'string' || typeof str2 !== 'string') {
      return !!str1 === !!str2
    }
    return str1.toLowerCase() === str2.toLowerCase()
  }
}
