/**
 * @generated SignedSource<<7851dab183c7b596997009ce23a55c27>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type KYBAttemptState = "DONE" | "NOT_STARTED" | "ONGOING" | "ON_HOLD" | "REJECTED" | "REVIEW_IN_PROGRESS" | "%future added value";
export type KYBDocumentType = "BENEFICIAL_OWNER_DECLARATION" | "BOARD_RESOLUTION" | "BUSINESS_DETAILS" | "CERT_INCORPORATION" | "CERT_INCUMBENCY" | "CORPORATE_STRUCTURE_OWNERSHIP" | "MEMORANDUM_ARTICLES" | "OTHER" | "PROOF_SOURCE_FUNDS" | "REGISTER_OF_DIRECTORS" | "REGISTER_OF_SHAREHOLDERS" | "STAKEHOLDER_DETAILS" | "%future added value";
export type AdminUploadBusinessDocumentInput = {
  documents?: ReadonlyArray<SubmitBusinessDocumentInput> | null;
  kybAttemptID: string;
};
export type SubmitBusinessDocumentInput = {
  comment?: string | null;
  fileName?: string | null;
  type: KYBDocumentType;
  url: string;
};
export type AdminUploadBusinessDocumentMutation$variables = {
  input: AdminUploadBusinessDocumentInput;
};
export type AdminUploadBusinessDocumentMutation$data = {
  readonly adminUploadBusinessDocument: {
    readonly data: {
      readonly businessAccountIdentityID: string;
      readonly createTime: any | null;
      readonly documents: ReadonlyArray<{
        readonly id: string;
        readonly type: KYBDocumentType;
        readonly url: string;
      }> | null;
      readonly id: string;
      readonly state: KYBAttemptState;
      readonly updateTime: any | null;
    };
  };
};
export type AdminUploadBusinessDocumentMutation = {
  response: AdminUploadBusinessDocumentMutation$data;
  variables: AdminUploadBusinessDocumentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "KYBAttemptStatus",
    "kind": "LinkedField",
    "name": "adminUploadBusinessDocument",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "KYBAttempt",
        "kind": "LinkedField",
        "name": "data",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updateTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "businessAccountIdentityID",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "state",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "KYBDocument",
            "kind": "LinkedField",
            "name": "documents",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "url",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AdminUploadBusinessDocumentMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AdminUploadBusinessDocumentMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "c41a36bd154dfeb00bd087cc5b58369e",
    "id": null,
    "metadata": {},
    "name": "AdminUploadBusinessDocumentMutation",
    "operationKind": "mutation",
    "text": "mutation AdminUploadBusinessDocumentMutation(\n  $input: AdminUploadBusinessDocumentInput!\n) {\n  adminUploadBusinessDocument(input: $input) {\n    data {\n      id\n      createTime\n      updateTime\n      businessAccountIdentityID\n      state\n      documents {\n        id\n        url\n        type\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "13f1eb805ce6c9c5c7157fcf4b2caf1d";

export default node;
