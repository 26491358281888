import { Button, Table } from 'antd'
import { FC, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useBehaviorMapper } from 'src/hooks'
import { ERoutes, generate } from 'src/router'
import { BreadcrumbService } from 'src/services'
import { CountriesStore } from 'src/store'
import { EPaths } from '../../routes.path'
import { renderColumns } from './columns'

export const CountryList: FC = () => {
  /**
   * breadcrumb
   */
  useEffect(() => {
    BreadcrumbService.items = [{
      route: EPaths.COUNTRY_CONFIGURATIONS,
      label: 'Country Configurations'
    }]
    return () => {
      BreadcrumbService.items = []
    }
  }, [])

  const navigate = useNavigate()
  const countryList = useBehaviorMapper(CountriesStore.supportedCountries$)
  const loading = useBehaviorMapper(CountriesStore.loading$)

  useEffect(() => {
    CountriesStore.fetchCountryList()
  }, [])

  return (
    <section className="fx fx-column fx-extend">
      <div className="fx fx-jc-space-between mb-2">
        <div className="fx fx-extend fx-ai-center gap-2"/>
        <div className="fx fx-ai-center gap-2">
          <Button
            type="primary"
            onClick={() => navigate(generate(ERoutes.COUNTRY_CONFIG_HISTORY))}
          >
            See history
          </Button>
        </div>
      </div>

      <Table
        bordered
        rowKey="id"
        className="fx-extend"
        loading={loading}
        dataSource={countryList}
        columns={renderColumns({ onFresh: () => CountriesStore.fetchCountryList() })}
        scroll={{ x: true }}
      />
    </section>
  )
}
