/**
 * @generated SignedSource<<b0ff8d50b8d8717581c74f7be6293c0c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type KYCReviewDocumentType = "driving_license" | "national_identity_card" | "other" | "passport" | "proof_of_address" | "proof_source_of_fund" | "%future added value";
export type CreateKYCReviewDocumentInput = {
  comment?: string | null;
  documentType: KYCReviewDocumentType;
  kycAttemptID: string;
  url: string;
};
export type CreateKYCReviewDocumentMutation$variables = {
  input: CreateKYCReviewDocumentInput;
};
export type CreateKYCReviewDocumentMutation$data = {
  readonly createKYCReviewDocument: {
    readonly comment: string | null;
    readonly createTime: any | null;
    readonly id: string;
    readonly kycAttemptID: string;
    readonly type: KYCReviewDocumentType;
    readonly updateTime: any | null;
    readonly uploaderUserID: string;
    readonly url: string;
  };
};
export type CreateKYCReviewDocumentMutation = {
  response: CreateKYCReviewDocumentMutation$data;
  variables: CreateKYCReviewDocumentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "KYCReviewDocument",
    "kind": "LinkedField",
    "name": "createKYCReviewDocument",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createTime",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "updateTime",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "kycAttemptID",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "uploaderUserID",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "comment",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateKYCReviewDocumentMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateKYCReviewDocumentMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c5df5b7fdbae980d472d9b38c3baad5c",
    "id": null,
    "metadata": {},
    "name": "CreateKYCReviewDocumentMutation",
    "operationKind": "mutation",
    "text": "mutation CreateKYCReviewDocumentMutation(\n  $input: CreateKYCReviewDocumentInput!\n) {\n  createKYCReviewDocument(input: $input) {\n    id\n    createTime\n    updateTime\n    kycAttemptID\n    uploaderUserID\n    url\n    type\n    comment\n  }\n}\n"
  }
};
})();

(node as any).hash = "8e9464d3e4653d2f8de527b499af809a";

export default node;
