import { Button, Popconfirm, Space } from 'antd'
import { TAdminPortalUser } from 'src/graphql'
import { getAdminPortalPermissionTxt } from 'src/interfaces'
import { DialogChangePermissions } from 'src/partials'
import { UserUtils } from 'src/utils'

interface Actions<T = TAdminPortalUser> {
  onFresh?: () => void
  onRemove: (record: T) => any
}

export const renderColumns = ({ onRemove, onFresh }: Actions) => [
  {
    title: 'User ID',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'User name',
    dataIndex: 'username',
    key: 'username',
    render: (_: any, record: TAdminPortalUser) => UserUtils.getFullName(record)
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email'
  },
  {
    title: 'Permissions',
    dataIndex: 'adminPortalUserPermissionsExtended',
    key: 'permissions',
    render: (value?: string[]) => (
      <Space direction="vertical">
        {value?.map(el => (
          <span key={el}>{getAdminPortalPermissionTxt(el)}</span>
        ))}
      </Space>
    )
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    render: (_: any, record: TAdminPortalUser) => (
      <Space>
        <DialogChangePermissions
          uid={record.id}
          value={record.adminPortalUserPermissionsExtended}
          btnProps={{
            size: 'small',
            type: 'link'
          }}
          afterClose={(fresh) => fresh && onFresh?.()}
        />

        {onRemove && (
          <Popconfirm
            title="Confirm to remove?"
            onConfirm={() => onRemove(record)}
            okText="Yes"
            cancelText="No"
          >
            <Button
              danger
              size="small"
              type="link"
            >
              Remove
            </Button>
          </Popconfirm>
        )}
      </Space>
    )
  }
]
