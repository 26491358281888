import { Table } from 'antd'
import { FC } from 'react'
import { TUser } from 'src/graphql'
import { renderColumns } from './columns'

interface IProps {
  user: TUser | null
  onFresh?: () => void
}

export const BusinessUserList: FC<IProps> = ({ user, onFresh }) => {
  return (
    <Table
      bordered
      rowKey="id"
      className="fx-extend"
      dataSource={user?.account.users as any}
      columns={renderColumns({ user })}
      scroll={{ x: true }}
    />
  )
}
