import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { EEnv } from 'src/constants'

export enum EResponseStatus {
  SUCCESS = 'success',
  FAILURE = 'failure'
}

/**
 * data to ensure Content-Type: application/json
 */
const ensureApplicationJson = (config: AxiosRequestConfig) => {
  if (!config.data) {
    config.data = {}
  }
  return config
}

const responseInterceptor = (response: AxiosResponse) => {
  if (response.data.status === EResponseStatus.FAILURE) {
    throw new AxiosError(
      response.data.message || response.data.error,
      '400',
      response.config,
      response.request,
      response
    )
  }

  return response
}

export const EdgeAxios = axios.create({
  baseURL: EEnv.REACT_APP_EDGE_HOST,
  withCredentials: true,
  headers: {
    adminportal: 'true',
    Accept: 'application/json, text/plain, */*',
    'Content-Type': 'application/json'
  }
})
EdgeAxios.interceptors.request.use(ensureApplicationJson)
EdgeAxios.interceptors.response.use(responseInterceptor)

export const RewardAxios = axios.create({
  baseURL: EEnv.REACT_APP_REWARD_HOST,
  withCredentials: true,
  headers: {
    adminportal: 'true',
    Accept: 'application/json, text/plain, */*',
    'Content-Type': 'application/json'
  }
})
RewardAxios.interceptors.request.use(ensureApplicationJson)
RewardAxios.interceptors.response.use(responseInterceptor)
