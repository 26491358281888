import { AppstoreOutlined, BranchesOutlined, DeploymentUnitOutlined, ForkOutlined, SettingOutlined, ShareAltOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons'
import { ComponentType } from 'react'
import { matchPath } from 'react-router'
import { ERoutes, generate } from 'src/router'

// export const menus: IMenuItem[] = Array.from(
//   new Array(5),
//   (val, i) => ({
//     key: `menuItem${i + 1}`,
//     label: `Menu item ${i + 1}`,
//     pathname: `/menu-url-${i + 1}`,
//     subMenus: Array.from(
//       new Array(10),
//       (val, j) => ({
//         key: `menuItemSub${i * 10 + j + 1}`,
//         label: `Sub menu item ${i * 10 + j + 1}`,
//         pathname: `/menu-url-${i * 10 + j + 1}`
//       })
//     )
//   })
// )

export interface IMenuItem {
  key: string
  label: string
  pathname?: string
  icon?: ComponentType
  subMenus?: IMenuItem[]
  superAdmin?: boolean
}

export const menus: IMenuItem[] = [
  {
    key: ERoutes.DASHBOARD,
    label: 'Dashboard',
    icon: AppstoreOutlined,
    pathname: generate(ERoutes.DASHBOARD)
  },
  {
    key: ERoutes.ADMIN_MANAGEMENT,
    label: 'Admin Portal',
    icon: UserOutlined,
    pathname: generate(ERoutes.ADMIN_MANAGEMENT)
  },
  {
    key: ERoutes.MARKETING_ARTICLE,
    label: 'Marketing',
    icon: ShareAltOutlined,
    subMenus: [
      {
        key: 'Articles',
        label: 'Articles',
        icon: ForkOutlined,
        pathname: generate(ERoutes.MARKETING_ARTICLE)
      },
      {
        key: 'KOLTracker',
        label: 'KOL Tracker',
        icon: DeploymentUnitOutlined,
        pathname: generate(ERoutes.KOL_TRACKER)
      },
      {
        key: 'ShortLinks',
        label: 'Short Links',
        icon: BranchesOutlined,
        pathname: generate(ERoutes.MARKETING_SHORT_LINK)
      }
    ]
  },
  {
    key: 'gravity-markets',
    label: 'Gravity Markets',
    icon: TeamOutlined,
    subMenus: [
      {
        key: 'Country Configurations',
        label: 'Country Configurations',
        icon: AppstoreOutlined,
        pathname: generate(ERoutes.COUNTRY_CONFIGURATIONS)
      },
      {
        key: 'Business',
        label: 'Business',
        icon: AppstoreOutlined,
        pathname: generate(ERoutes.MARKET_BUSINESS)
      },
      {
        key: 'Individual',
        label: 'Individual',
        icon: AppstoreOutlined,
        pathname: generate(ERoutes.MARKET_INDIVIDUAL)
      },
      {
        key: 'Individual-kyc',
        label: 'Individual KYC',
        icon: AppstoreOutlined,
        pathname: generate(ERoutes.MARKET_INDIVIDUAL_KYC)
      },
      {
        key: 'Business-kyc',
        label: 'Business KYC',
        icon: AppstoreOutlined,
        pathname: generate(ERoutes.MARKET_BUSINESS_KYC)
      },
      {
        key: 'Business-kyb',
        label: 'Business KYB',
        icon: AppstoreOutlined,
        pathname: generate(ERoutes.MARKET_BUSINESS_KYB)
      }
    ]
  },
  // {
  //   key: ERoutes.EMAIL_TEMPLATES,
  //   label: 'Email Template',
  //   icon: MailOutlined,
  //   pathname: generate(ERoutes.EMAIL_TEMPLATES)
  // },
  {
    key: ERoutes.SETTING,
    label: 'Settings',
    icon: SettingOutlined,
    pathname: generate(ERoutes.SETTING)
  }
]

export const getOpenKeys = (pathname: string) => {
  const openKeys: string[] = []

  menus.forEach((menu) => {
    if (menu.subMenus) {
      menu.subMenus.forEach((subMenu) => {
        if (subMenu.pathname && matchPath(pathname, subMenu.pathname)) {
          openKeys.push(menu.key)
        }
      })
    } else if (menu.pathname && matchPath(pathname, menu.pathname)) {
      openKeys.push(menu.key)
    }
  })

  return openKeys
}
