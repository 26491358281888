import { BehaviorSubject, skip } from 'rxjs'
import { AuthApi } from 'src/api'
import { TAuthProfile } from 'src/graphql'
import { WithOutNextComplete } from 'types/rxjs'

export const AuthService = new (
  class {
    private readonly _isAuthenticated$ = new BehaviorSubject<boolean | null>(null)
    get isAuthenticated$(): WithOutNextComplete<typeof this._isAuthenticated$> {
      return this._isAuthenticated$
    }

    private readonly _user$ = new BehaviorSubject<TAuthProfile | null>(null)
    get user$(): WithOutNextComplete<typeof this._user$> {
      return this._user$
    }

    constructor() {
      this._user$
        .pipe(skip(1))
        .subscribe((val) => this._isAuthenticated$.next(!!val))
    }

    async signIn(payload: Parameters<typeof AuthApi.login>[0]) {
      await AuthApi.login(payload)
      await this.getProfile()
    }

    getProfile() {
      return AuthApi.profile()
        .then((profile) => this._user$.next(profile))
        .catch((error) => {
          this._user$.next(null)
          throw error
        })
    }

    logout() {
      return AuthApi.logout().finally(
        () => this._user$.next(null)
      )
    }
  }
)()
