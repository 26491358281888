import { graphql } from 'relay-runtime'

import { EditRiskScoreMutation } from './__generated__/EditRiskScoreMutation.graphql'

export type { EditRiskScoreMutation }

export const editRiskScoreMutation = graphql`
  mutation EditRiskScoreMutation($input: EditRiskScoreInput!) {
    editRiskScore(input: $input) {
      data {
        ... on KYCAttempt {
          id
          # createTime
          # updateTime
          # userIdentityID
          # state
          # countryOfResidence
          # userType
          # extractedInfoID
          # extractedInfo {
          #   ... on KYCExtractedInfo {
          #     id
          #     createTime
          #     ccCheckID
          #     kycAttemptID
          #     firstName
          #     lastName
          #     dateOfBirth
          #     nationality
          #     documentNumber
          #     documentType
          #   }
          # }
        }
      }
    }
  }
`
