/**
 * @generated SignedSource<<30c9ffaf76e211cd140149780c60f2d3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type UserIdentityQuery$variables = {
  id: string;
};
export type UserIdentityQuery$data = {
  readonly userIdentity: {
    readonly userIdentity: {
      readonly countryOfBirth: string | null;
      readonly countryOfResidence: string | null;
      readonly dateOfBirth: string | null;
      readonly firstName: string | null;
      readonly fullAddress: string | null;
      readonly lastName: string | null;
      readonly nationality: string | null;
    } | null;
  };
};
export type UserIdentityQuery = {
  response: UserIdentityQuery$data;
  variables: UserIdentityQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "UserIdentityStatus",
    "kind": "LinkedField",
    "name": "userIdentity",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserIdentity",
        "kind": "LinkedField",
        "name": "userIdentity",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fullAddress",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dateOfBirth",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nationality",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "countryOfBirth",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "countryOfResidence",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserIdentityQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserIdentityQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "bde0a6937ccdcd394114dd1670fabab8",
    "id": null,
    "metadata": {},
    "name": "UserIdentityQuery",
    "operationKind": "query",
    "text": "query UserIdentityQuery(\n  $id: ID!\n) {\n  userIdentity(id: $id) {\n    userIdentity {\n      lastName\n      firstName\n      fullAddress\n      dateOfBirth\n      nationality\n      countryOfBirth\n      countryOfResidence\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "edebaf9ac5679f67f42754e8af29b78c";

export default node;
