import { graphql } from 'relay-runtime'

import { UpdateArticleMutation } from './__generated__/UpdateArticleMutation.graphql'

export type { UpdateArticleMutation }

export const updateArticleMutation = graphql`
  mutation UpdateArticleMutation($id: ID!, $input: UpdateArticleInput!) {
    updateArticle(id: $id, input: $input) {
      code
      message
    }
  }
`
