import { Button, Modal } from 'antd'
import { ComponentProps, FC, useEffect, useState } from 'react'
import { ModalFullscreen } from 'src/atoms'
import { TAdminPortalUser } from 'src/graphql'

const validFileExtensions = ['jpg', 'jpeg', 'png', 'pdf']

export const DialogDocumentPreview: FC<Omit<
  ComponentProps<typeof Modal>,
  'onOk' | 'afterClose'
> & {
  url?: string | null
  fileExtension?: string
  btnProps?: ComponentProps<typeof Button>
}> = ({
  url,
  fileExtension,
  btnProps,
  ...props
}) => {
  const [invisible, setInvisible] = useState<boolean | TAdminPortalUser>(true)

  useEffect(() => {
    setInvisible(!props.open)
  }, [props.open])

  const isValid = fileExtension && validFileExtensions.includes(fileExtension)

  return (
    <>
      <Button
        type="primary"
        {...btnProps}
        onClick={() => setInvisible(false)}
        disabled={!isValid}
      >
        {props.children || 'Preview'}
      </Button>

      <ModalFullscreen
        title={props.title || 'Preview'}
        open={!invisible}
        centered
        closable // display X icon
        keyboard // enable close on press ESC
        maskClosable // enable close on click outside
        onCancel={() => setInvisible(true)}
        onOk={() => setInvisible(true)}
        footer={(
          <Button onClick={() => setInvisible(true)}>Close</Button>
        )}
      >
        {url && <iframe key={url} title={url} src={url} width="100%" height="100%" style={{ border: 'none' }}/>}
      </ModalFullscreen>
    </>
  )
}
