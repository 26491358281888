/**
 * @generated SignedSource<<badbef426e0c2a04b85c07106a0c3249>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AccountKybStatus = "failed" | "not_started" | "passed" | "replace_with_user_kyc" | "review" | "%future added value";
export type AccountReferralTier = "advocate" | "affiliate" | "agent" | "sub_affiliate" | "user" | "%future added value";
export type AccountStateTransitionFromState = "ACTIVE" | "CLOSED" | "DEACTIVATED" | "ON_HOLD" | "%future added value";
export type AccountStateTransitionInitiatorType = "ADMIN_PORTAL_USER" | "USER" | "%future added value";
export type AccountStateTransitionToState = "ACTIVE" | "CLOSED" | "DEACTIVATED" | "ON_HOLD" | "%future added value";
export type AdminPortalInviteeInvitationStatus = "accepted" | "cancelled" | "pending" | "%future added value";
export type AdminPortalPermissions = "ADMIN_PORTAL_ADMIN" | "ADMIN_PORTAL_COMPLIANCE" | "ADMIN_PORTAL_ENGINEERING" | "ADMIN_PORTAL_REFERRAL" | "ADMIN_PORTAL_USER" | "%future added value";
export type DocumentVisibility = "account" | "stakeholder" | "user" | "%future added value";
export type MultisigTransactionTransactionType = "AddAccountSigner" | "AddTransferAccount" | "AddWithdrawalAddress" | "DeactivateAccount" | "RemoveAccountSigner" | "RemoveTransferAccount" | "RemoveTransferSubAccount" | "RemoveWithdrawalAddress" | "SetAccountMultiSigThreshold" | "SetAccountSignerPermissions" | "%future added value";
export type UserComplyCubeOutcome = "attention" | "clear" | "not_started" | "%future added value";
export type UserKycStatus = "failed" | "not_started" | "passed" | "review" | "%future added value";
export type AdminPortalUserWhereInput = {
  and?: ReadonlyArray<AdminPortalUserWhereInput> | null;
  createTime?: any | null;
  createTimeGT?: any | null;
  createTimeGTE?: any | null;
  createTimeIn?: ReadonlyArray<any> | null;
  createTimeIsNil?: boolean | null;
  createTimeLT?: any | null;
  createTimeLTE?: any | null;
  createTimeNEQ?: any | null;
  createTimeNotIn?: ReadonlyArray<any> | null;
  createTimeNotNil?: boolean | null;
  email?: string | null;
  emailContains?: string | null;
  emailContainsFold?: string | null;
  emailEqualFold?: string | null;
  emailGT?: string | null;
  emailGTE?: string | null;
  emailHasPrefix?: string | null;
  emailHasSuffix?: string | null;
  emailIn?: ReadonlyArray<string> | null;
  emailLT?: string | null;
  emailLTE?: string | null;
  emailNEQ?: string | null;
  emailNotIn?: ReadonlyArray<string> | null;
  firstName?: string | null;
  firstNameContains?: string | null;
  firstNameContainsFold?: string | null;
  firstNameEqualFold?: string | null;
  firstNameGT?: string | null;
  firstNameGTE?: string | null;
  firstNameHasPrefix?: string | null;
  firstNameHasSuffix?: string | null;
  firstNameIn?: ReadonlyArray<string> | null;
  firstNameIsNil?: boolean | null;
  firstNameLT?: string | null;
  firstNameLTE?: string | null;
  firstNameNEQ?: string | null;
  firstNameNotIn?: ReadonlyArray<string> | null;
  firstNameNotNil?: boolean | null;
  hasAdminActionLog?: boolean | null;
  hasAdminActionLogWith?: ReadonlyArray<AdminActionLogWhereInput> | null;
  hasAdminPortalUser?: boolean | null;
  hasAdminPortalUserWith?: ReadonlyArray<AdminPortalInviteeWhereInput> | null;
  hasDocument?: boolean | null;
  hasDocumentWith?: ReadonlyArray<DocumentWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  lastName?: string | null;
  lastNameContains?: string | null;
  lastNameContainsFold?: string | null;
  lastNameEqualFold?: string | null;
  lastNameGT?: string | null;
  lastNameGTE?: string | null;
  lastNameHasPrefix?: string | null;
  lastNameHasSuffix?: string | null;
  lastNameIn?: ReadonlyArray<string> | null;
  lastNameIsNil?: boolean | null;
  lastNameLT?: string | null;
  lastNameLTE?: string | null;
  lastNameNEQ?: string | null;
  lastNameNotIn?: ReadonlyArray<string> | null;
  lastNameNotNil?: boolean | null;
  not?: AdminPortalUserWhereInput | null;
  or?: ReadonlyArray<AdminPortalUserWhereInput> | null;
  updateTime?: any | null;
  updateTimeGT?: any | null;
  updateTimeGTE?: any | null;
  updateTimeIn?: ReadonlyArray<any> | null;
  updateTimeIsNil?: boolean | null;
  updateTimeLT?: any | null;
  updateTimeLTE?: any | null;
  updateTimeNEQ?: any | null;
  updateTimeNotIn?: ReadonlyArray<any> | null;
  updateTimeNotNil?: boolean | null;
};
export type AdminPortalInviteeWhereInput = {
  adminPortalUserID?: string | null;
  adminPortalUserIDContains?: string | null;
  adminPortalUserIDContainsFold?: string | null;
  adminPortalUserIDEqualFold?: string | null;
  adminPortalUserIDGT?: string | null;
  adminPortalUserIDGTE?: string | null;
  adminPortalUserIDHasPrefix?: string | null;
  adminPortalUserIDHasSuffix?: string | null;
  adminPortalUserIDIn?: ReadonlyArray<string> | null;
  adminPortalUserIDLT?: string | null;
  adminPortalUserIDLTE?: string | null;
  adminPortalUserIDNEQ?: string | null;
  adminPortalUserIDNotIn?: ReadonlyArray<string> | null;
  and?: ReadonlyArray<AdminPortalInviteeWhereInput> | null;
  createTime?: any | null;
  createTimeGT?: any | null;
  createTimeGTE?: any | null;
  createTimeIn?: ReadonlyArray<any> | null;
  createTimeIsNil?: boolean | null;
  createTimeLT?: any | null;
  createTimeLTE?: any | null;
  createTimeNEQ?: any | null;
  createTimeNotIn?: ReadonlyArray<any> | null;
  createTimeNotNil?: boolean | null;
  email?: string | null;
  emailContains?: string | null;
  emailContainsFold?: string | null;
  emailEqualFold?: string | null;
  emailGT?: string | null;
  emailGTE?: string | null;
  emailHasPrefix?: string | null;
  emailHasSuffix?: string | null;
  emailIn?: ReadonlyArray<string> | null;
  emailLT?: string | null;
  emailLTE?: string | null;
  emailNEQ?: string | null;
  emailNotIn?: ReadonlyArray<string> | null;
  hasInvitingAdminPortalUser?: boolean | null;
  hasInvitingAdminPortalUserWith?: ReadonlyArray<AdminPortalUserWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  invitationStatus?: AdminPortalInviteeInvitationStatus | null;
  invitationStatusIn?: ReadonlyArray<AdminPortalInviteeInvitationStatus> | null;
  invitationStatusNEQ?: AdminPortalInviteeInvitationStatus | null;
  invitationStatusNotIn?: ReadonlyArray<AdminPortalInviteeInvitationStatus> | null;
  not?: AdminPortalInviteeWhereInput | null;
  or?: ReadonlyArray<AdminPortalInviteeWhereInput> | null;
  updateTime?: any | null;
  updateTimeGT?: any | null;
  updateTimeGTE?: any | null;
  updateTimeIn?: ReadonlyArray<any> | null;
  updateTimeIsNil?: boolean | null;
  updateTimeLT?: any | null;
  updateTimeLTE?: any | null;
  updateTimeNEQ?: any | null;
  updateTimeNotIn?: ReadonlyArray<any> | null;
  updateTimeNotNil?: boolean | null;
};
export type DocumentWhereInput = {
  accountID?: string | null;
  accountIDContains?: string | null;
  accountIDContainsFold?: string | null;
  accountIDEqualFold?: string | null;
  accountIDGT?: string | null;
  accountIDGTE?: string | null;
  accountIDHasPrefix?: string | null;
  accountIDHasSuffix?: string | null;
  accountIDIn?: ReadonlyArray<string> | null;
  accountIDIsNil?: boolean | null;
  accountIDLT?: string | null;
  accountIDLTE?: string | null;
  accountIDNEQ?: string | null;
  accountIDNotIn?: ReadonlyArray<string> | null;
  accountIDNotNil?: boolean | null;
  adminPortalUserID?: string | null;
  adminPortalUserIDContains?: string | null;
  adminPortalUserIDContainsFold?: string | null;
  adminPortalUserIDEqualFold?: string | null;
  adminPortalUserIDGT?: string | null;
  adminPortalUserIDGTE?: string | null;
  adminPortalUserIDHasPrefix?: string | null;
  adminPortalUserIDHasSuffix?: string | null;
  adminPortalUserIDIn?: ReadonlyArray<string> | null;
  adminPortalUserIDIsNil?: boolean | null;
  adminPortalUserIDLT?: string | null;
  adminPortalUserIDLTE?: string | null;
  adminPortalUserIDNEQ?: string | null;
  adminPortalUserIDNotIn?: ReadonlyArray<string> | null;
  adminPortalUserIDNotNil?: boolean | null;
  and?: ReadonlyArray<DocumentWhereInput> | null;
  createTime?: any | null;
  createTimeGT?: any | null;
  createTimeGTE?: any | null;
  createTimeIn?: ReadonlyArray<any> | null;
  createTimeIsNil?: boolean | null;
  createTimeLT?: any | null;
  createTimeLTE?: any | null;
  createTimeNEQ?: any | null;
  createTimeNotIn?: ReadonlyArray<any> | null;
  createTimeNotNil?: boolean | null;
  hasAccount?: boolean | null;
  hasAccountWith?: ReadonlyArray<AccountWhereInput> | null;
  hasAdminPortalUser?: boolean | null;
  hasAdminPortalUserWith?: ReadonlyArray<AdminPortalUserWhereInput> | null;
  hasUser?: boolean | null;
  hasUserWith?: ReadonlyArray<UserWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  name?: string | null;
  nameContains?: string | null;
  nameContainsFold?: string | null;
  nameEqualFold?: string | null;
  nameGT?: string | null;
  nameGTE?: string | null;
  nameHasPrefix?: string | null;
  nameHasSuffix?: string | null;
  nameIn?: ReadonlyArray<string> | null;
  nameLT?: string | null;
  nameLTE?: string | null;
  nameNEQ?: string | null;
  nameNotIn?: ReadonlyArray<string> | null;
  not?: DocumentWhereInput | null;
  or?: ReadonlyArray<DocumentWhereInput> | null;
  path?: string | null;
  pathContains?: string | null;
  pathContainsFold?: string | null;
  pathEqualFold?: string | null;
  pathGT?: string | null;
  pathGTE?: string | null;
  pathHasPrefix?: string | null;
  pathHasSuffix?: string | null;
  pathIn?: ReadonlyArray<string> | null;
  pathLT?: string | null;
  pathLTE?: string | null;
  pathNEQ?: string | null;
  pathNotIn?: ReadonlyArray<string> | null;
  updateTime?: any | null;
  updateTimeGT?: any | null;
  updateTimeGTE?: any | null;
  updateTimeIn?: ReadonlyArray<any> | null;
  updateTimeIsNil?: boolean | null;
  updateTimeLT?: any | null;
  updateTimeLTE?: any | null;
  updateTimeNEQ?: any | null;
  updateTimeNotIn?: ReadonlyArray<any> | null;
  updateTimeNotNil?: boolean | null;
  userID?: string | null;
  userIDContains?: string | null;
  userIDContainsFold?: string | null;
  userIDEqualFold?: string | null;
  userIDGT?: string | null;
  userIDGTE?: string | null;
  userIDHasPrefix?: string | null;
  userIDHasSuffix?: string | null;
  userIDIn?: ReadonlyArray<string> | null;
  userIDIsNil?: boolean | null;
  userIDLT?: string | null;
  userIDLTE?: string | null;
  userIDNEQ?: string | null;
  userIDNotIn?: ReadonlyArray<string> | null;
  userIDNotNil?: boolean | null;
  visibility?: DocumentVisibility | null;
  visibilityIn?: ReadonlyArray<DocumentVisibility> | null;
  visibilityNEQ?: DocumentVisibility | null;
  visibilityNotIn?: ReadonlyArray<DocumentVisibility> | null;
};
export type AccountWhereInput = {
  and?: ReadonlyArray<AccountWhereInput> | null;
  chainAccountAddress?: string | null;
  chainAccountAddressContains?: string | null;
  chainAccountAddressContainsFold?: string | null;
  chainAccountAddressEqualFold?: string | null;
  chainAccountAddressGT?: string | null;
  chainAccountAddressGTE?: string | null;
  chainAccountAddressHasPrefix?: string | null;
  chainAccountAddressHasSuffix?: string | null;
  chainAccountAddressIn?: ReadonlyArray<string> | null;
  chainAccountAddressIsNil?: boolean | null;
  chainAccountAddressLT?: string | null;
  chainAccountAddressLTE?: string | null;
  chainAccountAddressNEQ?: string | null;
  chainAccountAddressNotIn?: ReadonlyArray<string> | null;
  chainAccountAddressNotNil?: boolean | null;
  createTime?: any | null;
  createTimeGT?: any | null;
  createTimeGTE?: any | null;
  createTimeIn?: ReadonlyArray<any> | null;
  createTimeIsNil?: boolean | null;
  createTimeLT?: any | null;
  createTimeLTE?: any | null;
  createTimeNEQ?: any | null;
  createTimeNotIn?: ReadonlyArray<any> | null;
  createTimeNotNil?: boolean | null;
  displayName?: string | null;
  displayNameContains?: string | null;
  displayNameContainsFold?: string | null;
  displayNameEqualFold?: string | null;
  displayNameGT?: string | null;
  displayNameGTE?: string | null;
  displayNameHasPrefix?: string | null;
  displayNameHasSuffix?: string | null;
  displayNameIn?: ReadonlyArray<string> | null;
  displayNameIsNil?: boolean | null;
  displayNameLT?: string | null;
  displayNameLTE?: string | null;
  displayNameNEQ?: string | null;
  displayNameNotIn?: ReadonlyArray<string> | null;
  displayNameNotNil?: boolean | null;
  hasAccountSocials?: boolean | null;
  hasAccountSocialsWith?: ReadonlyArray<AccountSocialWhereInput> | null;
  hasAccountStateTransitions?: boolean | null;
  hasAccountStateTransitionsWith?: ReadonlyArray<AccountStateTransitionWhereInput> | null;
  hasContactPerson?: boolean | null;
  hasContactPersonWith?: ReadonlyArray<UserWhereInput> | null;
  hasDocument?: boolean | null;
  hasDocumentWith?: ReadonlyArray<DocumentWhereInput> | null;
  hasMultisigTransactions?: boolean | null;
  hasMultisigTransactionsWith?: ReadonlyArray<MultisigTransactionWhereInput> | null;
  hasRaffleDraws?: boolean | null;
  hasRaffleDrawsWith?: ReadonlyArray<RaffleDrawWhereInput> | null;
  hasRaffleReward?: boolean | null;
  hasRaffleRewardWith?: ReadonlyArray<RaffleRewardWhereInput> | null;
  hasRaffleTasks?: boolean | null;
  hasRaffleTasksWith?: ReadonlyArray<RaffleTaskWhereInput> | null;
  hasReferredAccount?: boolean | null;
  hasReferredAccountWith?: ReadonlyArray<AccountWhereInput> | null;
  hasReferringAccount?: boolean | null;
  hasReferringAccountWith?: ReadonlyArray<AccountWhereInput> | null;
  hasTransferAddressBook?: boolean | null;
  hasTransferAddressBookWith?: ReadonlyArray<TransferAddressBookWhereInput> | null;
  hasTransferRecipients?: boolean | null;
  hasTransferRecipientsWith?: ReadonlyArray<AccountWhereInput> | null;
  hasUsers?: boolean | null;
  hasUsersWith?: ReadonlyArray<UserWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  isBusiness?: boolean | null;
  isBusinessNEQ?: boolean | null;
  kybStatus?: AccountKybStatus | null;
  kybStatusIn?: ReadonlyArray<AccountKybStatus> | null;
  kybStatusNEQ?: AccountKybStatus | null;
  kybStatusNotIn?: ReadonlyArray<AccountKybStatus> | null;
  name?: string | null;
  nameContains?: string | null;
  nameContainsFold?: string | null;
  nameEqualFold?: string | null;
  nameGT?: string | null;
  nameGTE?: string | null;
  nameHasPrefix?: string | null;
  nameHasSuffix?: string | null;
  nameIn?: ReadonlyArray<string> | null;
  nameIsNil?: boolean | null;
  nameLT?: string | null;
  nameLTE?: string | null;
  nameNEQ?: string | null;
  nameNotIn?: ReadonlyArray<string> | null;
  nameNotNil?: boolean | null;
  not?: AccountWhereInput | null;
  or?: ReadonlyArray<AccountWhereInput> | null;
  referralCode?: string | null;
  referralCodeContains?: string | null;
  referralCodeContainsFold?: string | null;
  referralCodeEqualFold?: string | null;
  referralCodeGT?: string | null;
  referralCodeGTE?: string | null;
  referralCodeHasPrefix?: string | null;
  referralCodeHasSuffix?: string | null;
  referralCodeIn?: ReadonlyArray<string> | null;
  referralCodeLT?: string | null;
  referralCodeLTE?: string | null;
  referralCodeNEQ?: string | null;
  referralCodeNotIn?: ReadonlyArray<string> | null;
  referralTier?: AccountReferralTier | null;
  referralTierIn?: ReadonlyArray<AccountReferralTier> | null;
  referralTierNEQ?: AccountReferralTier | null;
  referralTierNotIn?: ReadonlyArray<AccountReferralTier> | null;
  updateTime?: any | null;
  updateTimeGT?: any | null;
  updateTimeGTE?: any | null;
  updateTimeIn?: ReadonlyArray<any> | null;
  updateTimeIsNil?: boolean | null;
  updateTimeLT?: any | null;
  updateTimeLTE?: any | null;
  updateTimeNEQ?: any | null;
  updateTimeNotIn?: ReadonlyArray<any> | null;
  updateTimeNotNil?: boolean | null;
};
export type UserWhereInput = {
  accountID?: string | null;
  accountIDContains?: string | null;
  accountIDContainsFold?: string | null;
  accountIDEqualFold?: string | null;
  accountIDGT?: string | null;
  accountIDGTE?: string | null;
  accountIDHasPrefix?: string | null;
  accountIDHasSuffix?: string | null;
  accountIDIn?: ReadonlyArray<string> | null;
  accountIDIsNil?: boolean | null;
  accountIDLT?: string | null;
  accountIDLTE?: string | null;
  accountIDNEQ?: string | null;
  accountIDNotIn?: ReadonlyArray<string> | null;
  accountIDNotNil?: boolean | null;
  and?: ReadonlyArray<UserWhereInput> | null;
  complyCubeDocumentID?: string | null;
  complyCubeDocumentIDContains?: string | null;
  complyCubeDocumentIDContainsFold?: string | null;
  complyCubeDocumentIDEqualFold?: string | null;
  complyCubeDocumentIDGT?: string | null;
  complyCubeDocumentIDGTE?: string | null;
  complyCubeDocumentIDHasPrefix?: string | null;
  complyCubeDocumentIDHasSuffix?: string | null;
  complyCubeDocumentIDIn?: ReadonlyArray<string> | null;
  complyCubeDocumentIDIsNil?: boolean | null;
  complyCubeDocumentIDLT?: string | null;
  complyCubeDocumentIDLTE?: string | null;
  complyCubeDocumentIDNEQ?: string | null;
  complyCubeDocumentIDNotIn?: ReadonlyArray<string> | null;
  complyCubeDocumentIDNotNil?: boolean | null;
  complyCubeID?: string | null;
  complyCubeIDContains?: string | null;
  complyCubeIDContainsFold?: string | null;
  complyCubeIDEqualFold?: string | null;
  complyCubeIDGT?: string | null;
  complyCubeIDGTE?: string | null;
  complyCubeIDHasPrefix?: string | null;
  complyCubeIDHasSuffix?: string | null;
  complyCubeIDIn?: ReadonlyArray<string> | null;
  complyCubeIDIsNil?: boolean | null;
  complyCubeIDLT?: string | null;
  complyCubeIDLTE?: string | null;
  complyCubeIDNEQ?: string | null;
  complyCubeIDNotIn?: ReadonlyArray<string> | null;
  complyCubeIDNotNil?: boolean | null;
  complyCubeOutcome?: UserComplyCubeOutcome | null;
  complyCubeOutcomeIn?: ReadonlyArray<UserComplyCubeOutcome> | null;
  complyCubeOutcomeNEQ?: UserComplyCubeOutcome | null;
  complyCubeOutcomeNotIn?: ReadonlyArray<UserComplyCubeOutcome> | null;
  createTime?: any | null;
  createTimeGT?: any | null;
  createTimeGTE?: any | null;
  createTimeIn?: ReadonlyArray<any> | null;
  createTimeIsNil?: boolean | null;
  createTimeLT?: any | null;
  createTimeLTE?: any | null;
  createTimeNEQ?: any | null;
  createTimeNotIn?: ReadonlyArray<any> | null;
  createTimeNotNil?: boolean | null;
  email?: string | null;
  emailContains?: string | null;
  emailContainsFold?: string | null;
  emailEqualFold?: string | null;
  emailGT?: string | null;
  emailGTE?: string | null;
  emailHasPrefix?: string | null;
  emailHasSuffix?: string | null;
  emailIn?: ReadonlyArray<string> | null;
  emailLT?: string | null;
  emailLTE?: string | null;
  emailNEQ?: string | null;
  emailNotIn?: ReadonlyArray<string> | null;
  firstName?: string | null;
  firstNameContains?: string | null;
  firstNameContainsFold?: string | null;
  firstNameEqualFold?: string | null;
  firstNameGT?: string | null;
  firstNameGTE?: string | null;
  firstNameHasPrefix?: string | null;
  firstNameHasSuffix?: string | null;
  firstNameIn?: ReadonlyArray<string> | null;
  firstNameIsNil?: boolean | null;
  firstNameLT?: string | null;
  firstNameLTE?: string | null;
  firstNameNEQ?: string | null;
  firstNameNotIn?: ReadonlyArray<string> | null;
  firstNameNotNil?: boolean | null;
  hasAccount?: boolean | null;
  hasAccountWith?: ReadonlyArray<AccountWhereInput> | null;
  hasDocument?: boolean | null;
  hasDocumentWith?: ReadonlyArray<DocumentWhereInput> | null;
  hasInitiatedMultisigTransactions?: boolean | null;
  hasInitiatedMultisigTransactionsWith?: ReadonlyArray<MultisigTransactionWhereInput> | null;
  hasSubAccountAuthorizations?: boolean | null;
  hasSubAccountAuthorizationsWith?: ReadonlyArray<SubAccountWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  isBusinessUser?: boolean | null;
  isBusinessUserNEQ?: boolean | null;
  kycStatus?: UserKycStatus | null;
  kycStatusIn?: ReadonlyArray<UserKycStatus> | null;
  kycStatusNEQ?: UserKycStatus | null;
  kycStatusNotIn?: ReadonlyArray<UserKycStatus> | null;
  lastName?: string | null;
  lastNameContains?: string | null;
  lastNameContainsFold?: string | null;
  lastNameEqualFold?: string | null;
  lastNameGT?: string | null;
  lastNameGTE?: string | null;
  lastNameHasPrefix?: string | null;
  lastNameHasSuffix?: string | null;
  lastNameIn?: ReadonlyArray<string> | null;
  lastNameIsNil?: boolean | null;
  lastNameLT?: string | null;
  lastNameLTE?: string | null;
  lastNameNEQ?: string | null;
  lastNameNotIn?: ReadonlyArray<string> | null;
  lastNameNotNil?: boolean | null;
  not?: UserWhereInput | null;
  or?: ReadonlyArray<UserWhereInput> | null;
  telegramHandle?: string | null;
  telegramHandleContains?: string | null;
  telegramHandleContainsFold?: string | null;
  telegramHandleEqualFold?: string | null;
  telegramHandleGT?: string | null;
  telegramHandleGTE?: string | null;
  telegramHandleHasPrefix?: string | null;
  telegramHandleHasSuffix?: string | null;
  telegramHandleIn?: ReadonlyArray<string> | null;
  telegramHandleIsNil?: boolean | null;
  telegramHandleLT?: string | null;
  telegramHandleLTE?: string | null;
  telegramHandleNEQ?: string | null;
  telegramHandleNotIn?: ReadonlyArray<string> | null;
  telegramHandleNotNil?: boolean | null;
  telegramID?: number | null;
  telegramIDGT?: number | null;
  telegramIDGTE?: number | null;
  telegramIDIn?: ReadonlyArray<number> | null;
  telegramIDIsNil?: boolean | null;
  telegramIDLT?: number | null;
  telegramIDLTE?: number | null;
  telegramIDNEQ?: number | null;
  telegramIDNotIn?: ReadonlyArray<number> | null;
  telegramIDNotNil?: boolean | null;
  updateTime?: any | null;
  updateTimeGT?: any | null;
  updateTimeGTE?: any | null;
  updateTimeIn?: ReadonlyArray<any> | null;
  updateTimeIsNil?: boolean | null;
  updateTimeLT?: any | null;
  updateTimeLTE?: any | null;
  updateTimeNEQ?: any | null;
  updateTimeNotIn?: ReadonlyArray<any> | null;
  updateTimeNotNil?: boolean | null;
};
export type SubAccountWhereInput = {
  accountID?: string | null;
  accountIDContains?: string | null;
  accountIDContainsFold?: string | null;
  accountIDEqualFold?: string | null;
  accountIDGT?: string | null;
  accountIDGTE?: string | null;
  accountIDHasPrefix?: string | null;
  accountIDHasSuffix?: string | null;
  accountIDIn?: ReadonlyArray<string> | null;
  accountIDLT?: string | null;
  accountIDLTE?: string | null;
  accountIDNEQ?: string | null;
  accountIDNotIn?: ReadonlyArray<string> | null;
  and?: ReadonlyArray<SubAccountWhereInput> | null;
  createTime?: any | null;
  createTimeGT?: any | null;
  createTimeGTE?: any | null;
  createTimeIn?: ReadonlyArray<any> | null;
  createTimeIsNil?: boolean | null;
  createTimeLT?: any | null;
  createTimeLTE?: any | null;
  createTimeNEQ?: any | null;
  createTimeNotIn?: ReadonlyArray<any> | null;
  createTimeNotNil?: boolean | null;
  hasAccount?: boolean | null;
  hasAccountWith?: ReadonlyArray<AccountWhereInput> | null;
  hasFavouriteFollowing?: boolean | null;
  hasFavouriteFollowingWith?: ReadonlyArray<SubAccountWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  name?: string | null;
  nameContains?: string | null;
  nameContainsFold?: string | null;
  nameEqualFold?: string | null;
  nameGT?: string | null;
  nameGTE?: string | null;
  nameHasPrefix?: string | null;
  nameHasSuffix?: string | null;
  nameIn?: ReadonlyArray<string> | null;
  nameLT?: string | null;
  nameLTE?: string | null;
  nameNEQ?: string | null;
  nameNotIn?: ReadonlyArray<string> | null;
  not?: SubAccountWhereInput | null;
  or?: ReadonlyArray<SubAccountWhereInput> | null;
  updateTime?: any | null;
  updateTimeGT?: any | null;
  updateTimeGTE?: any | null;
  updateTimeIn?: ReadonlyArray<any> | null;
  updateTimeIsNil?: boolean | null;
  updateTimeLT?: any | null;
  updateTimeLTE?: any | null;
  updateTimeNEQ?: any | null;
  updateTimeNotIn?: ReadonlyArray<any> | null;
  updateTimeNotNil?: boolean | null;
};
export type MultisigTransactionWhereInput = {
  accountID?: string | null;
  accountIDContains?: string | null;
  accountIDContainsFold?: string | null;
  accountIDEqualFold?: string | null;
  accountIDGT?: string | null;
  accountIDGTE?: string | null;
  accountIDHasPrefix?: string | null;
  accountIDHasSuffix?: string | null;
  accountIDIn?: ReadonlyArray<string> | null;
  accountIDLT?: string | null;
  accountIDLTE?: string | null;
  accountIDNEQ?: string | null;
  accountIDNotIn?: ReadonlyArray<string> | null;
  and?: ReadonlyArray<MultisigTransactionWhereInput> | null;
  createTime?: any | null;
  createTimeGT?: any | null;
  createTimeGTE?: any | null;
  createTimeIn?: ReadonlyArray<any> | null;
  createTimeIsNil?: boolean | null;
  createTimeLT?: any | null;
  createTimeLTE?: any | null;
  createTimeNEQ?: any | null;
  createTimeNotIn?: ReadonlyArray<any> | null;
  createTimeNotNil?: boolean | null;
  expiry?: number | null;
  expiryGT?: number | null;
  expiryGTE?: number | null;
  expiryIn?: ReadonlyArray<number> | null;
  expiryLT?: number | null;
  expiryLTE?: number | null;
  expiryNEQ?: number | null;
  expiryNotIn?: ReadonlyArray<number> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  initiatorUserID?: string | null;
  initiatorUserIDContains?: string | null;
  initiatorUserIDContainsFold?: string | null;
  initiatorUserIDEqualFold?: string | null;
  initiatorUserIDGT?: string | null;
  initiatorUserIDGTE?: string | null;
  initiatorUserIDHasPrefix?: string | null;
  initiatorUserIDHasSuffix?: string | null;
  initiatorUserIDIn?: ReadonlyArray<string> | null;
  initiatorUserIDLT?: string | null;
  initiatorUserIDLTE?: string | null;
  initiatorUserIDNEQ?: string | null;
  initiatorUserIDNotIn?: ReadonlyArray<string> | null;
  not?: MultisigTransactionWhereInput | null;
  or?: ReadonlyArray<MultisigTransactionWhereInput> | null;
  transactionType?: MultisigTransactionTransactionType | null;
  transactionTypeIn?: ReadonlyArray<MultisigTransactionTransactionType> | null;
  transactionTypeNEQ?: MultisigTransactionTransactionType | null;
  transactionTypeNotIn?: ReadonlyArray<MultisigTransactionTransactionType> | null;
  updateTime?: any | null;
  updateTimeGT?: any | null;
  updateTimeGTE?: any | null;
  updateTimeIn?: ReadonlyArray<any> | null;
  updateTimeIsNil?: boolean | null;
  updateTimeLT?: any | null;
  updateTimeLTE?: any | null;
  updateTimeNEQ?: any | null;
  updateTimeNotIn?: ReadonlyArray<any> | null;
  updateTimeNotNil?: boolean | null;
};
export type RaffleRewardWhereInput = {
  and?: ReadonlyArray<RaffleRewardWhereInput> | null;
  createTime?: any | null;
  createTimeGT?: any | null;
  createTimeGTE?: any | null;
  createTimeIn?: ReadonlyArray<any> | null;
  createTimeIsNil?: boolean | null;
  createTimeLT?: any | null;
  createTimeLTE?: any | null;
  createTimeNEQ?: any | null;
  createTimeNotIn?: ReadonlyArray<any> | null;
  createTimeNotNil?: boolean | null;
  hasAccount?: boolean | null;
  hasAccountWith?: ReadonlyArray<AccountWhereInput> | null;
  hasRarestBox?: boolean | null;
  hasRarestBoxWith?: ReadonlyArray<MysteryBoxWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  not?: RaffleRewardWhereInput | null;
  or?: ReadonlyArray<RaffleRewardWhereInput> | null;
  updateTime?: any | null;
  updateTimeGT?: any | null;
  updateTimeGTE?: any | null;
  updateTimeIn?: ReadonlyArray<any> | null;
  updateTimeIsNil?: boolean | null;
  updateTimeLT?: any | null;
  updateTimeLTE?: any | null;
  updateTimeNEQ?: any | null;
  updateTimeNotIn?: ReadonlyArray<any> | null;
  updateTimeNotNil?: boolean | null;
};
export type MysteryBoxWhereInput = {
  and?: ReadonlyArray<MysteryBoxWhereInput> | null;
  createTime?: any | null;
  createTimeGT?: any | null;
  createTimeGTE?: any | null;
  createTimeIn?: ReadonlyArray<any> | null;
  createTimeIsNil?: boolean | null;
  createTimeLT?: any | null;
  createTimeLTE?: any | null;
  createTimeNEQ?: any | null;
  createTimeNotIn?: ReadonlyArray<any> | null;
  createTimeNotNil?: boolean | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  not?: MysteryBoxWhereInput | null;
  or?: ReadonlyArray<MysteryBoxWhereInput> | null;
  updateTime?: any | null;
  updateTimeGT?: any | null;
  updateTimeGTE?: any | null;
  updateTimeIn?: ReadonlyArray<any> | null;
  updateTimeIsNil?: boolean | null;
  updateTimeLT?: any | null;
  updateTimeLTE?: any | null;
  updateTimeNEQ?: any | null;
  updateTimeNotIn?: ReadonlyArray<any> | null;
  updateTimeNotNil?: boolean | null;
};
export type AccountSocialWhereInput = {
  and?: ReadonlyArray<AccountSocialWhereInput> | null;
  createTime?: any | null;
  createTimeGT?: any | null;
  createTimeGTE?: any | null;
  createTimeIn?: ReadonlyArray<any> | null;
  createTimeIsNil?: boolean | null;
  createTimeLT?: any | null;
  createTimeLTE?: any | null;
  createTimeNEQ?: any | null;
  createTimeNotIn?: ReadonlyArray<any> | null;
  createTimeNotNil?: boolean | null;
  hasAccount?: boolean | null;
  hasAccountWith?: ReadonlyArray<AccountWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  not?: AccountSocialWhereInput | null;
  or?: ReadonlyArray<AccountSocialWhereInput> | null;
  updateTime?: any | null;
  updateTimeGT?: any | null;
  updateTimeGTE?: any | null;
  updateTimeIn?: ReadonlyArray<any> | null;
  updateTimeIsNil?: boolean | null;
  updateTimeLT?: any | null;
  updateTimeLTE?: any | null;
  updateTimeNEQ?: any | null;
  updateTimeNotIn?: ReadonlyArray<any> | null;
  updateTimeNotNil?: boolean | null;
};
export type RaffleTaskWhereInput = {
  and?: ReadonlyArray<RaffleTaskWhereInput> | null;
  createTime?: any | null;
  createTimeGT?: any | null;
  createTimeGTE?: any | null;
  createTimeIn?: ReadonlyArray<any> | null;
  createTimeIsNil?: boolean | null;
  createTimeLT?: any | null;
  createTimeLTE?: any | null;
  createTimeNEQ?: any | null;
  createTimeNotIn?: ReadonlyArray<any> | null;
  createTimeNotNil?: boolean | null;
  hasAccounts?: boolean | null;
  hasAccountsWith?: ReadonlyArray<AccountWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  not?: RaffleTaskWhereInput | null;
  or?: ReadonlyArray<RaffleTaskWhereInput> | null;
  updateTime?: any | null;
  updateTimeGT?: any | null;
  updateTimeGTE?: any | null;
  updateTimeIn?: ReadonlyArray<any> | null;
  updateTimeIsNil?: boolean | null;
  updateTimeLT?: any | null;
  updateTimeLTE?: any | null;
  updateTimeNEQ?: any | null;
  updateTimeNotIn?: ReadonlyArray<any> | null;
  updateTimeNotNil?: boolean | null;
};
export type RaffleDrawWhereInput = {
  and?: ReadonlyArray<RaffleDrawWhereInput> | null;
  createTime?: any | null;
  createTimeGT?: any | null;
  createTimeGTE?: any | null;
  createTimeIn?: ReadonlyArray<any> | null;
  createTimeIsNil?: boolean | null;
  createTimeLT?: any | null;
  createTimeLTE?: any | null;
  createTimeNEQ?: any | null;
  createTimeNotIn?: ReadonlyArray<any> | null;
  createTimeNotNil?: boolean | null;
  hasAccount?: boolean | null;
  hasAccountWith?: ReadonlyArray<AccountWhereInput> | null;
  hasCurrentBox?: boolean | null;
  hasCurrentBoxWith?: ReadonlyArray<MysteryBoxWhereInput> | null;
  hasResultBox?: boolean | null;
  hasResultBoxWith?: ReadonlyArray<MysteryBoxWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  not?: RaffleDrawWhereInput | null;
  or?: ReadonlyArray<RaffleDrawWhereInput> | null;
  updateTime?: any | null;
  updateTimeGT?: any | null;
  updateTimeGTE?: any | null;
  updateTimeIn?: ReadonlyArray<any> | null;
  updateTimeIsNil?: boolean | null;
  updateTimeLT?: any | null;
  updateTimeLTE?: any | null;
  updateTimeNEQ?: any | null;
  updateTimeNotIn?: ReadonlyArray<any> | null;
  updateTimeNotNil?: boolean | null;
};
export type AccountStateTransitionWhereInput = {
  accountID?: string | null;
  accountIDContains?: string | null;
  accountIDContainsFold?: string | null;
  accountIDEqualFold?: string | null;
  accountIDGT?: string | null;
  accountIDGTE?: string | null;
  accountIDHasPrefix?: string | null;
  accountIDHasSuffix?: string | null;
  accountIDIn?: ReadonlyArray<string> | null;
  accountIDLT?: string | null;
  accountIDLTE?: string | null;
  accountIDNEQ?: string | null;
  accountIDNotIn?: ReadonlyArray<string> | null;
  and?: ReadonlyArray<AccountStateTransitionWhereInput> | null;
  comment?: string | null;
  commentContains?: string | null;
  commentContainsFold?: string | null;
  commentEqualFold?: string | null;
  commentGT?: string | null;
  commentGTE?: string | null;
  commentHasPrefix?: string | null;
  commentHasSuffix?: string | null;
  commentIn?: ReadonlyArray<string> | null;
  commentIsNil?: boolean | null;
  commentLT?: string | null;
  commentLTE?: string | null;
  commentNEQ?: string | null;
  commentNotIn?: ReadonlyArray<string> | null;
  commentNotNil?: boolean | null;
  createTime?: any | null;
  createTimeGT?: any | null;
  createTimeGTE?: any | null;
  createTimeIn?: ReadonlyArray<any> | null;
  createTimeIsNil?: boolean | null;
  createTimeLT?: any | null;
  createTimeLTE?: any | null;
  createTimeNEQ?: any | null;
  createTimeNotIn?: ReadonlyArray<any> | null;
  createTimeNotNil?: boolean | null;
  fromState?: AccountStateTransitionFromState | null;
  fromStateIn?: ReadonlyArray<AccountStateTransitionFromState> | null;
  fromStateNEQ?: AccountStateTransitionFromState | null;
  fromStateNotIn?: ReadonlyArray<AccountStateTransitionFromState> | null;
  hasAccount?: boolean | null;
  hasAccountWith?: ReadonlyArray<AccountWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  initiatedBy?: string | null;
  initiatedByContains?: string | null;
  initiatedByContainsFold?: string | null;
  initiatedByEqualFold?: string | null;
  initiatedByGT?: string | null;
  initiatedByGTE?: string | null;
  initiatedByHasPrefix?: string | null;
  initiatedByHasSuffix?: string | null;
  initiatedByIn?: ReadonlyArray<string> | null;
  initiatedByLT?: string | null;
  initiatedByLTE?: string | null;
  initiatedByNEQ?: string | null;
  initiatedByNotIn?: ReadonlyArray<string> | null;
  initiatorType?: AccountStateTransitionInitiatorType | null;
  initiatorTypeIn?: ReadonlyArray<AccountStateTransitionInitiatorType> | null;
  initiatorTypeNEQ?: AccountStateTransitionInitiatorType | null;
  initiatorTypeNotIn?: ReadonlyArray<AccountStateTransitionInitiatorType> | null;
  not?: AccountStateTransitionWhereInput | null;
  or?: ReadonlyArray<AccountStateTransitionWhereInput> | null;
  reason?: string | null;
  reasonContains?: string | null;
  reasonContainsFold?: string | null;
  reasonEqualFold?: string | null;
  reasonGT?: string | null;
  reasonGTE?: string | null;
  reasonHasPrefix?: string | null;
  reasonHasSuffix?: string | null;
  reasonIn?: ReadonlyArray<string> | null;
  reasonIsNil?: boolean | null;
  reasonLT?: string | null;
  reasonLTE?: string | null;
  reasonNEQ?: string | null;
  reasonNotIn?: ReadonlyArray<string> | null;
  reasonNotNil?: boolean | null;
  toState?: AccountStateTransitionToState | null;
  toStateIn?: ReadonlyArray<AccountStateTransitionToState> | null;
  toStateNEQ?: AccountStateTransitionToState | null;
  toStateNotIn?: ReadonlyArray<AccountStateTransitionToState> | null;
  updateTime?: any | null;
  updateTimeGT?: any | null;
  updateTimeGTE?: any | null;
  updateTimeIn?: ReadonlyArray<any> | null;
  updateTimeIsNil?: boolean | null;
  updateTimeLT?: any | null;
  updateTimeLTE?: any | null;
  updateTimeNEQ?: any | null;
  updateTimeNotIn?: ReadonlyArray<any> | null;
  updateTimeNotNil?: boolean | null;
};
export type TransferAddressBookWhereInput = {
  and?: ReadonlyArray<TransferAddressBookWhereInput> | null;
  createTime?: any | null;
  createTimeGT?: any | null;
  createTimeGTE?: any | null;
  createTimeIn?: ReadonlyArray<any> | null;
  createTimeIsNil?: boolean | null;
  createTimeLT?: any | null;
  createTimeLTE?: any | null;
  createTimeNEQ?: any | null;
  createTimeNotIn?: ReadonlyArray<any> | null;
  createTimeNotNil?: boolean | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  name?: string | null;
  nameContains?: string | null;
  nameContainsFold?: string | null;
  nameEqualFold?: string | null;
  nameGT?: string | null;
  nameGTE?: string | null;
  nameHasPrefix?: string | null;
  nameHasSuffix?: string | null;
  nameIn?: ReadonlyArray<string> | null;
  nameLT?: string | null;
  nameLTE?: string | null;
  nameNEQ?: string | null;
  nameNotIn?: ReadonlyArray<string> | null;
  not?: TransferAddressBookWhereInput | null;
  or?: ReadonlyArray<TransferAddressBookWhereInput> | null;
  updateTime?: any | null;
  updateTimeGT?: any | null;
  updateTimeGTE?: any | null;
  updateTimeIn?: ReadonlyArray<any> | null;
  updateTimeIsNil?: boolean | null;
  updateTimeLT?: any | null;
  updateTimeLTE?: any | null;
  updateTimeNEQ?: any | null;
  updateTimeNotIn?: ReadonlyArray<any> | null;
  updateTimeNotNil?: boolean | null;
};
export type AdminActionLogWhereInput = {
  and?: ReadonlyArray<AdminActionLogWhereInput> | null;
  createTime?: any | null;
  createTimeGT?: any | null;
  createTimeGTE?: any | null;
  createTimeIn?: ReadonlyArray<any> | null;
  createTimeIsNil?: boolean | null;
  createTimeLT?: any | null;
  createTimeLTE?: any | null;
  createTimeNEQ?: any | null;
  createTimeNotIn?: ReadonlyArray<any> | null;
  createTimeNotNil?: boolean | null;
  hasAdminPortalUser?: boolean | null;
  hasAdminPortalUserWith?: ReadonlyArray<AdminPortalUserWhereInput> | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  not?: AdminActionLogWhereInput | null;
  or?: ReadonlyArray<AdminActionLogWhereInput> | null;
  updateTime?: any | null;
  updateTimeGT?: any | null;
  updateTimeGTE?: any | null;
  updateTimeIn?: ReadonlyArray<any> | null;
  updateTimeIsNil?: boolean | null;
  updateTimeLT?: any | null;
  updateTimeLTE?: any | null;
  updateTimeNEQ?: any | null;
  updateTimeNotIn?: ReadonlyArray<any> | null;
  updateTimeNotNil?: boolean | null;
  userID?: string | null;
  userIDContains?: string | null;
  userIDContainsFold?: string | null;
  userIDEqualFold?: string | null;
  userIDGT?: string | null;
  userIDGTE?: string | null;
  userIDHasPrefix?: string | null;
  userIDHasSuffix?: string | null;
  userIDIn?: ReadonlyArray<string> | null;
  userIDLT?: string | null;
  userIDLTE?: string | null;
  userIDNEQ?: string | null;
  userIDNotIn?: ReadonlyArray<string> | null;
};
export type AdminPortalUsersQuery$variables = {
  after?: any | null;
  before?: any | null;
  first?: number | null;
  last?: number | null;
  where?: AdminPortalUserWhereInput | null;
};
export type AdminPortalUsersQuery$data = {
  readonly adminPortalUsers: {
    readonly edges: ReadonlyArray<{
      readonly cursor: any;
      readonly node: {
        readonly adminPortalUserPermissionsExtended: ReadonlyArray<AdminPortalPermissions> | null;
        readonly attemptCount: number | null;
        readonly confirmed: boolean | null;
        readonly createTime: any | null;
        readonly email: string;
        readonly firstName: string | null;
        readonly id: string;
        readonly lastAttempt: any | null;
        readonly lastName: string | null;
        readonly locked: any | null;
        readonly oAuth2Provider: string | null;
        readonly oAuth2UID: string | null;
        readonly updateTime: any | null;
      } | null;
    } | null> | null;
    readonly pageInfo: {
      readonly endCursor: any | null;
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
      readonly startCursor: any | null;
    };
    readonly totalCount: number;
  };
};
export type AdminPortalUsersQuery = {
  response: AdminPortalUsersQuery$data;
  variables: AdminPortalUsersQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "before"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "where"
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "after",
        "variableName": "after"
      },
      {
        "kind": "Variable",
        "name": "before",
        "variableName": "before"
      },
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "last",
        "variableName": "last"
      },
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where"
      }
    ],
    "concreteType": "AdminPortalUserConnection",
    "kind": "LinkedField",
    "name": "adminPortalUsers",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "PageInfo",
        "kind": "LinkedField",
        "name": "pageInfo",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasNextPage",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasPreviousPage",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startCursor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endCursor",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminPortalUserEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cursor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AdminPortalUser",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "updateTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "confirmed",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "attemptCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastAttempt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "locked",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "oAuth2UID",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "oAuth2Provider",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "adminPortalUserPermissionsExtended",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AdminPortalUsersQuery",
    "selections": (v5/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "AdminPortalUsersQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "ecd39e29454df66173df976312adafbb",
    "id": null,
    "metadata": {},
    "name": "AdminPortalUsersQuery",
    "operationKind": "query",
    "text": "query AdminPortalUsersQuery(\n  $after: Cursor\n  $first: Int\n  $before: Cursor\n  $last: Int\n  $where: AdminPortalUserWhereInput\n) {\n  adminPortalUsers(after: $after, first: $first, before: $before, last: $last, where: $where) {\n    totalCount\n    pageInfo {\n      hasNextPage\n      hasPreviousPage\n      startCursor\n      endCursor\n    }\n    edges {\n      cursor\n      node {\n        id\n        createTime\n        updateTime\n        firstName\n        lastName\n        email\n        confirmed\n        attemptCount\n        lastAttempt\n        locked\n        oAuth2UID\n        oAuth2Provider\n        adminPortalUserPermissionsExtended\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "03a2e12b5154ca2e74db08c0e902056b";

export default node;
