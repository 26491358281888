/**
 * @generated SignedSource<<e2a3e9ec281e139db796d1bd72c50873>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AdminPortalInviteeInvitationStatus = "accepted" | "cancelled" | "pending" | "%future added value";
export type StatusCode = "ABORTED" | "ALREADY_EXISTS" | "CANCELLED" | "DATA_LOSS" | "DEADLINE_EXCEEDED" | "FAILED_PRECONDITION" | "INTERNAL" | "INVALID_ARGUMENT" | "NOT_FOUND" | "OK" | "OUT_OF_RANGE" | "PERMISSION_DENIED" | "RESOURCE_EXHAUSTED" | "UNAUTHENTICATED" | "UNAVAILABLE" | "UNIMPLEMENTED" | "UNKNOWN" | "%future added value";
export type CreateAdminPortalInviteeInput = {
  email: string;
  invitationStatus?: AdminPortalInviteeInvitationStatus | null;
  invitingAdminPortalUserID: string;
};
export type InviteAdminPortalUserMutation$variables = {
  input: CreateAdminPortalInviteeInput;
};
export type InviteAdminPortalUserMutation$data = {
  readonly inviteAdminPortalUser: {
    readonly code: StatusCode;
    readonly invitee: {
      readonly adminPortalUserID: string;
      readonly createTime: any | null;
      readonly email: string;
      readonly id: string;
      readonly invitationStatus: AdminPortalInviteeInvitationStatus;
      readonly updateTime: any | null;
    } | null;
    readonly message: string;
  };
};
export type InviteAdminPortalUserMutation = {
  response: InviteAdminPortalUserMutation$data;
  variables: InviteAdminPortalUserMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "InviteAdminPortalUserStatus",
    "kind": "LinkedField",
    "name": "inviteAdminPortalUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "code",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminPortalInvitee",
        "kind": "LinkedField",
        "name": "invitee",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updateTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "adminPortalUserID",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "invitationStatus",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InviteAdminPortalUserMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InviteAdminPortalUserMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d5acdc979857a0458fda5eb1d1b9729a",
    "id": null,
    "metadata": {},
    "name": "InviteAdminPortalUserMutation",
    "operationKind": "mutation",
    "text": "mutation InviteAdminPortalUserMutation(\n  $input: CreateAdminPortalInviteeInput!\n) {\n  inviteAdminPortalUser(input: $input) {\n    code\n    message\n    invitee {\n      id\n      createTime\n      updateTime\n      adminPortalUserID\n      email\n      invitationStatus\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "af966d9de9f41a74b265976a5705adbc";

export default node;
