import { Button, Modal, Table } from 'antd'
import { ComponentProps, FC, useEffect, useState } from 'react'
import { EDocumentKey, TAccountDocuments, TAdminPortalUser } from 'src/graphql'
import { renderColumns } from './columns'

export const DialogDocuments: FC<Omit<
  ComponentProps<typeof Modal>,
  'onOk' | 'afterClose'
> & {
  btnProps?: ComponentProps<typeof Button>
  onUploadDocuments?: (files: File[], documentKey: EDocumentKey) => void
  dataSource?: TAccountDocuments[]
}> = ({
  btnProps,
  onUploadDocuments,
  dataSource,
  ...props
}) => {
  const [invisible, setInvisible] = useState<boolean | TAdminPortalUser>(true)

  useEffect(() => {
    setInvisible(!props.open)
  }, [props.open])

  return (
    <>
      <Button
        type="link"
        size="small"
        {...btnProps}
        onClick={() => setInvisible(false)}
      >
        {props.children || 'Details'}
      </Button>

      <Modal
        title={props.title || 'Details'}
        open={!invisible}
        centered
        width={1000}
        closable // display X icon
        keyboard // enable close on press ESC
        maskClosable // enable close on click outside
        onCancel={() => setInvisible(true)}
        onOk={() => setInvisible(true)}
        footer={(
          <Button onClick={() => setInvisible(true)}>Close</Button>
        )}
      >
        <Table
          bordered
          className="fx-extend"
          pagination={false}
          dataSource={dataSource}
          columns={renderColumns({ onUploadDocuments })}
          scroll={{ x: true }}
        />
      </Modal>
    </>
  )
}
