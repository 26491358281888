/**
 * @generated SignedSource<<fb0d796d0b86e9bf7e5d8a5a50a3c4ac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type QuestionQuestionSet = "kyc_v1" | "%future added value";
export type QuestionType = "multiple_choice" | "single_choice" | "text" | "%future added value";
export type GetQuestionSetInput = {
  questionSet: QuestionQuestionSet;
};
export type QuestionSetQuery$variables = {
  input: GetQuestionSetInput;
};
export type QuestionSetQuery$data = {
  readonly questionSet: {
    readonly questionSetID: string;
    readonly questions: ReadonlyArray<{
      readonly display: string;
      readonly id: string;
      readonly options: ReadonlyArray<{
        readonly display: string;
        readonly id: string;
      }> | null;
      readonly type: QuestionType;
    }>;
  };
};
export type QuestionSetQuery = {
  response: QuestionSetQuery$data;
  variables: QuestionSetQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "display",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "QuestionSetOutput",
    "kind": "LinkedField",
    "name": "questionSet",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "questionSetID",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Question",
        "kind": "LinkedField",
        "name": "questions",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "QuestionAnswerOption",
            "kind": "LinkedField",
            "name": "options",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QuestionSetQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QuestionSetQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "b4cba6581883b8da1e673f7d4526a18c",
    "id": null,
    "metadata": {},
    "name": "QuestionSetQuery",
    "operationKind": "query",
    "text": "query QuestionSetQuery(\n  $input: GetQuestionSetInput!\n) {\n  questionSet(input: $input) {\n    questionSetID\n    questions {\n      id\n      display\n      type\n      options {\n        id\n        display\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f6e30596822b841195b98e85c8afcc35";

export default node;
