import { ArticlesQuery, CreateArticleMutation, RemoveArticleMutation, TArticle, UpdateArticleMutation, articlesQuery, createArticleMutation, removeArticleMutation, updateArticleMutation } from 'src/graphql'
import { EdgeGraphqlService } from 'src/services'

export class ArticleApi {
  /**
   * SECTION: Queries
   */

  static find(id: Required<DeepExclude<ArticlesQuery['variables'], null>>['where']['id']) {
    return EdgeGraphqlService.queryAsPromise<ArticlesQuery>(articlesQuery, { where: { id } }).then(
      (response) => {
        const article = response.articles.edges?.[0]?.node as TArticle
        if (!article) {
          throw new Error('Article not found')
        }

        return article
      }
    )
  }

  /**
   * SECTION: Mutations
   */

  static create(variables: CreateArticleMutation['variables']) {
    return EdgeGraphqlService.mutationAsPromise<CreateArticleMutation>(createArticleMutation, variables).then(
      (response) => response.createArticle
    )
  }

  static update(variables: UpdateArticleMutation['variables']) {
    return EdgeGraphqlService.mutationAsPromise<UpdateArticleMutation>(updateArticleMutation, variables).then(
      (response) => response.updateArticle
    )
  }

  static remove(variables: RemoveArticleMutation['variables']) {
    return EdgeGraphqlService.mutationAsPromise<RemoveArticleMutation>(removeArticleMutation, variables).then(
      (response) => response.removeArticle
    )
  }
}
