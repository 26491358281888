/**
 * @generated SignedSource<<af734edf95cdb8bc47a4b7f40704c91d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type KYCReviewDocumentType = "driving_license" | "national_identity_card" | "other" | "passport" | "proof_of_address" | "proof_source_of_fund" | "%future added value";
export type GenerateKYCReviewDocumentSignedURLInput = {
  documentType: KYCReviewDocumentType;
  fileName: string;
  kycAttemptID: string;
};
export type KycReviewDocumentsSignedURLQuery$variables = {
  input: GenerateKYCReviewDocumentSignedURLInput;
};
export type KycReviewDocumentsSignedURLQuery$data = {
  readonly kycReviewDocumentsSignedURL: {
    readonly key: string;
    readonly signedURL: string;
  };
};
export type KycReviewDocumentsSignedURLQuery = {
  response: KycReviewDocumentsSignedURLQuery$data;
  variables: KycReviewDocumentsSignedURLQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "GenerateKYCReviewDocumentSignedURLOutput",
    "kind": "LinkedField",
    "name": "kycReviewDocumentsSignedURL",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "signedURL",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "key",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "KycReviewDocumentsSignedURLQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "KycReviewDocumentsSignedURLQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "88f2b86a868f497a5d6143b7f15d74eb",
    "id": null,
    "metadata": {},
    "name": "KycReviewDocumentsSignedURLQuery",
    "operationKind": "query",
    "text": "query KycReviewDocumentsSignedURLQuery(\n  $input: GenerateKYCReviewDocumentSignedURLInput!\n) {\n  kycReviewDocumentsSignedURL(input: $input) {\n    signedURL\n    key\n  }\n}\n"
  }
};
})();

(node as any).hash = "944224bb75579b1e82dc399988afba79";

export default node;
