import { InviteAdminPortalUserMutation, inviteAdminPortalUserMutation, UpdateAdminPortalUserMutation, updateAdminPortalUserMutation } from 'src/graphql'
import { EdgeGraphqlService } from 'src/services'

export class AdminApi {
  /**
   * SECTION: Queries
   */

  /**
   * SECTION: Mutations
   */

  static inviteAdminPortalUser(variables: InviteAdminPortalUserMutation['variables']) {
    return EdgeGraphqlService.mutationAsPromise<InviteAdminPortalUserMutation>(inviteAdminPortalUserMutation, variables).then(
      (response) => response.inviteAdminPortalUser
    )
  }

  static updateAdminPortalUserByAdminPortalUser(variables: UpdateAdminPortalUserMutation['variables']) {
    return EdgeGraphqlService.mutationAsPromise<UpdateAdminPortalUserMutation>(updateAdminPortalUserMutation, variables).then(
      (response) => response.updateAdminPortalUserByAdminPortalUser
    )
  }
}
