import { graphql } from 'relay-runtime'

import { CreateKYCReviewDocumentMutation } from './__generated__/CreateKYCReviewDocumentMutation.graphql'

export type { CreateKYCReviewDocumentMutation }

export const createKYCReviewDocumentMutation = graphql`
  mutation CreateKYCReviewDocumentMutation($input: CreateKYCReviewDocumentInput!) {
    createKYCReviewDocument(input: $input) {
      ... on KYCReviewDocument {
        id
        createTime
        updateTime
        kycAttemptID
        uploaderUserID
        url
        type
        comment
      }
    }
  }
`
