import { graphql } from 'relay-runtime'

import { AdminUploadBusinessDocumentMutation } from './__generated__/AdminUploadBusinessDocumentMutation.graphql'

export type { AdminUploadBusinessDocumentMutation }

export const adminUploadBusinessDocumentMutation = graphql`
  mutation AdminUploadBusinessDocumentMutation($input: AdminUploadBusinessDocumentInput!) {
    adminUploadBusinessDocument(input: $input) {
      data {
        ... on KYBAttempt {
          id
          createTime
          updateTime
          businessAccountIdentityID
          state
          documents {
            ...on KYBDocument {
              id
              url
              type
            }
          }
        }
      }
    }
  }
`
