import { FC } from 'react'
import Style from './style.module.scss'

/**
 * @param {{  show?: boolean }} props
 */
export const Loading: FC<{ show?: boolean }> = (props) => (
  <div
    className={Style.loading}
    style={{ display: props.show ? '' : 'none' }}
  >
    Loading&#8230;
  </div>
)
