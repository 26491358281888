import { graphql } from 'relay-runtime'

import { BonusTicketsMutation } from './__generated__/BonusTicketsMutation.graphql'

export type { BonusTicketsMutation }

export const bonusTicketsMutation = graphql`
  mutation BonusTicketsMutation($input: BonusTicketsInput!) {
    bonusTickets(input: $input) {
      data {
        totalTickets
        totalUsedTickets
      }
    }
  }
`
