import { CaretLeftFilled, CaretRightFilled } from '@ant-design/icons'
import { Button } from 'antd'
import clsx from 'clsx'
import { FC } from 'react'

interface IProps {
  className?: string
  disabled?: boolean
  canPrev?: boolean
  canNext?: boolean
  onPrev?: () => any
  onNext?: () => any
}

export const Pagination: FC<IProps> = (props) => {
  return (
    <div className={clsx('fx fx-ai-center gap-2', props.className)}>
      <Button
        className="px-1"
        size="small"
        disabled={props.disabled || !props.canPrev}
        onClick={() => props.onPrev?.()}
      >
        <CaretLeftFilled/>
      </Button>

      <Button
        className="px-1"
        size="small"
        disabled={props.disabled || !props.canNext}
        onClick={() => props.onNext?.()}
      >
        <CaretRightFilled/>
      </Button>
    </div>
  )
}
