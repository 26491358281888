/**
 * @generated SignedSource<<4dd48952eaec3e1718a56fbd9186e295>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type StatusCode = "ABORTED" | "ALREADY_EXISTS" | "CANCELLED" | "DATA_LOSS" | "DEADLINE_EXCEEDED" | "FAILED_PRECONDITION" | "INTERNAL" | "INVALID_ARGUMENT" | "NOT_FOUND" | "OK" | "OUT_OF_RANGE" | "PERMISSION_DENIED" | "RESOURCE_EXHAUSTED" | "UNAUTHENTICATED" | "UNAVAILABLE" | "UNIMPLEMENTED" | "UNKNOWN" | "%future added value";
export type AdminPortalUserMeQuery$variables = {};
export type AdminPortalUserMeQuery$data = {
  readonly adminPortalUserMe: {
    readonly admin: {
      readonly attemptCount: number | null;
      readonly confirmed: boolean | null;
      readonly createTime: any | null;
      readonly email: string;
      readonly firstName: string | null;
      readonly id: string;
      readonly lastAttempt: any | null;
      readonly lastName: string | null;
      readonly locked: any | null;
      readonly oAuth2Provider: string | null;
      readonly oAuth2UID: string | null;
      readonly updateTime: any | null;
    } | null;
    readonly code: StatusCode;
    readonly message: string;
  };
};
export type AdminPortalUserMeQuery = {
  response: AdminPortalUserMeQuery$data;
  variables: AdminPortalUserMeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "GravityAdminStatus",
    "kind": "LinkedField",
    "name": "adminPortalUserMe",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "code",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminPortalUser",
        "kind": "LinkedField",
        "name": "admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updateTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "confirmed",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "attemptCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastAttempt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "locked",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "oAuth2UID",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "oAuth2Provider",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AdminPortalUserMeQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AdminPortalUserMeQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "c86f67e7aedd2604b77d4ab2fcbe54e5",
    "id": null,
    "metadata": {},
    "name": "AdminPortalUserMeQuery",
    "operationKind": "query",
    "text": "query AdminPortalUserMeQuery {\n  adminPortalUserMe {\n    code\n    message\n    admin {\n      id\n      createTime\n      updateTime\n      firstName\n      lastName\n      email\n      confirmed\n      attemptCount\n      lastAttempt\n      locked\n      oAuth2UID\n      oAuth2Provider\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "678800bd7e309fc3571c5d6d99106b3e";

export default node;
