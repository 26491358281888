import { CreateShortLinkMutation, RemoveShortLinkMutation, UpdateShortLinkMutation, createShortLinkMutation, removeShortLinkMutation, updateShortLinkMutation } from 'src/graphql'
import { EdgeGraphqlService } from 'src/services'

export class ShortLinkApi {
  /**
   * SECTION: Queries
   */

  /**
   * SECTION: Mutations
   */

  static create(variables: CreateShortLinkMutation['variables']) {
    return EdgeGraphqlService.mutationAsPromise<CreateShortLinkMutation>(createShortLinkMutation, variables).then(
      (response) => response.createShortLink
    )
  }

  static update(variables: UpdateShortLinkMutation['variables']) {
    return EdgeGraphqlService.mutationAsPromise<UpdateShortLinkMutation>(updateShortLinkMutation, variables).then(
      (response) => response.updateShortLink
    )
  }

  static remove(variables: RemoveShortLinkMutation['variables']) {
    return EdgeGraphqlService.mutationAsPromise<RemoveShortLinkMutation>(removeShortLinkMutation, variables).then(
      (response) => response.removeShortLink
    )
  }
}
