import { graphql } from 'relay-runtime'

import { ReferralStatisticsQuery } from './__generated__/ReferralStatisticsQuery.graphql'

export type { ReferralStatisticsQuery }

export type TReferralStatistics = Partial<DeepExclude<DeepWriteable<ReferralStatisticsQuery>, null>['response']['referralStatistics']>

export const referralStatisticsQuery = graphql`
  query ReferralStatisticsQuery($accountID: ID!) {
    referralStatistics(accountID: $accountID) {
      code
      message
      rank
      directReferralTotal
      indirectReferralTotal
      lastUpdatedTime
      waitlistTotal
      data {
        ...on ReferralStatistics {
          rank
          leaderboardTotal
          lastUpdatedTime
          directReferralPhaseZero
          indirectReferralPhaseZero
          pointsPhaseZero
          directReferralPhaseOne
          indirectReferralPhaseOne
          pointsPhaseOne
          availableQuota
          totalQuota
          quotaLastRefreshedTime
        }
      }
    }
  }
`
