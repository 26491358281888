import { message } from 'antd'
import { AxiosError } from 'axios'

export class MessageService {
  static info(content: string) {
    return message.info(content)
  }

  static success(content: string) {
    return message.success(content)
  }

  static error(error: Error | string) {
    return message.error((error as Error)?.message || error as string)
  }

  static axiosError(error: AxiosError<IObject>) {
    const content = error.response?.data?.message || error.response?.data?.error || error.message
    return message.error(content)
  }
}
