import { Table } from 'antd'
import { FC, useMemo } from 'react'
import { TUserPii } from 'src/graphql'
import { renderColumns } from './columns'

interface IProps {
  user?: TUserPii | null
}

export const UserComplyCubeChecks: FC<IProps> = ({ user }) => {
  const dataSource = useMemo(() => user?.currentKyc?.complyCubeChecks?.map((check) => ({
    CheckID: check.ccCheckID,
    Type: check.ccType,
    Status: check.ccStatus,
    Outcome: check.ccOutcome
  })) || [], [user])

  return (
    <section className="fx fx-column fx-extend">
      <Table
        bordered
        rowKey="id"
        className="fx-extend"
        pagination={false}
        dataSource={dataSource}
        columns={renderColumns()}
        scroll={{ x: true }}
      />
    </section>
  )
}
