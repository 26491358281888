import { Space } from 'antd'
import { TAccountDocuments, TStakeholderIdentities } from 'src/graphql'
import { ModalStakeholderDetail } from 'src/partials'

interface IColumnsProps {
  accountID?: string
  onFresh?: () => void
}

interface IRecord extends TStakeholderIdentities {
  docs: TAccountDocuments[]
}

export const renderColumns = ({ accountID, onFresh }: IColumnsProps) => [
  {
    title: 'ID',
    dataIndex: 'stakeholderID',
    key: 'id',
    width: '50%'
  },
  {
    title: 'Role',
    dataIndex: 'role',
    key: 'role'
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    width: 100,
    render: (_: any, record: IRecord) => (
      <Space>
        <ModalStakeholderDetail
          accountID={accountID}
          stakeholder={record}
          onFresh={onFresh}
          btnProps={{ size: 'small', type: 'link' }}
        />
      </Space>
    )
  }
]
