import { graphql } from 'relay-runtime'

import { UserIdentitiesQuery } from './__generated__/UserIdentitiesQuery.graphql'

export type { UserIdentitiesQuery }

export type TUserPiiOverview = Partial<DeepWriteable<DeepExclude<TArrayItem<UserIdentitiesQuery['response']['userIdentities']['edges']>, null>['node']>>

export const userIdentitiesQuery = graphql`
  query UserIdentitiesQuery(
    $after: Cursor
    $first: Int
    $before: Cursor
    $last: Int
    $where: UserIdentityWhereInput
    $orderBy: UserIdentityOrder
  ) {
    userIdentities(
      after: $after
      first: $first
      before: $before
      last: $last
      where: $where
      orderBy: $orderBy
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          ... on UserIdentity {
            id
            createTime
            updateTime
            userID
            accountID
            email
            firstName
            lastName
            kycReviewState
            currentKyc {
              ... on KYCAttempt {
                state
                kycTags {
                  ... on KYCTag {
                    id
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
