/**
 * @generated SignedSource<<072f8879cc6fdc460646f133875b52aa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ArticleActiveStatus = "activated" | "deactivated" | "%future added value";
export type StatusCode = "ABORTED" | "ALREADY_EXISTS" | "CANCELLED" | "DATA_LOSS" | "DEADLINE_EXCEEDED" | "FAILED_PRECONDITION" | "INTERNAL" | "INVALID_ARGUMENT" | "NOT_FOUND" | "OK" | "OUT_OF_RANGE" | "PERMISSION_DENIED" | "RESOURCE_EXHAUSTED" | "UNAUTHENTICATED" | "UNAVAILABLE" | "UNIMPLEMENTED" | "UNKNOWN" | "%future added value";
export type UpdateArticleInput = {
  activeStatus?: ArticleActiveStatus | null;
  clearDeleteTime?: boolean | null;
  clearDescription?: boolean | null;
  clearTitle?: boolean | null;
  deleteTime?: any | null;
  description?: string | null;
  title?: string | null;
};
export type UpdateArticleMutation$variables = {
  id: string;
  input: UpdateArticleInput;
};
export type UpdateArticleMutation$data = {
  readonly updateArticle: {
    readonly code: StatusCode;
    readonly message: string;
  };
};
export type UpdateArticleMutation = {
  response: UpdateArticleMutation$data;
  variables: UpdateArticleMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "BaseStatus",
    "kind": "LinkedField",
    "name": "updateArticle",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "code",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateArticleMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateArticleMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3e657106fe6d2486d0bb51c1d8127cdd",
    "id": null,
    "metadata": {},
    "name": "UpdateArticleMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateArticleMutation(\n  $id: ID!\n  $input: UpdateArticleInput!\n) {\n  updateArticle(id: $id, input: $input) {\n    code\n    message\n  }\n}\n"
  }
};
})();

(node as any).hash = "98fda399c9413864cde1b19606dd22e6";

export default node;
