/**
 * @generated SignedSource<<c7612a2cd4fb42c5ca57b3100218e130>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DocumentVisibility = "account" | "stakeholder" | "user" | "%future added value";
export type StatusCode = "ABORTED" | "ALREADY_EXISTS" | "CANCELLED" | "DATA_LOSS" | "DEADLINE_EXCEEDED" | "FAILED_PRECONDITION" | "INTERNAL" | "INVALID_ARGUMENT" | "NOT_FOUND" | "OK" | "OUT_OF_RANGE" | "PERMISSION_DENIED" | "RESOURCE_EXHAUSTED" | "UNAUTHENTICATED" | "UNAVAILABLE" | "UNIMPLEMENTED" | "UNKNOWN" | "%future added value";
export type CreateDocumentInput = {
  accountID?: string | null;
  adminPortalUserID?: string | null;
  name: string;
  path: string;
  userID?: string | null;
  visibility: DocumentVisibility;
};
export type CreateDocumentMutation$variables = {
  input: CreateDocumentInput;
};
export type CreateDocumentMutation$data = {
  readonly createDocument: {
    readonly code: StatusCode;
    readonly document: {
      readonly account: {
        readonly createTime: any | null;
        readonly id: string;
        readonly updateTime: any | null;
      } | null;
      readonly accountID: string | null;
      readonly createTime: any | null;
      readonly id: string;
      readonly name: string;
      readonly path: string;
      readonly updateTime: any | null;
      readonly user: {
        readonly createTime: any | null;
        readonly id: string;
        readonly updateTime: any | null;
      } | null;
      readonly userID: string | null;
      readonly visibility: DocumentVisibility;
    } | null;
    readonly message: string;
  };
};
export type CreateDocumentMutation = {
  response: CreateDocumentMutation$data;
  variables: CreateDocumentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createTime",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updateTime",
  "storageKey": null
},
v4 = [
  (v1/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/)
],
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DocumentStatus",
    "kind": "LinkedField",
    "name": "createDocument",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "code",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Document",
        "kind": "LinkedField",
        "name": "document",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "path",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accountID",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "userID",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "visibility",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateDocumentMutation",
    "selections": (v5/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateDocumentMutation",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "87f6f2bbe78c319d0d442082ba5b238b",
    "id": null,
    "metadata": {},
    "name": "CreateDocumentMutation",
    "operationKind": "mutation",
    "text": "mutation CreateDocumentMutation(\n  $input: CreateDocumentInput!\n) {\n  createDocument(input: $input) {\n    code\n    message\n    document {\n      id\n      createTime\n      updateTime\n      name\n      path\n      accountID\n      userID\n      visibility\n      account {\n        id\n        createTime\n        updateTime\n      }\n      user {\n        id\n        createTime\n        updateTime\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "30693179b8be55795341b67a47bcef26";

export default node;
