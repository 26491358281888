import { Descriptions, DescriptionsProps } from 'antd'
import { FC, useMemo } from 'react'
import { TUserPii } from 'src/graphql'
import { CountriesStore } from 'src/store'

interface IProps {
  user?: TUserPii | null
}

export const UserAddress: FC<IProps> = ({ user }) => {
  const items: DescriptionsProps['items'] = useMemo(
    () => [
      {
        label: 'Address line one',
        children: user?.currentKyc?.address?.addressLineOne || '--'
      },
      {
        label: 'Address line two',
        children: user?.currentKyc?.address?.addressLineTwo || '--'

      },
      {
        label: 'City',
        children: user?.currentKyc?.address?.city || '--'

      },
      {
        label: 'Postal Code',
        children: user?.currentKyc?.address?.postalCode || '--'
      },
      {
        label: 'State/Province',
        children: user?.currentKyc?.address?.stateProvince || '--'
      },
      {
        label: 'Country of residence',
        children: CountriesStore.getCountryName(user?.currentKyc?.countryOfResidence) || '--'
      }
      // {
      //   label: 'ComplyCube address check ID',
      //   children: 'TODO' || '--'
      // },
      // {
      //   label: 'ComplyCube address outcome',
      //   children: 'TODO' || '--'
      // }
    ].map((el, index) => ({
      ...el,
      key: index,
      style: { width: '50%' }
    })),
    [user]
  )

  return (
    <section>
      <Descriptions
        layout="vertical"
        size="small"
        column={2}
        bordered
        items={items}
      />
    </section>
  )
}
