/**
 * @generated SignedSource<<e71de9484d2ddeeaeb3a9addd25fb830>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type KOLStatisticsInput = {
  pageNumber?: number | null;
  pageSize?: number | null;
  query?: string | null;
};
export type KolStatisticsQuery$variables = {
  input: KOLStatisticsInput;
};
export type KolStatisticsQuery$data = {
  readonly kolStatistics: {
    readonly data: ReadonlyArray<{
      readonly accountID: string;
      readonly email: string;
      readonly joinedDate: string;
      readonly name: string;
      readonly projectedEfficiencyRate: number;
      readonly projectedTotalCodes: number;
      readonly rank: number;
      readonly tier: string;
      readonly totalDirectPhase0Referrals: number;
      readonly totalDirectPhase1Referrals: number;
      readonly totalDirectReferrals: number;
      readonly totalIndirectPhase0Referrals: number;
      readonly totalIndirectPhase1Referrals: number;
      readonly totalIndirectReferrals: number;
      readonly totalPhase0Points: number;
      readonly totalPhase1Points: number;
      readonly totalPoints: number;
    }>;
    readonly total: number;
  };
};
export type KolStatisticsQuery = {
  response: KolStatisticsQuery$data;
  variables: KolStatisticsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "KOLStatisticsStatus",
    "kind": "LinkedField",
    "name": "kolStatistics",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "KOLStatistic",
        "kind": "LinkedField",
        "name": "data",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accountID",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "joinedDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tier",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalDirectReferrals",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalIndirectReferrals",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalDirectPhase0Referrals",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalIndirectPhase0Referrals",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalDirectPhase1Referrals",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalIndirectPhase1Referrals",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalPoints",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalPhase0Points",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalPhase1Points",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rank",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "projectedTotalCodes",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "projectedEfficiencyRate",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "total",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "KolStatisticsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "KolStatisticsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4f3acf7b5bc697403b55e1fd0c0a9ebe",
    "id": null,
    "metadata": {},
    "name": "KolStatisticsQuery",
    "operationKind": "query",
    "text": "query KolStatisticsQuery(\n  $input: KOLStatisticsInput!\n) {\n  kolStatistics(input: $input) {\n    data {\n      accountID\n      email\n      name\n      joinedDate\n      tier\n      totalDirectReferrals\n      totalIndirectReferrals\n      totalDirectPhase0Referrals\n      totalIndirectPhase0Referrals\n      totalDirectPhase1Referrals\n      totalIndirectPhase1Referrals\n      totalPoints\n      totalPhase0Points\n      totalPhase1Points\n      rank\n      projectedTotalCodes\n      projectedEfficiencyRate\n    }\n    total\n  }\n}\n"
  }
};
})();

(node as any).hash = "fca09350a7ed150cf61bf5ffe398662f";

export default node;
