import { Environment, Network, RecordSource, Store } from 'relay-runtime'
import { from } from 'rxjs'
import { EEnv } from 'src/constants'

const GRAPHQL_API_HOST = String(EEnv.REACT_APP_EDGE_HOST)

const genFetch = (host: string) => async function FetchGraphQL(query: any, variables: any) {
  const data = await fetch(host, {
    method: 'POST',
    headers: {
      adminportal: 'true',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      query,
      variables
    }),
    credentials: 'include'
  })
    .then((res) => {
      if (res.status === 429) {
        return {
          errors: [{
            message: 'You have exceeded the rate limit. Please try again at a later time'
          }]
        }
      }

      return res.json()
    })
    .catch((error) => {
      // eslint-disable-next-line no-throw-literal
      throw {
        message: error.message,
        stack: error.stack
      }
    })

  if (Array.ensure(data.errors, []).length) {
    data.message = data.errors[0].message || data.errors[0]
    throw data
  }

  return data
}

export const EdgeEnvironment = new Environment({
  network: Network.create((params: any, variables: any) => from(genFetch([GRAPHQL_API_HOST.replace(/\/$/g, ''), 'query'].join('/'))(params.text, variables))),
  store: new Store(new RecordSource())
})

export const PiiEnvironment = new Environment({
  network: Network.create((params: any, variables: any) => from(genFetch([GRAPHQL_API_HOST.replace(/\/$/g, ''), 'identity'].join('/'))(params.text, variables))),
  store: new Store(new RecordSource())
})
