import moment from 'moment'
import { TCountry } from 'src/graphql'
import { ECountryEditLogAction, ECountryEditLogActionTxt } from 'src/interfaces'
import { TzService } from 'src/services'

type TCountryEditLog = NonNullable<TCountry['editLogs']>[0]

const formatLog = (status: any) => {
  if (Array.isArray(status)) {
    return (
      <div className="fx-column">
        {status.map((el) => (
          <div key={el.id}>
            {formatLog(el)}
          </div>
        ))}
      </div>
    )
  }

  if (typeof status === 'string') {
    return status.split('_').join(' ')
  }

  return status
}

export const renderColumns = ({ countryList }: { countryList?: TCountry[] }) => [
  {
    title: 'Edited time',
    dataIndex: 'updateTime',
    key: 'updateTime',
    className: 'wp-no-wrap',
    render: (_: any, record: TCountryEditLog) => record.updateTime
      ? TzService.format(record.updateTime, 'HH:mm:ss YYYY-MM-DD')
      : '--',
    sorter: (log1: TCountryEditLog, log2: TCountryEditLog) =>
      moment(log1.updateTime).toDate().getTime() -
      moment(log2.updateTime).toDate().getTime()
  },
  {
    title: 'Edited by',
    dataIndex: 'editorEmail',
    key: 'editedBy',
    className: 'break-all min-w-150'
  },
  {
    title: 'Country',
    dataIndex: 'countryID',
    key: 'country',
    filters: countryList?.length
      ? countryList.map((country) => ({
        text: country.name,
        value: country.id
      }))
      : undefined,
    onFilter: ((value: string, record: TCountryEditLog) => record.countryID === value) as any,
    render: (_: any, record: TCountryEditLog) => {
      const country = countryList?.find((c) => c.id === record.countryID)
      return country ? country.name : ''
    }
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    className: 'wp-no-wrap',
    filters: Object.values(ECountryEditLogAction).map((value) => ({
      text: ECountryEditLogActionTxt[value],
      value
    })),
    onFilter: ((value: string, record: TCountryEditLog) => record.action === value) as any,
    render: (_: any, record: TCountryEditLog) => ECountryEditLogActionTxt[record.action as ECountryEditLogAction] || formatLog(record.action)
  },
  {
    title: 'Previous status',
    dataIndex: 'previousStatus',
    key: 'previousStatus',
    className: 'wp-no-wrap text-capitalize',
    render: (_: any, record: TCountryEditLog) => {
      const oldData = JSON.parse(record.data).old
      return formatLog(oldData)
    }
  },
  {
    title: 'New status',
    dataIndex: 'newStatus',
    key: 'newStatus',
    className: 'wp-no-wrap text-capitalize',
    render: (_: any, record: TCountryEditLog) => {
      const newData = JSON.parse(record.data).new
      return formatLog(newData)
    }
  },
  {
    title: 'Comment',
    dataIndex: 'comment',
    key: 'comment',
    className: 'break-all min-w-200'
  }
]
