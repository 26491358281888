import { cloneDeep, unionBy } from 'lodash'
import { GraphQLTaggedNode, OperationType, Variables } from 'relay-runtime'
import { BehaviorSubject } from 'rxjs'
import { EdgeGraphqlService } from './graphql.service'

const defaultPagingInfo: {
  totalCount: number
  pageInfo: {
    startCursor: string
    endCursor: string
    hasPreviousPage: boolean
    hasNextPage: boolean
  }
} = Object.freeze({
  pageInfo: {
    startCursor: '',
    endCursor: '',
    hasNextPage: true,
    hasPreviousPage: false
  },
  totalCount: 1
})

interface IPaginationParams extends Variables {
  after?: string
  before?: string
  first?: number
  last?: number
  where?: Record<string, any> | null
}

interface IPaginationResponse<T = any> {
  edges: {
    cursor: string
    node: T
  }[]
  pageInfo: {
    startCursor: string
    endCursor: string
    hasNextPage: boolean
    hasPreviousPage: boolean
  }
  totalCount: number
}

interface IQuery<T> {
  variables?: IPaginationParams
  response: Record<string, IPaginationResponse<T>>
}

export class LoadMoreService<T extends { id?: string }, Q extends Omit<OperationType, 'variables'> & { variables?: OperationType['variables'] } = IQuery<T> > {
  query: GraphQLTaggedNode
  constructor(query: GraphQLTaggedNode) {
    this.query = query
  }

  /**
   * loading
   */

  private readonly _loading$ = new BehaviorSubject(false)
  get loading$() {
    return this._loading$.asObservable()
  }

  get loading() {
    return this._loading$.getValue()
  }

  set loading(loading) {
    this._loading$.next(loading)
  }

  /**
   * items
   */

  private readonly _items$ = new BehaviorSubject<T[]>([])

  get items$() {
    return this._items$.asObservable()
  }

  get items() {
    return this._items$.getValue()
  }

  set items(items) {
    this._items$.next(items)
  }

  /**
   * error
   */

  private readonly _error$ = new BehaviorSubject<Error | null>(null)
  get error$() {
    return this._error$.asObservable()
  }

  get error() {
    return this._error$.getValue()
  }

  set error(error) {
    this._error$.next(error)
  }

  /**
   * error
   */

  private readonly _paging$ = new BehaviorSubject(cloneDeep(defaultPagingInfo))

  get paging$() {
    return this._paging$.asObservable()
  }

  get paging() {
    return this._paging$.getValue()
  }

  set paging(paging) {
    this._paging$.next(paging)
  }

  /**
   * implement
   */

  get canPrev() {
    return !this.error && !this.loading && this.paging.pageInfo.hasPreviousPage
  }

  get canNext() {
    return !this.error && !this.loading && this.paging.pageInfo.hasNextPage
  }

  reset() {
    this.items = []
    this.error = null
    this.loading = false
    this.paging = cloneDeep(defaultPagingInfo)
  }

  next(variables: Q['variables'] = {}) {
    if (!this.loading && this.canNext) {
      return EdgeGraphqlService.queryAsPromise<Q>(this.query, {
        after: this.paging.pageInfo.endCursor || null,
        first: 10,
        ...variables
      }).then(
        (response) => {
          const { edges, pageInfo, totalCount } = Object.values(response)[0] as IPaginationResponse<T>

          this.paging = {
            pageInfo,
            totalCount
          }

          this.items = unionBy(
            [...this.items, ...edges.map((edge) => edge.node)],
            'id'
          )
        }
      ).catch(
        (error) => {
          this.error = error
          throw error
        }
      )
    }
  }
}
