import { Descriptions, DescriptionsProps } from 'antd'
import { FC, useMemo } from 'react'
import { TBusinessUserPii } from 'src/graphql'

interface IProps {
  user?: TBusinessUserPii | null
  onUpdated?: () => void
}

export const BusinessKybProfile: FC<IProps> = ({ user, onUpdated }) => {
  const items: DescriptionsProps['items'] = useMemo(
    () => [
      {
        label: 'Full Legal Name',
        children: user?.fullLegalName || '--'
      },
      {
        label: 'Doing Business As (DBA) name',
        children: user?.doingBusinessAsName || '--'
      },
      {
        label: 'Type of Business',
        children: user?.businessType || '--'
      },
      {
        label: 'Country of incorporation',
        children: user?.incorporationCountry || '--'
      },
      {
        label: 'Date of Entity Incorporation / Establishment',
        children: String(user?.dateOfIncorporation) || '--'
      },
      {
        label: 'Incorporation Number / Business Registration Number',
        children: user?.incorporationNumber || '--'
      },
      {
        label: 'Full Legal (Registered) Address',
        children: user?.legalAddress || '--'
      },
      {
        label: 'Full Primary Business Address',
        children: user?.primaryAddress || '--'
      }
    ].map((el, index) => ({
      ...el,
      key: index,
      style: { width: '50%' }
    })),
    [user]
  )

  return (
    <section>
      <Descriptions
        layout="vertical"
        size="small"
        column={2}
        bordered
        items={items}
      />
    </section>
  )
}
