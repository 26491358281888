import { Button, Form, Input, Modal, Radio } from 'antd'
import { ComponentProps, FC, useCallback, useEffect, useState } from 'react'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { PiiApi } from 'src/api'
import { TAdminInvitee, TUserPii } from 'src/graphql'
import { useAsRef, useUnsubscribe } from 'src/hooks'
import { MessageService } from 'src/services'

type FieldType = {
  riskLevel: 'high' | 'medium' | 'low'
  comment: string
}

export const DialogChangeKycRiskLevel: FC<Omit<
  ComponentProps<typeof Modal>,
  'onOk' | 'afterClose'
> & {
  user: TUserPii
  btnProps?: ComponentProps<typeof Button>
  afterClose?: (saved?: boolean) => any
}> = ({
  user,
  btnProps,
  ...props
}) => {
  const unsubscribe$ = useUnsubscribe()
  const [loading, setLoading] = useState(false)
  const [invisible, setInvisible] = useState<boolean | TAdminInvitee>(true)

  useEffect(() => {
    setInvisible(!props.open)
  }, [props.open])

  const afterCloseRef = useAsRef(props.afterClose)
  const currentRiskLevel = user?.currentKyc?.riskScore?.level

  const [form] = Form.useForm()
  useEffect(() => {
    if (!invisible) {
      form.setFieldsValue({ riskLevel: currentRiskLevel })
    }
  }, [invisible, currentRiskLevel, form])

  const submit = useCallback(async (values: FieldType) => {
    const input = {
      id: user?.currentKyc?.id as string,
      level: values.riskLevel,
      comment: values.comment
    }
    if (!input.id || !input.level || !input.comment) {
      return
    }
    setLoading(true)
    from(PiiApi.editRiskScore({ input }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          MessageService.error(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(() => {
        MessageService.info('Change risk level successfully')
        setInvisible(true)
        form.resetFields()
        afterCloseRef.current?.(true)
      })
  }, [afterCloseRef, form, unsubscribe$, user?.currentKyc?.id])

  return (
    <>
      <Button
        type="primary"
        {...btnProps}
        onClick={() => setInvisible(false)}
      >
        {props.children || 'Edit risk tag'}
      </Button>

      <Modal
        title={props.title || 'Change risk level'}
        open={!invisible}
        centered
        closable={!loading} // display X icon
        keyboard={false} // disable close on press ESC
        maskClosable={false} // disable close on click outside
        okButtonProps={{ disabled: loading }}
        cancelButtonProps={{ disabled: loading }}
        okText="Ok"
        cancelText="Cancel"
        onOk={() => form.submit()}
        onCancel={() => {
          setInvisible(true)
          form.resetFields()
        }}
      >
        {!invisible && (
          <Form
            form={form}
            name="change-risk-level"
            labelCol={{ span: 5 }}
            style={{ maxWidth: 600 }}
            onFinish={submit}
            autoComplete="off"
          >
            <Form.Item<FieldType>
              label="Risk level"
              name="riskLevel"
              rules={[{ required: true }]}
            >
              <Radio.Group>
                <Radio value="high">High</Radio>
                <Radio value="medium">Medium</Radio>
                <Radio value="low">Low</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item<FieldType>
              label="Comment"
              name="comment"
              rules={[{ required: true }]}
            >
              <Input.TextArea/>
            </Form.Item>
          </Form>
        )}
      </Modal>
    </>
  )
}
