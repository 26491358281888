export enum ECountryDocumentType {
  PASSPORT = 'passport',
  NATIONAL_IDENTITY_CARD = 'national_identity_card',
  DRIVING_LICENSE = 'driving_license',
  RESIDENCE_PERMIT = 'residence_permit',
  OTHER = 'other'
}

export const ECountryDocumentTypeTxt = Object.freeze({
  [ECountryDocumentType.PASSPORT]: 'Passport',
  [ECountryDocumentType.NATIONAL_IDENTITY_CARD]: 'National Identity Card',
  [ECountryDocumentType.DRIVING_LICENSE]: 'Driving License',
  [ECountryDocumentType.RESIDENCE_PERMIT]: 'Residence Permit',
  [ECountryDocumentType.OTHER]: 'Other'
})

export enum ECountryEditLogAction {
  NATIONALITY_TIER = 'nationality_tier',
  NATIONALITY_STATUS = 'nationality_status',
  RESIDENCY_TIER = 'residency_tier',
  RESIDENCY_STATUS = 'residency_status',
  DOCUMENTS = 'documents'
}

export const ECountryEditLogActionTxt = Object.freeze({
  [ECountryEditLogAction.NATIONALITY_TIER]: 'Nationality Tier',
  [ECountryEditLogAction.NATIONALITY_STATUS]: 'Nationality Status',
  [ECountryEditLogAction.RESIDENCY_TIER]: 'Residency Tier',
  [ECountryEditLogAction.RESIDENCY_STATUS]: 'Residency Status',
  [ECountryEditLogAction.DOCUMENTS]: 'Accepted IDs'
})

export enum ECountryStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive'
}

export const ECountryStatusTxt = Object.freeze({
  [ECountryStatus.ACTIVE]: 'Active',
  [ECountryStatus.INACTIVE]: 'Inactive'
})
