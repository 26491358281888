import { graphql } from 'relay-runtime'

import { ReviewKYCAttemptMutation } from './__generated__/ReviewKYCAttemptMutation.graphql'

export type { ReviewKYCAttemptMutation }

export const reviewKYCAttemptMutation = graphql`
  mutation ReviewKYCAttemptMutation($input: ReviewKYCAttemptInput!) {
    reviewKYCAttempt(input: $input) {
      data {
        ... on KYCAttempt {
          id
          # createTime
          # updateTime
          # userIdentityID
          # state
          # countryOfResidence
          # userType
          # extractedInfoID
          # extractedInfo {
          #   ... on KYCExtractedInfo {
          #     id
          #     createTime
          #     updateTime
          #     ccCheckID
          #     kycAttemptID
          #     firstName
          #     lastName
          #     dateOfBirth
          #     nationality
          #     documentNumber
          #     documentType
          #   }
          # }
        }
      }
    }
  }
`
