import { Descriptions, DescriptionsProps } from 'antd'
import { FC, useMemo } from 'react'
import { TBusinessUserPii } from 'src/graphql'

interface IProps {
  user?: TBusinessUserPii | null
}

export const BusinessKybContactInfo: FC<IProps> = ({ user }) => {
  const items: DescriptionsProps['items'] = useMemo(
    () => [
      {
        label: 'Official Website',
        children: user?.website || '--'
      },
      {
        label: 'Official e-mail address',
        children: user?.email || '--'
      },
      {
        label: 'Official Phone number',
        children: user?.phone || '--'
      }
    ].map((el, index) => ({
      ...el,
      key: index,
      style: { width: '50%' }
    })),
    [user]
  )

  return (
    <section>
      <Descriptions
        layout="vertical"
        size="small"
        column={2}
        bordered
        items={items}
      />
    </section>
  )
}
