import { HomeOutlined } from '@ant-design/icons'
import { ExoticComponent, ReactNode } from 'react'
import { BehaviorSubject } from 'rxjs'

export interface IBreadcrumbItem {
  icon?: ExoticComponent
  key?: string
  label?: string | ReactNode
  route?: string | [string, { [key in string]?: string | number }]
}

const defaultItems: IBreadcrumbItem = {
  icon: HomeOutlined,
  key: Math.random().toString(36).substr(2, 9),
  route: '/dashboard'
}

export const BreadcrumbService = new (
  class {
    create(items: IBreadcrumbItem | IBreadcrumbItem[]) {
      return Array.ensure<IBreadcrumbItem>(items).map(
        item => ({
          ...item,
          key: item.key || Math.random().toString(36).substring(2, 11)
        })
      )
    }

    constructor(items: IBreadcrumbItem[] = []) {
      this.items = items
    }

    readonly items$ = new BehaviorSubject<IBreadcrumbItem[]>([])
    get items() {
      return this.items$.getValue()
    }

    set items(items: IBreadcrumbItem[]) {
      this.items$.next(this.create([defaultItems, ...items]))
    }
  }
)()
