import { InboxOutlined } from '@ant-design/icons'
import { Button, Modal, Upload, UploadFile, UploadProps } from 'antd'
import { ComponentProps, FC, SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { TAdminInvitee } from 'src/graphql'
import { MessageService } from 'src/services'
import { EventUtils } from 'src/utils'

const { Dragger } = Upload

const MAX_SIZE_FILE_UPLOAD = 5 * 1024 * 1024

export const DialogUploadDocuments: FC<Omit<
  ComponentProps<typeof Modal>,
  'onOk' | 'afterClose'
> & {
  btnProps?: ComponentProps<typeof Button>
  onOk?: (files: File[]) => void
}> = ({
  btnProps,
  onOk,
  ...props
}) => {
  const [loading] = useState(false)
  const [invisible, setInvisible] = useState<boolean | TAdminInvitee>(true)
  const [fileList, setFileList] = useState<UploadFile[]>([])

  const submit = useCallback(async (e: SyntheticEvent) => {
    EventUtils.preventDefault(e)
    onOk?.(fileList as unknown as File[])
    setInvisible(true)
  }, [fileList, onOk])

  const uploadProps: UploadProps = {
    multiple: true,
    onRemove: (file) => {
      const index = fileList.indexOf(file)
      const newFileList = fileList.slice()
      newFileList.splice(index, 1)
      setFileList(newFileList)
    },
    beforeUpload: (file) => {
      if (file.size > MAX_SIZE_FILE_UPLOAD) {
        MessageService.error('File size exceeds the limit of 5MB')
        return true
      }
      setFileList(prev => ([...prev, file]))
      return false
    },
    fileList,
    accept: 'image/*, application/pdf'
  }

  useEffect(() => {
    return () => {
      setFileList([])
    }
  }, [invisible])

  useEffect(() => {
    setInvisible(!props.open)
  }, [props.open])

  return (
    <>
      <Button
        type="primary"
        {...btnProps}
        onClick={() => setInvisible(false)}
      >
        {props.children || 'Upload documents'}
      </Button>

      <Modal
        title={props.title || 'Upload documents'}
        open={!invisible}
        centered
        closable={!loading} // display X icon
        keyboard // enable close on press ESC
        maskClosable // enable close on click outside
        okButtonProps={{ disabled: loading || !fileList.length }}
        cancelButtonProps={{ disabled: loading }}
        okText="Ok"
        cancelText="Cancel"
        onOk={submit}
        onCancel={() => setInvisible(true)}
      >
        <Dragger {...uploadProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined/>
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
          <p className="ant-upload-hint">
            Please upload pdf, jpg or png within 5mb.
          </p>
        </Dragger>
      </Modal>
    </>
  )
}
