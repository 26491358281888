/**
 * @generated SignedSource<<cccf327b049939a0c35159547acf47d1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type StakeholderRole = "AUTHORIZED_REPRESENTATIVE" | "DIRECTOR" | "ENTITY" | "SHAREHOLDER" | "UBO" | "%future added value";
export type StatusCode = "ABORTED" | "ALREADY_EXISTS" | "CANCELLED" | "DATA_LOSS" | "DEADLINE_EXCEEDED" | "FAILED_PRECONDITION" | "INTERNAL" | "INVALID_ARGUMENT" | "NOT_FOUND" | "OK" | "OUT_OF_RANGE" | "PERMISSION_DENIED" | "RESOURCE_EXHAUSTED" | "UNAUTHENTICATED" | "UNAVAILABLE" | "UNIMPLEMENTED" | "UNKNOWN" | "%future added value";
export type StakeholderIdentitiesQuery$variables = {
  accountID: string;
};
export type StakeholderIdentitiesQuery$data = {
  readonly stakeholderIdentities: {
    readonly code: StatusCode;
    readonly message: string;
    readonly stakeholders: ReadonlyArray<{
      readonly accountID: string;
      readonly deletedAt: string | null;
      readonly entity: {
        readonly companyName: string | null;
        readonly companyRegistrationNumber: string | null;
        readonly countryOfIncorporation: string | null;
        readonly dateOfIncorporation: string | null;
        readonly registeredOfficeAddress: string | null;
      } | null;
      readonly individual: {
        readonly countryOfBirth: string | null;
        readonly countryOfResidence: string | null;
        readonly dateOfBirth: string | null;
        readonly firstName: string | null;
        readonly fullAddress: string | null;
        readonly lastName: string | null;
        readonly nationality: string | null;
      } | null;
      readonly role: StakeholderRole;
      readonly stakeholderID: string;
    } | null>;
  };
};
export type StakeholderIdentitiesQuery = {
  response: StakeholderIdentitiesQuery$data;
  variables: StakeholderIdentitiesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "accountID"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "accountID",
        "variableName": "accountID"
      }
    ],
    "concreteType": "StakeholderIdentitiesStatus",
    "kind": "LinkedField",
    "name": "stakeholderIdentities",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "code",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "StakeholderIdentity",
        "kind": "LinkedField",
        "name": "stakeholders",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accountID",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "stakeholderID",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "role",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "StakeholderIdentityEntity",
            "kind": "LinkedField",
            "name": "entity",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "companyName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "countryOfIncorporation",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "companyRegistrationNumber",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dateOfIncorporation",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "registeredOfficeAddress",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "StakeholderIdentityIndividual",
            "kind": "LinkedField",
            "name": "individual",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fullAddress",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dateOfBirth",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "countryOfResidence",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "nationality",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "countryOfBirth",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deletedAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "StakeholderIdentitiesQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "StakeholderIdentitiesQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5bab2032b93cacaa76548dee078a873c",
    "id": null,
    "metadata": {},
    "name": "StakeholderIdentitiesQuery",
    "operationKind": "query",
    "text": "query StakeholderIdentitiesQuery(\n  $accountID: ID!\n) {\n  stakeholderIdentities(accountID: $accountID) {\n    code\n    message\n    stakeholders {\n      accountID\n      stakeholderID\n      role\n      entity {\n        companyName\n        countryOfIncorporation\n        companyRegistrationNumber\n        dateOfIncorporation\n        registeredOfficeAddress\n      }\n      individual {\n        firstName\n        lastName\n        fullAddress\n        dateOfBirth\n        countryOfResidence\n        nationality\n        countryOfBirth\n      }\n      deletedAt\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "dbbc6b22a6c655b314c00447cd87b4a0";

export default node;
