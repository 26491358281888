import clsx from 'clsx'
import { memo } from 'react'
import Style from './style.module.scss'

interface IProps {
  message?: string
  className?: string
  children?: React.ReactNode
}

export const ErrorMessage = memo(
  ({ message, children, className }: IProps) => {
    const displayMessage = message || children
    return (
      <div className={clsx(Style.errorMessage, displayMessage ? Style.show : Style.hide, className)}>
        {displayMessage}
      </div>
    )
  }
)
