import { TCountry } from 'src/graphql'
import { ECountryDocumentType, ECountryDocumentTypeTxt, ECountryEditLogAction, ECountryStatus, ECountryStatusTxt } from 'src/interfaces'
import { DialogEditCountryConfig } from 'src/partials'

interface Actions {
  onFresh?: () => void
}

export const renderColumns = ({ onFresh }: Actions) => [
  {
    title: 'Code',
    dataIndex: 'code',
    key: 'code',
    sorter: (a: TCountry, b: TCountry) => a.code.localeCompare(b.code)
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: (a: TCountry, b: TCountry) => a.name.localeCompare(b.name)
  },
  {
    title: 'Nationality Tier',
    dataIndex: 'nationalityTier',
    key: 'nationalityTier',
    sorter: (a: TCountry, b: TCountry) => a.nationalityTier - b.nationalityTier,
    render: (_: any, record: TCountry) => (
      <DialogEditCountryConfig
        id={record.id}
        editLogAction={ECountryEditLogAction.NATIONALITY_TIER}
        nationalityTier={record.nationalityTier}
        btnProps={{ size: 'small', type: 'link' }}
        afterClose={(fresh) => fresh && onFresh?.()}
      >
        {record.nationalityTier}
      </DialogEditCountryConfig>
    )
  },
  {
    title: 'Nationality Status',
    dataIndex: 'nationalityStatus',
    key: 'nationalityStatus',
    render: (_: any, record: TCountry) => (
      <DialogEditCountryConfig
        id={record.id}
        editLogAction={ECountryEditLogAction.NATIONALITY_STATUS}
        nationalityStatus={record.nationalityStatus}
        btnProps={{ size: 'small', type: 'link' }}
        afterClose={(fresh) => fresh && onFresh?.()}
      >
        {record.nationalityStatus}
      </DialogEditCountryConfig>
    ),
    filters: Object.values(ECountryStatus).map((value) => ({
      text: ECountryStatusTxt[value],
      value
    })),
    onFilter: ((value: string, record: TCountry) => record.nationalityStatus === value) as any
  },
  {
    title: 'Residency Tier',
    dataIndex: 'residencyTier',
    key: 'residencyTier',
    sorter: (a: TCountry, b: TCountry) => a.residencyTier - b.residencyTier,
    render: (_: any, record: TCountry) => (
      <DialogEditCountryConfig
        id={record.id}
        editLogAction={ECountryEditLogAction.RESIDENCY_TIER}
        residencyTier={record.residencyTier}
        btnProps={{ size: 'small', type: 'link' }}
        afterClose={(fresh) => fresh && onFresh?.()}
      >
        {record.residencyTier}
      </DialogEditCountryConfig>
    )
  },
  {
    title: 'Residency Status',
    dataIndex: 'status',
    key: 'residencyStatus',
    render: (_: any, record: TCountry) => (
      <DialogEditCountryConfig
        id={record.id}
        editLogAction={ECountryEditLogAction.RESIDENCY_STATUS}
        residencyStatus={record.status}
        btnProps={{ size: 'small', type: 'link' }}
        afterClose={(fresh) => fresh && onFresh?.()}
      >
        {record.status}
      </DialogEditCountryConfig>
    ),
    filters: Object.values(ECountryStatus).map((value) => ({
      text: ECountryStatusTxt[value],
      value
    })),
    onFilter: ((value: string, record: TCountry) => record.status === value) as any
  },
  {
    title: 'Accepted IDs',
    dataIndex: 'acceptedIDs',
    key: 'acceptedIDs',
    render: (_: any, record: TCountry) => (
      <div className="fx fx-ai-center gap-2">
        {!!record.documents?.length && (
          <div className="fx-column text-capitalize">
            {record.documents?.map((el) => (
              <div key={el.id}>
                {ECountryDocumentTypeTxt[el.ccDocumentType as ECountryDocumentType] || el.ccDocumentType.split('_').join(' ')}
              </div>
            ))}
          </div>
        )}
        <DialogEditCountryConfig
          id={record.id}
          editLogAction={ECountryEditLogAction.DOCUMENTS}
          documentTypes={record.documents?.map((el) => el.ccDocumentType)}
          btnProps={{ size: 'small', type: 'link' }}
          afterClose={(fresh) => fresh && onFresh?.()}
        >
          Edit
        </DialogEditCountryConfig>
      </div>
    )
  }
]
