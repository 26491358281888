/**
 * @generated SignedSource<<478002c0dc879056a25b64527e663476>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AdminPortalPermissions = "ADMIN_PORTAL_ADMIN" | "ADMIN_PORTAL_COMPLIANCE" | "ADMIN_PORTAL_ENGINEERING" | "ADMIN_PORTAL_REFERRAL" | "ADMIN_PORTAL_USER" | "%future added value";
export type StatusCode = "ABORTED" | "ALREADY_EXISTS" | "CANCELLED" | "DATA_LOSS" | "DEADLINE_EXCEEDED" | "FAILED_PRECONDITION" | "INTERNAL" | "INVALID_ARGUMENT" | "NOT_FOUND" | "OK" | "OUT_OF_RANGE" | "PERMISSION_DENIED" | "RESOURCE_EXHAUSTED" | "UNAUTHENTICATED" | "UNAVAILABLE" | "UNIMPLEMENTED" | "UNKNOWN" | "%future added value";
export type UpdateAdminPortalUserByAnotherAdminPortalUserInput = {
  addPermissions?: ReadonlyArray<AdminPortalPermissions> | null;
  adminPortalUserID: string;
  permissions?: ReadonlyArray<AdminPortalPermissions> | null;
  removePermissions?: ReadonlyArray<AdminPortalPermissions> | null;
};
export type UpdateAdminPortalUserMutation$variables = {
  input: UpdateAdminPortalUserByAnotherAdminPortalUserInput;
};
export type UpdateAdminPortalUserMutation$data = {
  readonly updateAdminPortalUserByAdminPortalUser: {
    readonly code: StatusCode;
    readonly message: string;
  };
};
export type UpdateAdminPortalUserMutation = {
  response: UpdateAdminPortalUserMutation$data;
  variables: UpdateAdminPortalUserMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateAdminPortalUserByAnotherAdminPortalUserStatus",
    "kind": "LinkedField",
    "name": "updateAdminPortalUserByAdminPortalUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "code",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateAdminPortalUserMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateAdminPortalUserMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9f09a6e2237bb35cf2082e03212e481b",
    "id": null,
    "metadata": {},
    "name": "UpdateAdminPortalUserMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateAdminPortalUserMutation(\n  $input: UpdateAdminPortalUserByAnotherAdminPortalUserInput!\n) {\n  updateAdminPortalUserByAdminPortalUser(input: $input) {\n    code\n    message\n  }\n}\n"
  }
};
})();

(node as any).hash = "71049bfa8f240a020f50ee6514fb0139";

export default node;
