/**
 * @generated SignedSource<<9b891ef615697f8234fedc4e54bfb334>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type StatusCode = "ABORTED" | "ALREADY_EXISTS" | "CANCELLED" | "DATA_LOSS" | "DEADLINE_EXCEEDED" | "FAILED_PRECONDITION" | "INTERNAL" | "INVALID_ARGUMENT" | "NOT_FOUND" | "OK" | "OUT_OF_RANGE" | "PERMISSION_DENIED" | "RESOURCE_EXHAUSTED" | "UNAUTHENTICATED" | "UNAVAILABLE" | "UNIMPLEMENTED" | "UNKNOWN" | "%future added value";
export type RemoveShortLinkMutation$variables = {
  id: string;
};
export type RemoveShortLinkMutation$data = {
  readonly removeShortLink: {
    readonly code: StatusCode;
    readonly message: string;
  };
};
export type RemoveShortLinkMutation = {
  response: RemoveShortLinkMutation$data;
  variables: RemoveShortLinkMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "BaseStatus",
    "kind": "LinkedField",
    "name": "removeShortLink",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "code",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoveShortLinkMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemoveShortLinkMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "11d349dbcac31cb9a662ed274f63d9c7",
    "id": null,
    "metadata": {},
    "name": "RemoveShortLinkMutation",
    "operationKind": "mutation",
    "text": "mutation RemoveShortLinkMutation(\n  $id: ID!\n) {\n  removeShortLink(id: $id) {\n    code\n    message\n  }\n}\n"
  }
};
})();

(node as any).hash = "1e6ce010db940f366bdc1ed536036d0e";

export default node;
