import { CreateDocumentMutation, EDocumentKey, EDocumentVisibility, GenerateDocumentPreSignedURLQuery, createDocumentMutation, generateDocumentPreSignedURLQuery } from 'src/graphql'
import { EdgeGraphqlService, S3Service } from 'src/services'

export class DocumentApi {
  static async uploadDocument(file: File, params: {
    userID?: string
    accountID?: string
    visibility: EDocumentVisibility
    documentKey: EDocumentKey
    stakeholderID?: string
    adminPortalUserID?: string
    // name: string
    // path: string
  }) {
    const { url } = await DocumentApi.generateDocumentPreSignedURLQuery({
      input: {
        accountID: params.accountID,
        visibility: params.visibility,
        documentKey: params.documentKey,
        fileName: file.name,
        stakeholderID: params.stakeholderID
      }
    })

    if (!url) {
      throw new Error('Failed to generate presigned url')
    }

    await S3Service.presignedUpload(url, file)

    const documentInput = {
      userID: params.userID,
      accountID: params.accountID,
      adminPortalUserID: params.adminPortalUserID,
      visibility: params.visibility,
      name: file.name,
      path: decodeURIComponent(new URL(url).pathname.replace(/^\//, ''))
    }

    return DocumentApi.createDocumentMutation({ input: documentInput }).then(
      (response) => response.document
    )
  }

  /**
   * SECTION: Queries
   */

  static generateDocumentPreSignedURLQuery(variables: GenerateDocumentPreSignedURLQuery['variables']) {
    return EdgeGraphqlService.queryAsPromise<GenerateDocumentPreSignedURLQuery>(generateDocumentPreSignedURLQuery, variables).then(
      (response) => response.generateDocumentPreSignedURL
    )
  }

  /**
   * SECTION: Mutations
   */

  static createDocumentMutation(variables: CreateDocumentMutation['variables']) {
    return EdgeGraphqlService.mutationAsPromise<CreateDocumentMutation>(createDocumentMutation, variables).then(
      (response) => response.createDocument
    )
  }
}
