import { Button, Checkbox, Form, Input, InputNumber, Modal, Radio } from 'antd'
import { ComponentProps, FC, useCallback, useEffect, useState } from 'react'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { PiiApi } from 'src/api'
import { useAsRef, useUnsubscribe } from 'src/hooks'
import { ECountryDocumentType, ECountryDocumentTypeTxt, ECountryEditLogAction, ECountryStatus } from 'src/interfaces'
import { MessageService } from 'src/services'

type FieldType = {
  nationalityTier?: number
  residencyTier?: number
  nationalityStatus?: ECountryStatus
  residencyStatus?: ECountryStatus
  documentTypes?: `${ECountryDocumentType}`[]
  comment?: string
}

export const DialogEditCountryConfig: FC<Omit<
  ComponentProps<typeof Modal>,
  'onOk' | 'afterClose'
> & {
  uid?: string
  btnProps?: ComponentProps<typeof Button>
  afterClose?: (saved?: boolean) => any
  id?: string
  editLogAction: ECountryEditLogAction
  nationalityTier?: number
  residencyTier?: number
  nationalityStatus?: string
  residencyStatus?: string
  documentTypes?: string[]
}> = ({
  uid,
  btnProps,
  id,
  editLogAction = ECountryEditLogAction.NATIONALITY_STATUS,
  nationalityTier,
  residencyTier,
  nationalityStatus,
  residencyStatus,
  documentTypes,
  ...props
}) => {
  const unsubscribe$ = useUnsubscribe()
  const [loading, setLoading] = useState(false)
  const [invisible, setInvisible] = useState(true)
  const afterCloseRef = useAsRef(props.afterClose)

  const [form] = Form.useForm()
  useEffect(() => {
    if (!invisible) {
      form.resetFields()
      form.setFieldsValue({
        nationalityTier,
        residencyTier,
        nationalityStatus,
        residencyStatus,
        documentTypes
      })
    }
  }, [
    invisible,
    form,
    nationalityTier,
    residencyTier,
    nationalityStatus,
    residencyStatus,
    documentTypes
  ])

  useEffect(() => {
    setInvisible(!props.open)
  }, [props.open])

  const onSubmit = useCallback(async (values: FieldType) => {
    if (!id) {
      return
    }

    setLoading(true)
    from(PiiApi.configCountry({
      input: {
        id,
        nationalityTier: values.nationalityTier,
        residencyTier: values.residencyTier,
        nationalityStatus: values.nationalityStatus,
        residencyStatus: values.residencyStatus,
        documentTypes: values.documentTypes,
        comment: values.comment
      }
    }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          MessageService.error(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(() => {
        MessageService.info(`${editLogAction} updated successfully`)
        setInvisible(true)
        afterCloseRef.current?.(true)
      })
  }, [id, editLogAction, afterCloseRef, unsubscribe$])

  return (
    <>
      <Button
        type="primary"
        {...btnProps}
        onClick={() => setInvisible(false)}
        className="text-capitalize"
      >
        {props.children || `${editLogAction}`}
      </Button>

      <Modal
        title={props.title || `${editLogAction}`}
        open={!invisible}
        centered
        closable={!loading} // display X icon
        keyboard={false} // disable close on press ESC
        maskClosable={false} // disable close on click outside
        okButtonProps={{ disabled: loading }}
        cancelButtonProps={{ disabled: loading }}
        okText="Ok"
        cancelText="Cancel"
        onOk={() => form.submit()}
        onCancel={() => setInvisible(true)}
      >
        {!invisible && (
          <Form
            form={form}
            name={`Edit ${editLogAction}`}
            labelCol={{ span: 8 }}
            style={{ maxWidth: 600 }}
            onFinish={onSubmit}
            autoComplete="off"
            disabled={loading}
          >
            {editLogAction === ECountryEditLogAction.NATIONALITY_TIER && (
              <Form.Item<FieldType>
                label="Nationality Tier"
                name="nationalityTier"
                rules={[{ required: true }]}
              >
                <InputNumber min={1}/>
              </Form.Item>
            )}
            {editLogAction === ECountryEditLogAction.RESIDENCY_TIER && (
              <Form.Item<FieldType>
                label="Residency Tier"
                name="residencyTier"
                rules={[{ required: true }]}
              >
                <InputNumber min={1}/>
              </Form.Item>
            )}
            {editLogAction === ECountryEditLogAction.NATIONALITY_STATUS && (
              <Form.Item<FieldType>
                label="Nationality Status"
                name="nationalityStatus"
                rules={[{ required: true }]}
              >
                <Radio.Group>
                  <Radio value="active">Active</Radio>
                  <Radio value="inactive">Inactive</Radio>
                </Radio.Group>
              </Form.Item>
            )}
            {editLogAction === ECountryEditLogAction.RESIDENCY_STATUS && (
              <Form.Item<FieldType>
                label="Residency Status"
                name="residencyStatus"
                rules={[{ required: true }]}
              >
                <Radio.Group>
                  <Radio value="active">Active</Radio>
                  <Radio value="inactive">Inactive</Radio>
                </Radio.Group>
              </Form.Item>
            )}
            {editLogAction === ECountryEditLogAction.DOCUMENTS && (
              <Form.Item<FieldType>
                label="Accepted IDs"
                name="documentTypes"
                rules={[{ required: true }]}
              >
                <Checkbox.Group
                  options={Object.values(ECountryDocumentType).map((value) => ({
                    label: ECountryDocumentTypeTxt[value],
                    value
                  }))}
                  className="fx-column"
                />
              </Form.Item>
            )}
            <Form.Item<FieldType>
              label="Comment"
              name="comment"
              rules={[{ required: true }]}
            >
              <Input.TextArea/>
            </Form.Item>
          </Form>
        )}
      </Modal>
    </>
  )
}
