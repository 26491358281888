/**
 * @generated SignedSource<<de222f0b4054cfa030435f7a4520311e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type KYCReviewDocumentType = "driving_license" | "national_identity_card" | "other" | "passport" | "proof_of_address" | "proof_source_of_fund" | "%future added value";
export type KYCReviewDocumentInput = {
  kycAttemptID: string;
};
export type KycReviewDocumentsQuery$variables = {
  input: KYCReviewDocumentInput;
};
export type KycReviewDocumentsQuery$data = {
  readonly kycReviewDocuments: {
    readonly documents: ReadonlyArray<{
      readonly comment: string | null;
      readonly createTime: any | null;
      readonly id: string;
      readonly type: KYCReviewDocumentType;
      readonly updateTime: any | null;
      readonly uploaderUserID: string;
      readonly url: string;
    }>;
  };
};
export type KycReviewDocumentsQuery = {
  response: KycReviewDocumentsQuery$data;
  variables: KycReviewDocumentsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "KYCReviewDocumentsOutput",
    "kind": "LinkedField",
    "name": "kycReviewDocuments",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "KYCReviewDocument",
        "kind": "LinkedField",
        "name": "documents",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updateTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "uploaderUserID",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "comment",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "KycReviewDocumentsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "KycReviewDocumentsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8fee91f1c45077f68021961ca0e46047",
    "id": null,
    "metadata": {},
    "name": "KycReviewDocumentsQuery",
    "operationKind": "query",
    "text": "query KycReviewDocumentsQuery(\n  $input: KYCReviewDocumentInput!\n) {\n  kycReviewDocuments(input: $input) {\n    documents {\n      id\n      createTime\n      updateTime\n      uploaderUserID\n      url\n      type\n      comment\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "551557c15a12d3a596bc29eb1bb05941";

export default node;
