import { KolStatisticsQuery, kolStatisticsQuery } from 'src/graphql'
import { EdgeAxios, EdgeGraphqlService } from 'src/services'
import { TAxiosResponseData } from 'types/axios'
export class KolTrackerApi {
  static kol(): TAxiosResponseData<Blob> {
    return EdgeAxios.get('/kol', {
      responseType: 'blob'
    })
  }

  /**
   * SECTION: Queries
   */

  static kolStatistics(variables: KolStatisticsQuery['variables']) {
    return EdgeGraphqlService.queryAsPromise<KolStatisticsQuery>(kolStatisticsQuery, variables).then(
      (response) => response.kolStatistics
    )
  }

  /**
   * SECTION: Mutations
   */
}
