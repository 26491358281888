import { AccountRaffleInfoQuery, BonusTicketsMutation, ReferralStatisticsQuery, accountRaffleInfoQuery, bonusTicketsMutation, referralStatisticsQuery } from 'src/graphql'
import { EdgeGraphqlService } from 'src/services'

export class ReferralApi {
  /**
   * SECTION: Queries
   */

  static referralStatistics(variables: ReferralStatisticsQuery['variables']) {
    return EdgeGraphqlService.queryAsPromise<ReferralStatisticsQuery>(referralStatisticsQuery, variables).then(
      (response) => response.referralStatistics
    )
  }

  static accountRaffleInfo(variables: AccountRaffleInfoQuery['variables']) {
    return EdgeGraphqlService.queryAsPromise<AccountRaffleInfoQuery>(accountRaffleInfoQuery, variables).then(
      (response) => response.accountRaffleInfo.data
    )
  }

  /**
   * SECTION: Mutations
   */

  static bonusTickets(variables: BonusTicketsMutation['variables']) {
    return EdgeGraphqlService.mutationAsPromise<BonusTicketsMutation>(bonusTicketsMutation, variables).then(
      (response) => response.bonusTickets.data
    )
  }
}
