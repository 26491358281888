import { Descriptions, DescriptionsProps } from 'antd'
import { FC, ReactNode, useMemo } from 'react'
import { TUserPii } from 'src/graphql'

interface IProps {
  user?: TUserPii | null
}

export const QuestionsAnswered: FC<IProps> = ({ user }) => {
  // const [questions, setQuestions] = useState<TKycQuestion[]>([])

  // const items: DescriptionsProps['items'] = useMemo(
  //   () => questions.map(({ id, display }) => ({
  //     key: id,
  //     label: display,
  //     children: user?.currentKyc?.questionAnswers?.find(
  //       (answer) => answer.questionID === id
  //     )?.option?.display || '--'
  //   })),
  //   [questions, user?.currentKyc?.questionAnswers]
  // )

  // useUnsubscribeEffect((unsubscribe$) => {
  //   from(PiiApi.questionSet({ input: { questionSet: 'kyc_v1' } }))
  //     .pipe(
  //       takeUntil(unsubscribe$),
  //       catchError((error) => {
  //         MessageService.error(error)
  //         return EMPTY
  //       })
  //     )
  //     .subscribe((data) => setQuestions(data.questions))
  // }, [])

  const items: DescriptionsProps['items'] = useMemo(
    () => user?.currentKyc?.questionAnswers?.reduce<Array<{
      key: string
      label: string
      children: ReactNode[]
    }>>((acc, { id, question, option }) => {
      const existQuestion = acc.find((item) => item.key === question.id)
      if (existQuestion) {
        existQuestion.children.push(<div key={id}>{option?.display}</div>)
      } else {
        acc.push({
          key: question.id,
          label: question.display,
          children: [<div key={id}>{option?.display}</div>]
        })
      }
      return acc
    }, []),
    [user?.currentKyc?.questionAnswers]
  )

  return (
    <section>
      <Descriptions
        layout="vertical"
        size="small"
        column={1}
        bordered
        items={items}
      />
    </section>
  )
}
