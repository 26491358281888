/**
 * @generated SignedSource<<98aa41ce7b89313248c7643d7d1c0d2d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type KYBReviewAction = "approve" | "comment" | "escalate" | "in_progress" | "info_request" | "on_hold" | "reject" | "%future added value";
export type ReviewKYBAttemptInput = {
  action: KYBReviewAction;
  attemptID: string;
  message?: string | null;
};
export type ReviewKYBAttemptMutation$variables = {
  input: ReviewKYBAttemptInput;
};
export type ReviewKYBAttemptMutation$data = {
  readonly reviewKYBAttempt: {
    readonly data: {
      readonly id: string;
    };
  };
};
export type ReviewKYBAttemptMutation = {
  response: ReviewKYBAttemptMutation$data;
  variables: ReviewKYBAttemptMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "KYBAttemptStatus",
    "kind": "LinkedField",
    "name": "reviewKYBAttempt",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "KYBAttempt",
        "kind": "LinkedField",
        "name": "data",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ReviewKYBAttemptMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ReviewKYBAttemptMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "cfa9ea5fe5e7bf496358f463e747b858",
    "id": null,
    "metadata": {},
    "name": "ReviewKYBAttemptMutation",
    "operationKind": "mutation",
    "text": "mutation ReviewKYBAttemptMutation(\n  $input: ReviewKYBAttemptInput!\n) {\n  reviewKYBAttempt(input: $input) {\n    data {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "594e5bf0faf00b561b48b92cd4ac867d";

export default node;
