import { Button, Form, Input, Modal } from 'antd'
import { ComponentProps, FC, useCallback, useEffect, useState } from 'react'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { PiiApi } from 'src/api'
import { TAdminInvitee, TUserPii } from 'src/graphql'
import { useAsRef, useUnsubscribe } from 'src/hooks'
import { MessageService } from 'src/services'

type FieldType = {
  comment: string
}

export const DialogKycComment: FC<Omit<
  ComponentProps<typeof Modal>,
  'onOk' | 'afterClose'
> & {
  user: TUserPii
  btnProps?: ComponentProps<typeof Button>
  afterClose?: (saved?: boolean) => any
}> = ({
  user,
  btnProps,
  ...props
}) => {
  const unsubscribe$ = useUnsubscribe()
  const [loading, setLoading] = useState(false)
  const [invisible, setInvisible] = useState<boolean | TAdminInvitee>(true)

  useEffect(() => {
    setInvisible(!props.open)
  }, [props.open])

  const afterCloseRef = useAsRef(props.afterClose)

  const [form] = Form.useForm<FieldType>()
  useEffect(() => {
    if (!invisible) {
      form.resetFields()
    }
  }, [invisible, form])

  const submit = useCallback((values: FieldType) => {
    const input = {
      comment: values.comment!,
      kycAttemptID: user.currentKyc?.id as string
    }
    if (!input.kycAttemptID || !input.comment) {
      return
    }

    setLoading(true)
    from(PiiApi.addKYCAttemptComment({ input }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          MessageService.error(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(() => {
        MessageService.info('Add comment successfully')
        setInvisible(true)
        afterCloseRef.current?.(true)
      })
  }, [afterCloseRef, unsubscribe$, user.currentKyc?.id])

  return (
    <>
      <Button
        type="primary"
        {...btnProps}
        onClick={() => setInvisible(false)}
      >
        {props.children || 'Comment'}
      </Button>

      <Modal
        title={props.title || 'Comment'}
        open={!invisible}
        centered
        closable={!loading} // display X icon
        keyboard={false} // disable close on press ESC
        maskClosable={false} // disable close on click outside
        okButtonProps={{ disabled: loading }}
        cancelButtonProps={{ disabled: loading }}
        okText="Ok"
        cancelText="Cancel"
        onOk={() => form.submit()}
        onCancel={() => setInvisible(true)}
      >
        {!invisible && (
          <Form
            form={form}
            layout="vertical"
            name="kyc-comment"
            style={{ maxWidth: 600 }}
            onFinish={submit}
            autoComplete="off"
          >
            <Form.Item<FieldType>
              label="Comment"
              name="comment"
              rules={[{ required: true }]}
            >
              <Input.TextArea/>
            </Form.Item>
          </Form>
        )}
      </Modal>
    </>
  )
}
