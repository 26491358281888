import { graphql } from 'relay-runtime'

import { UpdateAccountMutation } from './__generated__/UpdateAccountMutation.graphql'

export type { UpdateAccountMutation }

export const updateAccountMutation = graphql`
  mutation UpdateAccountMutation($input: UpdateAccountByAdminPortalInput!) {
    updateAccountByAdminPortal(input: $input) {
      code
      message
    }
  }
`
