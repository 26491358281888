import { Space } from 'antd'
import { Link } from 'react-router-dom'
import { ButtonConfirm } from 'src/atoms'
import { TArticle, TShortLink } from 'src/graphql'
import { DialogArticle } from 'src/partials'

interface Actions<T = TArticle> {
  onUpdate?: (record: T) => any
  onRemove?: (record: T) => any
}

export const renderColumns = ({ onUpdate, onRemove }: Actions) => [
  {
    title: 'Article Title',
    dataIndex: 'title',
    key: 'title',
    render: (val: string) => <span title={val} className="oneline-text" style={{ maxWidth: '15vw' }}>{val}</span>
  },
  {
    title: 'Article Link',
    dataIndex: 'url',
    key: 'url',
    render: (val: string) => val && <Link target="_blank" to={val} title={val} className="oneline-text" style={{ maxWidth: '15vw' }}>{val}</Link>
  },
  {
    title: 'Article Short Link',
    dataIndex: 'shortLink',
    key: 'shortLink',
    render: (val: TShortLink) => val?.[0]?.url && <Link target="_blank" to={val[0].url} title={val[0].url} className="oneline-text" style={{ maxWidth: '15vw' }}>{val[0].url}</Link>
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    render: (val: string) => <span title={val} className="oneline-text" style={{ maxWidth: '20vw' }}>{val}</span>
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    render: (_: any, record: TArticle) => (
      <Space>
        <DialogArticle
          id={record.id}
          btnProps={{ size: 'small', type: 'link' }}
          afterClose={(doc) => doc && onUpdate?.(doc)}
        >
          Edit
        </DialogArticle>

        {onRemove && (
          <ButtonConfirm
            title="Are you sure delete this article?"
            content="You can no longer undo after deleting this article."
            onOk={() => onRemove(record)}
            btnProps={{
              danger: true,
              size: 'small',
              type: 'link'
            }}
          >
            Delete
          </ButtonConfirm>
        )}
      </Space>
    )
  }
]
