import { ConfigProvider } from 'antd'
import { FC } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { AppLayout } from './layout'

export const App: FC = () => {
  return (
    <BrowserRouter>
      <ConfigProvider theme={{ components: { Modal: { wireframe: true } } }}>
        <AppLayout/>
      </ConfigProvider>
    </BrowserRouter>
  )
}
