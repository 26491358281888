import { Button } from 'antd'
import { ComponentProps, FC, MouseEvent, useCallback, useState } from 'react'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { AuthApi } from 'src/api'
import { useUnsubscribe } from 'src/hooks'
import { MessageService } from 'src/services'
import { EventUtils } from 'src/utils'

interface IProps extends ComponentProps<typeof Button> {
  params?: Parameters<typeof AuthApi.requestOAuthUrl>[1]
}

export const GoogleSignInBtn: FC<IProps> = ({ params, onClick, ...props }) => {
  const unsubscribe$ = useUnsubscribe()
  const [loading, setLoading] = useState(false)

  const onSubmit = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    EventUtils.preventDefault(e)

    setLoading(true)
    from(AuthApi.requestOAuthUrl('google', params))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          MessageService.error(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(({ data }) => {
        console.log(data)
        window.location.href = data.location
      })
  }, [params, unsubscribe$])

  return (
    <Button
      {...props}
      disabled={loading || props.disabled}
      onClick={onSubmit}
    >
      {props.children || 'Google'}
    </Button>
  )
}
