import { cloneDeep } from 'lodash'
import { GraphQLTaggedNode, OperationType, Variables } from 'relay-runtime'
import { BehaviorSubject } from 'rxjs'
import { WithOutNextComplete } from 'types/rxjs'
import { EdgeGraphqlService, PiiGraphqlService } from './graphql.service'

const defaultPagingInfo: {
  totalCount: number
  pageInfo: {
    startCursor: string
    endCursor: string
    hasPreviousPage: boolean
    hasNextPage: boolean
  }
} = Object.freeze({
  pageInfo: {
    startCursor: '',
    endCursor: '',
    hasNextPage: true,
    hasPreviousPage: false
  },
  totalCount: 1
})

interface IPaginationParams extends Variables {
  after?: string
  before?: string
  first?: number
  last?: number
  where?: Record<string, any> | null
}

interface IPaginationResponse<T = any> {
  edges: {
    cursor: string
    node: T
  }[]
  pageInfo: {
    startCursor: string
    endCursor: string
    hasNextPage: boolean
    hasPreviousPage: boolean
  }
  totalCount: number
}

interface IQuery<T> {
  variables?: IPaginationParams
  response: Record<string, IPaginationResponse<T>>
}

export class PaginationService<T extends { id?: string }, Q extends Omit<OperationType, 'variables'> & { variables?: OperationType['variables'] } = IQuery<T> > {
  constructor(
    private readonly query: GraphQLTaggedNode,
    private readonly options?: { usePii?: boolean }
  ) {
  }

  /**
   * loading
   */

  private readonly _loading$ = new BehaviorSubject(false)
  get loading$(): WithOutNextComplete<typeof this._loading$> {
    return this._loading$
  }

  get loading() {
    return this._loading$.getValue()
  }

  private set loading(loading) {
    this._loading$.next(loading)
  }

  /**
   * items
   */

  private readonly _items$ = new BehaviorSubject<T[]>([])

  get items$(): WithOutNextComplete<typeof this._items$> {
    return this._items$
  }

  get items() {
    return this._items$.getValue()
  }

  private set items(items) {
    this._items$.next(items)
  }

  /**
   * error
   */

  private readonly _error$ = new BehaviorSubject<Error | null>(null)
  get error$() {
    return this._error$.asObservable()
  }

  get error() {
    return this._error$.getValue()
  }

  private set error(error) {
    this._error$.next(error)
  }

  /**
   * error
   */

  private readonly _paging$ = new BehaviorSubject(cloneDeep(defaultPagingInfo))

  get paging$() {
    return this._paging$.asObservable()
  }

  get paging() {
    return this._paging$.getValue()
  }

  private set paging(paging) {
    this._paging$.next(paging)
  }

  /**
   * implement
   */

  get canPrev() {
    return !this.error && !this.loading && this.paging.pageInfo.hasPreviousPage
  }

  get canNext() {
    return !this.error && !this.loading && this.paging.pageInfo.hasNextPage
  }

  reset() {
    this.items = []
    this.error = null
    this.loading = false
    this.paging = cloneDeep(defaultPagingInfo)
  }

  updateItem(item: T) {
    const index = this.items.findIndex((i) => i.id === item.id)
    if (index !== -1) {
      this.items[index] = {
        ...this.items[index],
        ...item
      }
      this.items = [...this.items]
    }
  }

  private variables: Q['variables'] = {}
  fetch(variables?: Q['variables']) {
    if (this.loading) {
      return
    }

    this.loading = true
    this.variables = variables || this.variables
    this.variables = {
      ...this.variables,
      after: this.variables?.after || null,
      before: this.variables?.before || null,
      first: this.variables?.last ? null : (this.variables?.first || 10),
      last: this.variables?.last || null
    }
    return (this.options?.usePii ? PiiGraphqlService : EdgeGraphqlService).queryAsPromise<Q>(this.query, this.variables)
      .then((response) => {
        this.loading = false
        const { edges, pageInfo, totalCount } = Object.values(response)[0] as IPaginationResponse<T>
        this.paging = { pageInfo, totalCount }
        this.items = edges.map((edge) => edge.node)
      })
      .catch((error) => {
        this.loading = false
        this.error = error
        throw error
      })
  }

  prev(variables: Q['variables'] = {}) {
    if (this.canPrev) {
      return this.fetch({
        before: this.paging.pageInfo.startCursor || null,
        last: 10,
        ...variables
      })
    }
  }

  next(variables: Q['variables'] = {}) {
    if (!this.loading && this.canNext) {
      return this.fetch({
        after: this.paging.pageInfo.endCursor || null,
        first: 10,
        ...variables
      })
    }
  }
}
