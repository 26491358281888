import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Button, Modal, ModalFuncProps } from 'antd'
import { ComponentProps, FC, ReactNode, useCallback } from 'react'

interface IProps extends ModalFuncProps {
  children?: ReactNode
  btnProps?: ComponentProps<typeof Button>
}

export const ButtonConfirm: FC<IProps> = (props) => {
  const [modal, contextHolder] = Modal.useModal()

  const confirm = useCallback(() => modal.confirm({
    ...props,
    title: props.title || 'Confirm',
    icon: props.icon || <ExclamationCircleOutlined/>,
    content: props.content || 'You can no longer undo after deleting this article.',
    okText: props.okText || 'Yes',
    cancelText: props.cancelText || 'No',
    okButtonProps: props.okButtonProps || { danger: true },
    cancelButtonProps: props.cancelButtonProps || {},
    onOk: props.onOk,
    onCancel: props.onCancel
  }), [modal, props])

  return (
    <>
      <Button
        {...props.btnProps}
        onClick={confirm}
      >
        {props.btnProps?.children || props.children || 'Confirm'}
      </Button>
      {contextHolder}
    </>
  )
}
