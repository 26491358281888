import { graphql } from 'relay-runtime'

import { RemoveShortLinkMutation } from './__generated__/RemoveShortLinkMutation.graphql'

export type { RemoveShortLinkMutation }

export const removeShortLinkMutation = graphql`
  mutation RemoveShortLinkMutation($id: ID!) {
    removeShortLink(id: $id) {
      code
      message
    }
  }
`
