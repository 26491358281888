import { adminPortalUserMeQuery, AdminPortalUserMeQuery, TAuthProfile } from 'src/graphql'
import { EdgeAxios, EdgeGraphqlService, EResponseStatus } from 'src/services'
import { TAxiosResponseData } from 'types/axios'

export class AuthApi {
  static readonly _prefix = '/auth'

  static login(payload: {
    email: string
    password: string
  }): TAxiosResponseData<{
    status: EResponseStatus
    message: string
  }> {
    return EdgeAxios.post(`${AuthApi._prefix}/login`, payload)
  }

  static logout(): TAxiosResponseData<{
    status: EResponseStatus
    message: string
  }> {
    return EdgeAxios.get(`${AuthApi._prefix}/logout`)
  }

  static register(payload: {
    email: string
    first_name: string
    last_name: string
    password: string
    confirm_password: string
  }): TAxiosResponseData<{
    status: EResponseStatus
    message: string
  }> {
    return EdgeAxios.post(`${AuthApi._prefix}/register`, {
      ...payload,
      is_business: 'false'
    })
  }

  static resendRegisterConfirm(payload: { email: string }): TAxiosResponseData<{
    status: EResponseStatus
    message: string
  }> {
    return EdgeAxios.post(`${AuthApi._prefix}/confirm/resend`, payload)
  }

  static confirm(params: { cnf: string }): TAxiosResponseData<{
    status: EResponseStatus
    message: string
  }> {
    return EdgeAxios.get(`${AuthApi._prefix}/confirm`, { params })
  }

  static requestOAuthUrl(provider: 'google' | 'microsoft', params?: {
    isSignUp?: boolean
  }): TAxiosResponseData<{
    status: string
    location: string
  }> {
    return EdgeAxios.get(`${this._prefix}/oauth2/${provider}`, {
      params: {
        ...params,
        redirect_uri: `${window.location.origin}/auth/oauth2/callback/${provider}`
      }
    })
    // .then((response) => {
    //   const url = new URL(response.data.location)
    //   console.log('auth.ts:23', response.data.location)
    //   const redirectUri = url.searchParams.get('redirect_uri') as string
    //   url.searchParams.set('redirect_uri', redirectUri.replace(new URL(redirectUri).origin, window.location.origin))
    //   response.data.location = url.href
    //   console.log('auth.ts:26', response.data.location)
    //   return response
    // })
  }

  static callbackOAuth(provider: 'google' | 'microsoft', params?: { [key: string]: any }) {
    return EdgeAxios.get(`${this._prefix}/oauth2/callback/${provider}`, {
      params: {
        ...params,
        redirect_uri: `${window.location.origin}/auth/oauth2/callback/${provider}`
      }
    })
  }

  /**
   * SECTION: Queries
   */

  static profile() {
    return EdgeGraphqlService.queryAsPromise<AdminPortalUserMeQuery>(adminPortalUserMeQuery).then(
      (response) => {
        if (!response.adminPortalUserMe?.admin) {
          throw new Error(response.adminPortalUserMe?.message || 'User not found')
        }

        return response.adminPortalUserMe.admin as TAuthProfile
      }
    )
  }

  /**
   * SECTION: Mutations
   */

  // static update(variables: UpdateUserMutation['variables']) {
  //   return EdgeGraphqlService.mutationAsPromise<UpdateUserMutation>(updateUserMutation, variables).then(
  //     (response) => response.updateUser
  //   )
  // }
}
