import { Table } from 'antd'
import { FC, useMemo } from 'react'
import { TBusinessUserPii } from 'src/graphql'
import { KYBDocumentType, KYBDocumentTypeLabel } from 'src/interfaces'
import { TzService } from 'src/services'
import { renderColumns } from './columns'

interface IProps {
  user?: TBusinessUserPii | null
}

export const BusinessKybDocument: FC<IProps> = ({ user }) => {
  const dataSource = useMemo(() => {
    return user?.currentKyb?.documents?.map((doc) => ({
      'document-type': KYBDocumentTypeLabel[doc.type as KYBDocumentType] || doc.type,
      'uploaded-by': doc.uploaderUserID,
      'uploaded-time': TzService.format(doc.createTime, 'HH:mm YYYY-MM-DD'),
      comment: doc.comment as string,
      link: doc.url
    }))
  }, [user])

  return (
    <section className="fx fx-column fx-extend">
      <Table
        bordered
        rowKey="id"
        className="fx-extend"
        pagination={false}
        dataSource={dataSource}
        columns={renderColumns()}
        scroll={{ x: true }}
      />
    </section>
  )
}
