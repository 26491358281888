/**
 * @generated SignedSource<<69e651fd6b1398265861102b3aa34bf8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ArticleActiveStatus = "activated" | "deactivated" | "%future added value";
export type ShortLinkActiveStatus = "activated" | "deactivated" | "%future added value";
export type StatusCode = "ABORTED" | "ALREADY_EXISTS" | "CANCELLED" | "DATA_LOSS" | "DEADLINE_EXCEEDED" | "FAILED_PRECONDITION" | "INTERNAL" | "INVALID_ARGUMENT" | "NOT_FOUND" | "OK" | "OUT_OF_RANGE" | "PERMISSION_DENIED" | "RESOURCE_EXHAUSTED" | "UNAUTHENTICATED" | "UNAVAILABLE" | "UNIMPLEMENTED" | "UNKNOWN" | "%future added value";
export type CreateArticleInput = {
  activeStatus?: ArticleActiveStatus | null;
  deleteTime?: any | null;
  description?: string | null;
  title?: string | null;
  url: string;
};
export type CreateArticleMutation$variables = {
  input: CreateArticleInput;
};
export type CreateArticleMutation$data = {
  readonly createArticle: {
    readonly article: {
      readonly activeStatus: ArticleActiveStatus;
      readonly createTime: any | null;
      readonly description: string | null;
      readonly id: string;
      readonly shortLink: ReadonlyArray<{
        readonly activeStatus: ShortLinkActiveStatus;
        readonly articleID: string;
        readonly createTime: any | null;
        readonly id: string;
        readonly updateTime: any | null;
        readonly url: string;
      }> | null;
      readonly title: string | null;
      readonly updateTime: any | null;
      readonly url: string;
    } | null;
    readonly code: StatusCode;
    readonly message: string;
  };
};
export type CreateArticleMutation = {
  response: CreateArticleMutation$data;
  variables: CreateArticleMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createTime",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updateTime",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "activeStatus",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ArticleStatus",
    "kind": "LinkedField",
    "name": "createArticle",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "code",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Article",
        "kind": "LinkedField",
        "name": "article",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ShortLink",
            "kind": "LinkedField",
            "name": "shortLink",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "articleID",
                "storageKey": null
              },
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateArticleMutation",
    "selections": (v6/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateArticleMutation",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "3a6541586171d5cb455f207fdb6cfd19",
    "id": null,
    "metadata": {},
    "name": "CreateArticleMutation",
    "operationKind": "mutation",
    "text": "mutation CreateArticleMutation(\n  $input: CreateArticleInput!\n) {\n  createArticle(input: $input) {\n    code\n    message\n    article {\n      id\n      createTime\n      updateTime\n      url\n      title\n      description\n      activeStatus\n      shortLink {\n        id\n        createTime\n        updateTime\n        articleID\n        url\n        activeStatus\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9d7993fb818c4ad19339ba1180fa4e52";

export default node;
