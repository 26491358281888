import { graphql } from 'relay-runtime'

import { RemoveArticleMutation } from './__generated__/RemoveArticleMutation.graphql'

export type { RemoveArticleMutation }

export const removeArticleMutation = graphql`
  mutation RemoveArticleMutation($id: ID!) {
    removeArticle(id: $id) {
      code
      message
    }
  }
`
