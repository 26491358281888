/**
 * @generated SignedSource<<9a331bad20678581d18b796067d0ccf3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type BonusTicketsInput = {
  accountID: string;
  bonusTickets: number;
};
export type BonusTicketsMutation$variables = {
  input: BonusTicketsInput;
};
export type BonusTicketsMutation$data = {
  readonly bonusTickets: {
    readonly data: {
      readonly totalTickets: number;
      readonly totalUsedTickets: number;
    };
  };
};
export type BonusTicketsMutation = {
  response: BonusTicketsMutation$data;
  variables: BonusTicketsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AccountRaffleStatus",
    "kind": "LinkedField",
    "name": "bonusTickets",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountRaffleData",
        "kind": "LinkedField",
        "name": "data",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalTickets",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalUsedTickets",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BonusTicketsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BonusTicketsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "cc3558e710b533a73346305ed85252fb",
    "id": null,
    "metadata": {},
    "name": "BonusTicketsMutation",
    "operationKind": "mutation",
    "text": "mutation BonusTicketsMutation(\n  $input: BonusTicketsInput!\n) {\n  bonusTickets(input: $input) {\n    data {\n      totalTickets\n      totalUsedTickets\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "54799aed97b3ab3b8f9983e779126a19";

export default node;
