import { graphql } from 'relay-runtime'

import { AdminPortalUserMeQuery } from './__generated__/AdminPortalUserMeQuery.graphql'

export type { AdminPortalUserMeQuery }

export type TAuthProfile = Partial<DeepWriteable<DeepExclude<AdminPortalUserMeQuery, null>>['response']['adminPortalUserMe']['admin']>

export const adminPortalUserMeQuery = graphql`
  query AdminPortalUserMeQuery {
    adminPortalUserMe {
      code
      message
      admin {
        ... on AdminPortalUser {
          id
          createTime
          updateTime
          firstName
          lastName
          email
          confirmed
          attemptCount
          lastAttempt
          locked
          oAuth2UID
          oAuth2Provider
        }
      }
    }
  }
`
