import { Table } from 'antd'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { AccountApi, DocumentApi } from 'src/api'
import { EDocumentKey, EDocumentVisibility, TAccountDocuments } from 'src/graphql'
import { useBehaviorMapper, useUnsubscribe } from 'src/hooks'
import { AuthService, MessageService } from 'src/services'
import { renderColumns } from './columns'

interface IProps {
  accountID?: string
}

const documentKeys = [
  EDocumentKey.ACC_FILE_CERTIFICATE_OF_INCORPORATION,
  EDocumentKey.ACC_FILE_CERTIFICATE_OF_INCUMBENCY,
  EDocumentKey.ACC_FILE_MEMORANDUM_AND_ARTICLES_OF_ASSOCIATION,
  EDocumentKey.ACC_FILE_BOARD_RESOLUTION,
  EDocumentKey.ACC_FILE_CORPORATE_STRUCTURE_OWNERSHIP_CHART,
  EDocumentKey.ACC_FILE_REGISTERS_OF_DIRECTORS,
  EDocumentKey.ACC_FILE_REGISTER_OF_SHAREHOLDERS,
  EDocumentKey.ACC_FILE_BENEFICIAL_OWNER_DECLARATION_FORM
]

export const AccountDocuments: FC<IProps> = ({ accountID }) => {
  const unsubscribe$ = useUnsubscribe()
  const user = useBehaviorMapper(AuthService.user$)
  const [loading, setLoading] = useState(false)
  const [documents, setDocuments] = useState<TAccountDocuments[]>([])

  const fetchDocuments = useCallback(() => {
    if (!accountID) {
      MessageService.error('Something went wrong!')
      return
    }

    setLoading(true)
    from(AccountApi.accountDocuments({
      input: {
        accID: accountID
      }
    }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          MessageService.error(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe((documents) => {
        setDocuments(documents)
      })
  }, [accountID, unsubscribe$])

  const documentsToShow = useMemo(() => {
    const list = documentKeys.map(key => ({
      key,
      docs: [] as TAccountDocuments[]
    }))

    documents.forEach(doc => {
      const item = list.find(el => el.key === doc.key)

      if (item) {
        item.docs.push(doc)
      }
    })

    return list
  }, [documents])

  const onUploadDocuments = useCallback(async (files: File[], documentKey: EDocumentKey) => {
    if (!user?.id || !accountID) {
      MessageService.error('Something went wrong!')
      return
    }

    const promise = (async () => {
      for (const file of files) {
        await DocumentApi.uploadDocument(file as unknown as File, {
          adminPortalUserID: user.id,
          accountID,
          visibility: EDocumentVisibility.ACCOUNT,
          documentKey
        })
      }
    })()

    setLoading(true)

    from(promise)
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          MessageService.axiosError(error)
          return EMPTY
        }),
        finalize(() => {
          setLoading(false)
        })
      )
      .subscribe(() => {
        MessageService.success('Upload documents successfully')
        fetchDocuments()
      })
  }, [unsubscribe$, user, accountID, fetchDocuments])

  useEffect(() => {
    fetchDocuments()
  }, [fetchDocuments])

  return (
    <Table
      bordered
      className="fx-extend"
      pagination={false}
      loading={loading}
      dataSource={documentsToShow}
      columns={renderColumns({ onUploadDocuments })}
      scroll={{ x: true }}
    />
  )
}
