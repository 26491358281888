/**
 * @generated SignedSource<<efc8866ed7ae30d352ca350f19971ec1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type KYCReviewAction = "approve" | "deny_black_list" | "deny_close" | "deny_reject" | "escalate" | "in_progress" | "on_hold" | "request_info" | "%future added value";
export type ReviewKYCAttemptInput = {
  action: KYCReviewAction;
  attemptID: string;
  message?: string | null;
};
export type ReviewKYCAttemptMutation$variables = {
  input: ReviewKYCAttemptInput;
};
export type ReviewKYCAttemptMutation$data = {
  readonly reviewKYCAttempt: {
    readonly data: {
      readonly id: string;
    };
  };
};
export type ReviewKYCAttemptMutation = {
  response: ReviewKYCAttemptMutation$data;
  variables: ReviewKYCAttemptMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "KYCAttemptStatus",
    "kind": "LinkedField",
    "name": "reviewKYCAttempt",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "KYCAttempt",
        "kind": "LinkedField",
        "name": "data",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ReviewKYCAttemptMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ReviewKYCAttemptMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b60249808468e316038c9dcd1f78fa04",
    "id": null,
    "metadata": {},
    "name": "ReviewKYCAttemptMutation",
    "operationKind": "mutation",
    "text": "mutation ReviewKYCAttemptMutation(\n  $input: ReviewKYCAttemptInput!\n) {\n  reviewKYCAttempt(input: $input) {\n    data {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3f866d0b09210ee0b734baecd61593d2";

export default node;
