import { graphql } from 'relay-runtime'

import { AdminUpdatePersonalAddressMutation } from './__generated__/AdminUpdatePersonalAddressMutation.graphql'

export type { AdminUpdatePersonalAddressMutation }

export const adminUpdatePersonalAddressMutation = graphql`
  mutation AdminUpdatePersonalAddressMutation($input: PersonalAddressInput!) {
    adminUpdatePersonalAddress(input: $input) {
      data {
        ... on KYCAttempt {
          id
          createTime
          updateTime
        }
      }
    }
  }
`
