import { graphql } from 'relay-runtime'

export type { GenerateKYBDocumentsSignedURLMutation } from './__generated__/GenerateKYBDocumentsSignedURLMutation.graphql'

export const generateKYBDocumentsSignedURLMutation = graphql`
  mutation GenerateKYBDocumentsSignedURLMutation($input: GenerateKYBDocumentsSignedURLInput!) {
    generateKYBDocumentsSignedURL(input: $input) {
      signedURL
      key
    }
  }
`
