import { Descriptions, DescriptionsProps } from 'antd'
import { FC, useMemo, useState } from 'react'
import { EMPTY, catchError, from, takeUntil } from 'rxjs'
import { AccountApi } from 'src/api'
import { ignore } from 'src/constants'
import { TAccountIdentity } from 'src/graphql'
import { useUnsubscribeEffect } from 'src/hooks'
import { getEntityTypeTxt, getSourceOfFundTxt } from 'src/interfaces'
import { CountriesStore } from 'src/store'

interface IProps {
  accountID?: string
}

export const CompanyProfile: FC<IProps> = ({ accountID }) => {
  const [accountIdentity, setAccountIdentity] = useState<TAccountIdentity | null>(null)

  useUnsubscribeEffect((unsubscribe$) => {
    if (!accountID) return
    from(AccountApi.accountIdentity({ id: accountID }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          ignore(error)
          return EMPTY
        })
      )
      .subscribe((accountIdentity) => {
        setAccountIdentity(accountIdentity)
      })
  }, [accountID])

  const items: DescriptionsProps['items'] = useMemo(() => ([
    {
      key: '1',
      label: 'Display name',
      children: accountIdentity?.companyName
    },
    {
      key: '2',
      label: 'Country of incorporation',
      children: CountriesStore.getCountryName(accountIdentity?.countryOfIncorporation)
    },
    {
      key: '3',
      label: 'Company',
      children: accountIdentity?.companyName
    },
    {
      key: '4',
      label: 'Company ID',
      children: ''
    },
    {
      key: '5',
      label: 'Company registration number',
      children: accountIdentity?.companyRegistrationNumber
    },
    {
      key: '6',
      label: 'Date of incorporation',
      children: accountIdentity?.dateOfIncorporation
    },
    {
      key: '7',
      label: 'Registered office address',
      children: accountIdentity?.registeredOfficeAddress
    },
    {
      key: '8',
      label: 'Entity type',
      children: getEntityTypeTxt(accountIdentity?.entityType)
    },
    {
      key: '9',
      label: 'Source of funds',
      children: getSourceOfFundTxt(accountIdentity?.sourceOfFunds)
    }
  ].map(el => ({
    ...el,
    labelStyle: {
      width: '50%'
    }
  }))), [accountIdentity])

  return (
    <section className="fx fx-column fx-extend">
      <Descriptions layout="horizontal" size="small" column={1} bordered items={items}/>
    </section>
  )
}
