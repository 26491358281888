import { DownloadOutlined, GiftOutlined } from '@ant-design/icons'
import { Button, Input, Table } from 'antd'
import { FC, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { KolTrackerApi } from 'src/api'
import { TKolStatisticsStatus } from 'src/graphql'
import { useUnsubscribe } from 'src/hooks'
import { DialogBulkReward } from 'src/partials'
import { ERoutes, generate } from 'src/router'
import { BreadcrumbService, MessageService } from 'src/services'
import { EPaths } from '../../routes.path'
import { renderColumns } from './columns'

export const MarketingKolTracker: FC = () => {
  /**
   * breadcrumb
   */
  useEffect(() => {
    BreadcrumbService.items = [{
      route: EPaths.KOL_TRACKER,
      label: 'KOL Tracker'
    }]
    return () => {
      BreadcrumbService.items = []
    }
  }, [])

  const navigate = useNavigate()
  const unsubscribe$ = useUnsubscribe()
  const [loading, setLoading] = useState(false)
  const [kolStatistics, setKolStatistics] = useState<TKolStatisticsStatus>({})
  const [filter, setFilter] = useState({ keyword: '' })

  const fetch = useCallback((input: Parameters<typeof KolTrackerApi.kolStatistics>[0]['input']) => {
    setLoading(true)
    from(
      KolTrackerApi.kolStatistics({
        input: {
          ...input,
          query: filter.keyword.trim()
        }
      })
    )
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          MessageService.error(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe((kolStatistics) => {
        setKolStatistics(kolStatistics)
      })
  }, [filter.keyword, unsubscribe$])

  const handleExportCSV = useCallback((data: Blob) => {
    try {
      const url = window.URL.createObjectURL(data)

      const a = document.createElement('a')
      a.href = url
      a.download = 'kol.csv'

      document.body.appendChild(a)
      a.click()

      window.URL.revokeObjectURL(url)
      document.body.removeChild(a)
    } catch (error) {
      MessageService.error('Something went wrong!')
    }
  }, [])

  const onClickExport = useCallback(() => {
    from(KolTrackerApi.kol())
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          MessageService.error(error)
          return EMPTY
        })
      )
      .subscribe((res) => {
        handleExportCSV(res?.data)
      })
  }, [handleExportCSV, unsubscribe$])

  useEffect(() => {
    fetch({
      pageNumber: 1,
      pageSize: 10
    })
  }, [fetch])

  return (
    <section className="fx fx-column fx-extend">
      <div className="fx fx-jc-space-between mb-2">
        <div className="fx fx-extend fx-ai-center gap-2">
          <Input
            allowClear
            placeholder="Search..."
            style={{ flex: '0 0 200px' }}
            readOnly={loading}
            value={filter.keyword}
            onChange={(e) => setFilter((prev) => ({
              ...prev,
              keyword: e.target.value
            }))}
          />
        </div>

        <div className="fx fx-ai-center gap-2">
          <Button
            type="link"
            onClick={() => navigate(generate(ERoutes.KOL_POINT_DISTRIBUTION))}
          >
            Point Distributions
          </Button>
          <DialogBulkReward>
            Bulk Reward
            <GiftOutlined/>
          </DialogBulkReward>
          <Button onClick={onClickExport}>
            <DownloadOutlined/> Export
          </Button>
        </div>
      </div>

      <Table
        bordered
        rowKey="id"
        className="fx-extend"
        loading={loading}
        dataSource={(kolStatistics?.data || [])}
        columns={renderColumns()}
        tableLayout="fixed"
        pagination={{
          defaultPageSize: 10,
          total: kolStatistics.total,
          onChange: (page, pageSize) => fetch({ pageNumber: page, pageSize })
        }}
        scroll={{ x: true }}
      />
    </section>
  )
}
