import { graphql } from 'relay-runtime'

import { UpdateAdminPortalUserMutation } from './__generated__/UpdateAdminPortalUserMutation.graphql'

export type { UpdateAdminPortalUserMutation }

export const updateAdminPortalUserMutation = graphql`
  mutation UpdateAdminPortalUserMutation($input: UpdateAdminPortalUserByAnotherAdminPortalUserInput!) {
    updateAdminPortalUserByAdminPortalUser(input: $input) {
      code
      message
    }
  }
`
