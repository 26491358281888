import { AccountDocumentsQuery, AccountIdentityQuery, TAccountIdentity, UpdateAccountMutation, UpdateAccountStateMutation, accountDocumentsQuery, accountIdentityQuery, updateAccountMutation, updateAccountStateMutation } from 'src/graphql'
import { EdgeGraphqlService } from 'src/services'

export class AccountApi {
  /**
   * SECTION: Queries
   */

  static accountIdentity(variables: AccountIdentityQuery['variables']) {
    return EdgeGraphqlService.queryAsPromise<AccountIdentityQuery>(
      accountIdentityQuery,
      variables
    ).then((response) => response.accountIdentity.accountIdentity as TAccountIdentity)
  }

  static accountDocuments(variables: AccountDocumentsQuery['variables']) {
    return EdgeGraphqlService.queryAsPromise<AccountDocumentsQuery>(
      accountDocumentsQuery,
      variables
    ).then((response) => response.accountDocuments.documents)
  }

  /**
   * SECTION: Mutations
   */

  static update(variables: UpdateAccountMutation['variables']) {
    return EdgeGraphqlService.mutationAsPromise<UpdateAccountMutation>(updateAccountMutation, variables).then(
      (response) => response.updateAccountByAdminPortal
    )
  }

  static updateAccountState(variables: UpdateAccountStateMutation['variables']) {
    return EdgeGraphqlService.mutationAsPromise<UpdateAccountStateMutation>(updateAccountStateMutation, variables).then(
      (response) => response.updateAccountState
    )
  }
}
