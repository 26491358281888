/**
 * @generated SignedSource<<11922c181665bbf02d5dfbd6af45155e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AccountStateUpdateReasons = "BLACKLIST" | "FRAUD" | "MONEY_LAUNDERING" | "OTHER" | "%future added value";
export type AccountStates = "ACTIVE" | "CLOSED" | "DEACTIVATED" | "ON_HOLD" | "%future added value";
export type StatusCode = "ABORTED" | "ALREADY_EXISTS" | "CANCELLED" | "DATA_LOSS" | "DEADLINE_EXCEEDED" | "FAILED_PRECONDITION" | "INTERNAL" | "INVALID_ARGUMENT" | "NOT_FOUND" | "OK" | "OUT_OF_RANGE" | "PERMISSION_DENIED" | "RESOURCE_EXHAUSTED" | "UNAUTHENTICATED" | "UNAVAILABLE" | "UNIMPLEMENTED" | "UNKNOWN" | "%future added value";
export type UpdateAccountStateInput = {
  accountID: string;
  comment?: string | null;
  reason?: AccountStateUpdateReasons | null;
  state: AccountStates;
};
export type UpdateAccountStateMutation$variables = {
  input: UpdateAccountStateInput;
};
export type UpdateAccountStateMutation$data = {
  readonly updateAccountState: {
    readonly code: StatusCode;
    readonly message: string;
  };
};
export type UpdateAccountStateMutation = {
  response: UpdateAccountStateMutation$data;
  variables: UpdateAccountStateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AccountStatus",
    "kind": "LinkedField",
    "name": "updateAccountState",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "code",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateAccountStateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateAccountStateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8be755ade08d25ea8bc40f9ebb4689b1",
    "id": null,
    "metadata": {},
    "name": "UpdateAccountStateMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateAccountStateMutation(\n  $input: UpdateAccountStateInput!\n) {\n  updateAccountState(input: $input) {\n    code\n    message\n  }\n}\n"
  }
};
})();

(node as any).hash = "af843dd6df4b3c499a80a2fe66c24b33";

export default node;
