/**
 * @generated SignedSource<<6c5c16ae3c9c7c823dc43f4f64c08f04>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RecalculateRiskScoreMutation$variables = {
  attemptID: string;
};
export type RecalculateRiskScoreMutation$data = {
  readonly recalculateRiskScore: {
    readonly data: {
      readonly createTime: any | null;
      readonly id: string;
      readonly updateTime: any | null;
    };
  };
};
export type RecalculateRiskScoreMutation = {
  response: RecalculateRiskScoreMutation$data;
  variables: RecalculateRiskScoreMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "attemptID"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "attemptID",
        "variableName": "attemptID"
      }
    ],
    "concreteType": "KYCAttemptStatus",
    "kind": "LinkedField",
    "name": "recalculateRiskScore",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "KYCAttempt",
        "kind": "LinkedField",
        "name": "data",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updateTime",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RecalculateRiskScoreMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RecalculateRiskScoreMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d89883e49d1a6757f4f2ffdb177e2326",
    "id": null,
    "metadata": {},
    "name": "RecalculateRiskScoreMutation",
    "operationKind": "mutation",
    "text": "mutation RecalculateRiskScoreMutation(\n  $attemptID: String!\n) {\n  recalculateRiskScore(attemptID: $attemptID) {\n    data {\n      id\n      createTime\n      updateTime\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b8e4564db2867ce03fa0ce5318a236f9";

export default node;
