import { Table } from 'antd'
import moment from 'moment'
import { FC, useMemo } from 'react'
import { TUser } from 'src/graphql'
import { renderColumns } from './columns'

interface IProps {
  user?: TUser | null
}

export const AccountState: FC<IProps> = ({ user }) => {
  const dataSource = useMemo(
    () => user?.account?.accountStateTransitions?.sort(
      (log1, log2) => moment(log2.updateTime).toDate().getTime() - moment(log1.updateTime).toDate().getTime()
    ) || [],
    [user?.account?.accountStateTransitions]
  )

  return (
    <section className="fx fx-column fx-extend">
      <Table
        bordered
        rowKey="id"
        className="fx-extend"
        dataSource={dataSource}
        columns={renderColumns()}
        scroll={{ x: true }}
      />
    </section>
  )
}
