import { IRouterOptions } from 'src/router'
import { EmailTemplate, EmailTemplates } from './components'
import { EPaths } from './routes.path'

export enum ERoutes {
  EMAIL_TEMPLATES = 'EMAIL_TEMPLATES',
  EMAIL_TEMPLATE = 'EMAIL_TEMPLATE'
}

export const routes: IRouterOptions = [
  {
    path: EPaths.EMAIL_TEMPLATES,
    name: ERoutes.EMAIL_TEMPLATES,
    component: EmailTemplates,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: EPaths.EMAIL_TEMPLATE,
    name: ERoutes.EMAIL_TEMPLATE,
    component: EmailTemplate,
    meta: {
      requiredAuth: true
    }
  }
]

export const EmailTemplateModule = {
  ERoutes,
  routes
}
