/**
 * @generated SignedSource<<240a583feb33b214df99fd50d4e9fa85>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ArticleActiveStatus = "activated" | "deactivated" | "%future added value";
export type ShortLinkActiveStatus = "activated" | "deactivated" | "%future added value";
export type ArticleWhereInput = {
  activeStatus?: ArticleActiveStatus | null;
  activeStatusIn?: ReadonlyArray<ArticleActiveStatus> | null;
  activeStatusNEQ?: ArticleActiveStatus | null;
  activeStatusNotIn?: ReadonlyArray<ArticleActiveStatus> | null;
  and?: ReadonlyArray<ArticleWhereInput> | null;
  createTime?: any | null;
  createTimeGT?: any | null;
  createTimeGTE?: any | null;
  createTimeIn?: ReadonlyArray<any> | null;
  createTimeIsNil?: boolean | null;
  createTimeLT?: any | null;
  createTimeLTE?: any | null;
  createTimeNEQ?: any | null;
  createTimeNotIn?: ReadonlyArray<any> | null;
  createTimeNotNil?: boolean | null;
  deleteTime?: any | null;
  deleteTimeGT?: any | null;
  deleteTimeGTE?: any | null;
  deleteTimeIn?: ReadonlyArray<any> | null;
  deleteTimeIsNil?: boolean | null;
  deleteTimeLT?: any | null;
  deleteTimeLTE?: any | null;
  deleteTimeNEQ?: any | null;
  deleteTimeNotIn?: ReadonlyArray<any> | null;
  deleteTimeNotNil?: boolean | null;
  description?: string | null;
  descriptionContains?: string | null;
  descriptionContainsFold?: string | null;
  descriptionEqualFold?: string | null;
  descriptionGT?: string | null;
  descriptionGTE?: string | null;
  descriptionHasPrefix?: string | null;
  descriptionHasSuffix?: string | null;
  descriptionIn?: ReadonlyArray<string> | null;
  descriptionIsNil?: boolean | null;
  descriptionLT?: string | null;
  descriptionLTE?: string | null;
  descriptionNEQ?: string | null;
  descriptionNotIn?: ReadonlyArray<string> | null;
  descriptionNotNil?: boolean | null;
  id?: string | null;
  idGT?: string | null;
  idGTE?: string | null;
  idIn?: ReadonlyArray<string> | null;
  idLT?: string | null;
  idLTE?: string | null;
  idNEQ?: string | null;
  idNotIn?: ReadonlyArray<string> | null;
  not?: ArticleWhereInput | null;
  or?: ReadonlyArray<ArticleWhereInput> | null;
  title?: string | null;
  titleContains?: string | null;
  titleContainsFold?: string | null;
  titleEqualFold?: string | null;
  titleGT?: string | null;
  titleGTE?: string | null;
  titleHasPrefix?: string | null;
  titleHasSuffix?: string | null;
  titleIn?: ReadonlyArray<string> | null;
  titleIsNil?: boolean | null;
  titleLT?: string | null;
  titleLTE?: string | null;
  titleNEQ?: string | null;
  titleNotIn?: ReadonlyArray<string> | null;
  titleNotNil?: boolean | null;
  updateTime?: any | null;
  updateTimeGT?: any | null;
  updateTimeGTE?: any | null;
  updateTimeIn?: ReadonlyArray<any> | null;
  updateTimeIsNil?: boolean | null;
  updateTimeLT?: any | null;
  updateTimeLTE?: any | null;
  updateTimeNEQ?: any | null;
  updateTimeNotIn?: ReadonlyArray<any> | null;
  updateTimeNotNil?: boolean | null;
  url?: string | null;
  urlContains?: string | null;
  urlContainsFold?: string | null;
  urlEqualFold?: string | null;
  urlGT?: string | null;
  urlGTE?: string | null;
  urlHasPrefix?: string | null;
  urlHasSuffix?: string | null;
  urlIn?: ReadonlyArray<string> | null;
  urlLT?: string | null;
  urlLTE?: string | null;
  urlNEQ?: string | null;
  urlNotIn?: ReadonlyArray<string> | null;
};
export type ArticlesQuery$variables = {
  after?: any | null;
  before?: any | null;
  first?: number | null;
  last?: number | null;
  where?: ArticleWhereInput | null;
};
export type ArticlesQuery$data = {
  readonly articles: {
    readonly edges: ReadonlyArray<{
      readonly cursor: any;
      readonly node: {
        readonly activeStatus: ArticleActiveStatus;
        readonly createTime: any | null;
        readonly description: string | null;
        readonly id: string;
        readonly shortLink: ReadonlyArray<{
          readonly activeStatus: ShortLinkActiveStatus;
          readonly articleID: string;
          readonly createTime: any | null;
          readonly id: string;
          readonly updateTime: any | null;
          readonly url: string;
        }> | null;
        readonly title: string | null;
        readonly updateTime: any | null;
        readonly url: string;
      } | null;
    } | null> | null;
    readonly pageInfo: {
      readonly endCursor: any | null;
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
      readonly startCursor: any | null;
    };
    readonly totalCount: number;
  };
};
export type ArticlesQuery = {
  response: ArticlesQuery$data;
  variables: ArticlesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "before"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "where"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createTime",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updateTime",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "activeStatus",
  "storageKey": null
},
v10 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "after",
        "variableName": "after"
      },
      {
        "kind": "Variable",
        "name": "before",
        "variableName": "before"
      },
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "last",
        "variableName": "last"
      },
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where"
      }
    ],
    "concreteType": "ArticleConnection",
    "kind": "LinkedField",
    "name": "articles",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "PageInfo",
        "kind": "LinkedField",
        "name": "pageInfo",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasNextPage",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasPreviousPage",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startCursor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endCursor",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ArticleEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cursor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Article",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ShortLink",
                "kind": "LinkedField",
                "name": "shortLink",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "articleID",
                    "storageKey": null
                  },
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ArticlesQuery",
    "selections": (v10/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "ArticlesQuery",
    "selections": (v10/*: any*/)
  },
  "params": {
    "cacheID": "4290110628d7f955b65a746aa3e2db81",
    "id": null,
    "metadata": {},
    "name": "ArticlesQuery",
    "operationKind": "query",
    "text": "query ArticlesQuery(\n  $after: Cursor\n  $first: Int\n  $before: Cursor\n  $last: Int\n  $where: ArticleWhereInput\n) {\n  articles(after: $after, first: $first, before: $before, last: $last, where: $where) {\n    totalCount\n    pageInfo {\n      hasNextPage\n      hasPreviousPage\n      startCursor\n      endCursor\n    }\n    edges {\n      cursor\n      node {\n        id\n        createTime\n        updateTime\n        url\n        title\n        description\n        activeStatus\n        shortLink {\n          id\n          createTime\n          updateTime\n          articleID\n          url\n          activeStatus\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d4b66a80a5940613d9700a071d094b13";

export default node;
