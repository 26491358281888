/**
 * @generated SignedSource<<ac377b25317ba72b8219b32a22b811c2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type KYBDocumentType = "BENEFICIAL_OWNER_DECLARATION" | "BOARD_RESOLUTION" | "BUSINESS_DETAILS" | "CERT_INCORPORATION" | "CERT_INCUMBENCY" | "CORPORATE_STRUCTURE_OWNERSHIP" | "MEMORANDUM_ARTICLES" | "OTHER" | "PROOF_SOURCE_FUNDS" | "REGISTER_OF_DIRECTORS" | "REGISTER_OF_SHAREHOLDERS" | "STAKEHOLDER_DETAILS" | "%future added value";
export type GenerateKYBDocumentsSignedURLInput = {
  documentType: KYBDocumentType;
  fileName: string;
  kybAttemptID: string;
};
export type GenerateKYBDocumentsSignedURLMutation$variables = {
  input: GenerateKYBDocumentsSignedURLInput;
};
export type GenerateKYBDocumentsSignedURLMutation$data = {
  readonly generateKYBDocumentsSignedURL: {
    readonly key: string;
    readonly signedURL: string;
  };
};
export type GenerateKYBDocumentsSignedURLMutation = {
  response: GenerateKYBDocumentsSignedURLMutation$data;
  variables: GenerateKYBDocumentsSignedURLMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "GenerateKYBDocumentsSignedURLOutput",
    "kind": "LinkedField",
    "name": "generateKYBDocumentsSignedURL",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "signedURL",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "key",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GenerateKYBDocumentsSignedURLMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GenerateKYBDocumentsSignedURLMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f5a80ff99e64192d2702faf249e22057",
    "id": null,
    "metadata": {},
    "name": "GenerateKYBDocumentsSignedURLMutation",
    "operationKind": "mutation",
    "text": "mutation GenerateKYBDocumentsSignedURLMutation(\n  $input: GenerateKYBDocumentsSignedURLInput!\n) {\n  generateKYBDocumentsSignedURL(input: $input) {\n    signedURL\n    key\n  }\n}\n"
  }
};
})();

(node as any).hash = "088b2e7b9c04f4533366362b885a7512";

export default node;
