import { graphql } from 'relay-runtime'

import { UpdateAccountStateMutation } from './__generated__/UpdateAccountStateMutation.graphql'

export type { UpdateAccountStateMutation }

export const updateAccountStateMutation = graphql`
  mutation UpdateAccountStateMutation($input: UpdateAccountStateInput!) {
    updateAccountState(input: $input) {
      code
      message
    }
  }
`
