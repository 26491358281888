import { TBusinessUserPii } from 'src/graphql'

export class KybUtils {
  static canStartReview(user?: TBusinessUserPii | null) {
    return ['REVIEW_IN_PROGRESS'].includes(user?.currentKyb?.state as string)
  }

  static canOnHold(user?: TBusinessUserPii | null) {
    return true
  }

  static canApprove(user?: TBusinessUserPii | null) {
    return true
  }

  static canDeny(user?: TBusinessUserPii | null) {
    return true
  }
}
