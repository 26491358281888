export const renderColumns = () => [
  {
    title: 'Document type',
    dataIndex: 'document-type',
    key: 'document-type'
  },
  {
    title: 'Uploaded by',
    dataIndex: 'uploaded-by',
    key: 'uploaded-by'
  },
  {
    title: 'Uploaded time',
    dataIndex: 'uploaded-time',
    key: 'uploaded-time'
  },
  {
    title: 'Comment',
    dataIndex: 'comment',
    key: 'comment'
  },
  {
    title: 'Link',
    dataIndex: 'link',
    key: 'link',
    render: (link: string) => {
      if (link) {
        return <a href={link} target="_blank" rel="noreferrer">Download</a>
      }
    }
  }
]
