/**
 * @generated SignedSource<<7d93f2fd3e6af5a84750fb26e5573298>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type StatusCode = "ABORTED" | "ALREADY_EXISTS" | "CANCELLED" | "DATA_LOSS" | "DEADLINE_EXCEEDED" | "FAILED_PRECONDITION" | "INTERNAL" | "INVALID_ARGUMENT" | "NOT_FOUND" | "OK" | "OUT_OF_RANGE" | "PERMISSION_DENIED" | "RESOURCE_EXHAUSTED" | "UNAUTHENTICATED" | "UNAVAILABLE" | "UNIMPLEMENTED" | "UNKNOWN" | "%future added value";
export type ReferralStatisticsQuery$variables = {
  accountID: string;
};
export type ReferralStatisticsQuery$data = {
  readonly referralStatistics: {
    readonly code: StatusCode;
    readonly data: {
      readonly availableQuota: number;
      readonly directReferralPhaseOne: number;
      readonly directReferralPhaseZero: number;
      readonly indirectReferralPhaseOne: number;
      readonly indirectReferralPhaseZero: number;
      readonly lastUpdatedTime: string;
      readonly leaderboardTotal: number;
      readonly pointsPhaseOne: number;
      readonly pointsPhaseZero: number;
      readonly quotaLastRefreshedTime: string;
      readonly rank: number;
      readonly totalQuota: number;
    } | null;
    readonly directReferralTotal: number;
    readonly indirectReferralTotal: number;
    readonly lastUpdatedTime: string;
    readonly message: string;
    readonly rank: number;
    readonly waitlistTotal: number;
  };
};
export type ReferralStatisticsQuery = {
  response: ReferralStatisticsQuery$data;
  variables: ReferralStatisticsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "accountID"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rank",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastUpdatedTime",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "accountID",
        "variableName": "accountID"
      }
    ],
    "concreteType": "ReferralStatisticsStatus",
    "kind": "LinkedField",
    "name": "referralStatistics",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "code",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      },
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "directReferralTotal",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "indirectReferralTotal",
        "storageKey": null
      },
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "waitlistTotal",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ReferralStatistics",
        "kind": "LinkedField",
        "name": "data",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "leaderboardTotal",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "directReferralPhaseZero",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "indirectReferralPhaseZero",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pointsPhaseZero",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "directReferralPhaseOne",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "indirectReferralPhaseOne",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pointsPhaseOne",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "availableQuota",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalQuota",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "quotaLastRefreshedTime",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ReferralStatisticsQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ReferralStatisticsQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "19c5e08935e3e85d23b4bfda979ac0bc",
    "id": null,
    "metadata": {},
    "name": "ReferralStatisticsQuery",
    "operationKind": "query",
    "text": "query ReferralStatisticsQuery(\n  $accountID: ID!\n) {\n  referralStatistics(accountID: $accountID) {\n    code\n    message\n    rank\n    directReferralTotal\n    indirectReferralTotal\n    lastUpdatedTime\n    waitlistTotal\n    data {\n      rank\n      leaderboardTotal\n      lastUpdatedTime\n      directReferralPhaseZero\n      indirectReferralPhaseZero\n      pointsPhaseZero\n      directReferralPhaseOne\n      indirectReferralPhaseOne\n      pointsPhaseOne\n      availableQuota\n      totalQuota\n      quotaLastRefreshedTime\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "dbd930cc14c450d0ee3650853591483a";

export default node;
