import { Space } from 'antd'
import { EDocumentKey, EDocumentTxt, TAccountDocuments } from 'src/graphql'
import { DialogDocuments, DialogUploadDocuments } from 'src/partials'

interface Actions {
  onUploadDocuments?: (files: File[], documentKey: EDocumentKey) => void
}

export const renderColumns = ({ onUploadDocuments }: Actions) => [
  {
    title: 'File name',
    dataIndex: 'key',
    key: 'fileName',
    width: '50%',
    ellipsis: true,
    render: (val: EDocumentKey) => EDocumentTxt[val]
  },
  {
    title: 'Link',
    dataIndex: 'docs',
    key: 'link',
    render: (docs: TAccountDocuments[]) => docs.length
      ? (
        <DialogDocuments dataSource={docs}>
          {docs.length}
        </DialogDocuments>
      )
      : '--'
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    width: 160,
    render: (_: any, record: { key: EDocumentKey; docs: TAccountDocuments[]}) => (
      <Space>
        {
          !!record.docs.length && (
            <DialogDocuments dataSource={record.docs}>
              Detail
            </DialogDocuments>
          )
        }

        <DialogUploadDocuments
          btnProps={{ size: 'small', type: 'link' }}
          onOk={(files) => {
            onUploadDocuments?.(files, record.key)
          }}
        >
          Upload
        </DialogUploadDocuments>
      </Space>
    )
  }
]
