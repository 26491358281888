import { graphql } from 'relay-runtime'

import { RecalculateRiskScoreMutation } from './__generated__/RecalculateRiskScoreMutation.graphql'

export type { RecalculateRiskScoreMutation }

export const recalculateRiskScoreMutation = graphql`
  mutation RecalculateRiskScoreMutation($attemptID: String!) {
    recalculateRiskScore(attemptID: $attemptID) {
      data {
        ... on KYCAttempt {
          id
          createTime
          updateTime
        }
      }
    }
  }
`
