import { DownOutlined, ExclamationCircleOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import { Dropdown, Menu, Modal, Switch } from 'antd'
import { ComponentProps, memo, useCallback, useMemo } from 'react'
import { useBehaviorMapper } from 'src/hooks'
import { AuthService, LayoutService, TzService } from 'src/services'
import { UserUtils } from 'src/utils'
import { LayoutBreadcrumb } from '../breadcrumb'
import Style from './style.module.scss'

export const LayoutHeader = memo(
  () => {
    const user = useBehaviorMapper(AuthService.user$)
    const { loading, sidebar } = useBehaviorMapper(LayoutService.layoutConfig$)

    const logout = useCallback(() => {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined/>,
        content: 'Are you sure you want to sign out?',
        okText: 'Logout',
        cancelText: 'Cancel',
        closable: false,
        okButtonProps: { danger: true },
        onOk() {
          LayoutService.setLoading(true)
          AuthService.logout().finally(
            () => LayoutService.setLoading(false)
          )
        }
      })
    }, [])

    const ToggleIconComp = sidebar.collapsed
      ? MenuUnfoldOutlined
      : MenuFoldOutlined

    const dropdownItems = useMemo<ComponentProps<typeof Menu>['items']>(
      () => [
        {
          key: 'sign-out',
          danger: true,
          disabled: loading,
          label: 'Logout',
          onClick: logout
        }
      ], [loading, logout]
    )

    const useUTC = useBehaviorMapper(TzService.useUTC$)
    const timezoneOffset = useBehaviorMapper(TzService.timezoneOffset$)
    const tzTxt = useMemo(
      () => timezoneOffset > 0 ? `+${timezoneOffset / 60}` : `-${timezoneOffset / 60}`,
      [timezoneOffset]
    )

    return (
      <section className={Style.header}>
        <div className="fx fx-ai-center gap-4">
          <div className={Style.toggleIcon}>
            <ToggleIconComp onClick={() => LayoutService.setSidebarCollapsed(!sidebar.collapsed)}/>
          </div>

          <LayoutBreadcrumb/>
        </div>

        <div className="fx fx-ai-center gap-4">
          <Switch
            checkedChildren="UTC"
            unCheckedChildren={tzTxt}
            defaultChecked={useUTC}
            onChange={(checked) => (TzService.useUTC = checked)}
          />

          <Dropdown
            className="pointer"
            trigger={['click']}
            menu={{ items: dropdownItems }}
          >
            <div className={Style.user}>
              {/* <img className={Style.userAvt} src="/images/avatar.jpg" alt="avatar"/> */}
              <div className={Style.userName}>
                {user && (UserUtils.getFullName(user) || user.email)}
              </div>

              <DownOutlined/>
            </div>
          </Dropdown>
        </div>
      </section>
    )
  }
)
