/**
 * @generated SignedSource<<7926b9cf7bebe2dc1c2b45ea3703075a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type DocumentKey = "ACC_FILE_BENEFICIAL_OWNER_DECLARATION_FORM" | "ACC_FILE_BOARD_RESOLUTION" | "ACC_FILE_CERTIFICATE_OF_INCORPORATION" | "ACC_FILE_CERTIFICATE_OF_INCUMBENCY" | "ACC_FILE_COMPANY_DETAILS" | "ACC_FILE_CORPORATE_STRUCTURE_OWNERSHIP_CHART" | "ACC_FILE_MEMORANDUM_AND_ARTICLES_OF_ASSOCIATION" | "ACC_FILE_REGISTERS_OF_DIRECTORS" | "ACC_FILE_REGISTER_OF_SHAREHOLDERS" | "STAKEHOLDER_FILE_STAKEHOLDER_DETAILS" | "STAKEHOLDER_FILE_STAKEHOLDER_IDS" | "USER_FILE_PROOF_OF_ADDRESS" | "USER_FILE_USER_DETAILS" | "%future added value";
export type DocumentVisibility = "account" | "stakeholder" | "user" | "%future added value";
export type StatusCode = "ABORTED" | "ALREADY_EXISTS" | "CANCELLED" | "DATA_LOSS" | "DEADLINE_EXCEEDED" | "FAILED_PRECONDITION" | "INTERNAL" | "INVALID_ARGUMENT" | "NOT_FOUND" | "OK" | "OUT_OF_RANGE" | "PERMISSION_DENIED" | "RESOURCE_EXHAUSTED" | "UNAUTHENTICATED" | "UNAVAILABLE" | "UNIMPLEMENTED" | "UNKNOWN" | "%future added value";
export type GenerateDocumentPreSignedURLInput = {
  accountID?: string | null;
  documentKey: DocumentKey;
  fileName: string;
  stakeholderID?: string | null;
  visibility: DocumentVisibility;
};
export type GenerateDocumentPreSignedURLQuery$variables = {
  input: GenerateDocumentPreSignedURLInput;
};
export type GenerateDocumentPreSignedURLQuery$data = {
  readonly generateDocumentPreSignedURL: {
    readonly code: StatusCode;
    readonly message: string;
    readonly url: string | null;
  };
};
export type GenerateDocumentPreSignedURLQuery = {
  response: GenerateDocumentPreSignedURLQuery$data;
  variables: GenerateDocumentPreSignedURLQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "GenerateDocumentPreSignedUrlStatus",
    "kind": "LinkedField",
    "name": "generateDocumentPreSignedURL",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "code",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GenerateDocumentPreSignedURLQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GenerateDocumentPreSignedURLQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c6f1e30bb6f70aed99de4bbe5a658b5c",
    "id": null,
    "metadata": {},
    "name": "GenerateDocumentPreSignedURLQuery",
    "operationKind": "query",
    "text": "query GenerateDocumentPreSignedURLQuery(\n  $input: GenerateDocumentPreSignedURLInput!\n) {\n  generateDocumentPreSignedURL(input: $input) {\n    code\n    message\n    url\n  }\n}\n"
  }
};
})();

(node as any).hash = "b03755e35c02859da4fa51cdd271c96c";

export default node;
