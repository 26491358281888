import { graphql } from 'relay-runtime'

import { UpdateShortLinkMutation } from './__generated__/UpdateShortLinkMutation.graphql'

export type { UpdateShortLinkMutation }

export const updateShortLinkMutation = graphql`
  mutation UpdateShortLinkMutation($id: ID!, $input: UpdateShortLinkInput!) {
    updateShortLink(id: $id, input: $input) {
      code
      message
    }
  }
`
