import { ClipboardUtils } from 'src/utils'
import { MessageService } from './message.service'

export class ClipboardService {
  static copy(content: any) {
    const copyContent = typeof content === 'string'
      ? content
      : JSON.stringify(content)
    return ClipboardUtils.copy(copyContent).then(
      () => MessageService.success('Copied to clipboard')
    )
  }

  static paste() {
    return ClipboardUtils.paste()
  }
}
