import { graphql } from 'relay-runtime'

import { AccountIdentityQuery } from './__generated__/AccountIdentityQuery.graphql'

export type { AccountIdentityQuery }

export type TAccountIdentity = Partial<DeepExclude<DeepWriteable<AccountIdentityQuery>, null>['response']['accountIdentity']['accountIdentity']>

export const accountIdentityQuery = graphql`
  query AccountIdentityQuery($id: ID!) {
    accountIdentity(id: $id) {
      code
      message
      accountIdentity {
        companyName
        countryOfIncorporation
        companyRegistrationNumber
        dateOfIncorporation
        registeredOfficeAddress
        entityType
        sourceOfFunds
      }
    }
  }
`
