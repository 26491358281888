/**
 * @generated SignedSource<<752f8512102adaad7f0643bfded8ebed>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ShortLinkActiveStatus = "activated" | "deactivated" | "%future added value";
export type StatusCode = "ABORTED" | "ALREADY_EXISTS" | "CANCELLED" | "DATA_LOSS" | "DEADLINE_EXCEEDED" | "FAILED_PRECONDITION" | "INTERNAL" | "INVALID_ARGUMENT" | "NOT_FOUND" | "OK" | "OUT_OF_RANGE" | "PERMISSION_DENIED" | "RESOURCE_EXHAUSTED" | "UNAUTHENTICATED" | "UNAVAILABLE" | "UNIMPLEMENTED" | "UNKNOWN" | "%future added value";
export type CreateShortLinkInput = {
  activeStatus?: ShortLinkActiveStatus | null;
  articleID: string;
  deleteTime?: any | null;
  url: string;
};
export type CreateShortLinkMutation$variables = {
  input: CreateShortLinkInput;
};
export type CreateShortLinkMutation$data = {
  readonly createShortLink: {
    readonly code: StatusCode;
    readonly message: string;
    readonly shortLink: {
      readonly activeStatus: ShortLinkActiveStatus;
      readonly articleID: string;
      readonly createTime: any | null;
      readonly id: string;
      readonly updateTime: any | null;
      readonly url: string;
    } | null;
  };
};
export type CreateShortLinkMutation = {
  response: CreateShortLinkMutation$data;
  variables: CreateShortLinkMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ShortLinkStatus",
    "kind": "LinkedField",
    "name": "createShortLink",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "code",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ShortLink",
        "kind": "LinkedField",
        "name": "shortLink",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updateTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "articleID",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "activeStatus",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateShortLinkMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateShortLinkMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f4e1c96a90fdce16fe9dd3ccb0d6739e",
    "id": null,
    "metadata": {},
    "name": "CreateShortLinkMutation",
    "operationKind": "mutation",
    "text": "mutation CreateShortLinkMutation(\n  $input: CreateShortLinkInput!\n) {\n  createShortLink(input: $input) {\n    code\n    message\n    shortLink {\n      id\n      createTime\n      updateTime\n      articleID\n      url\n      activeStatus\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4e7e80c504a09a55f308d2c66cec2ddb";

export default node;
