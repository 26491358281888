import { Badge } from 'antd'

export enum EKycStatus {
  NOT_STARTED = 'not_started',
  REVIEW = 'review',
  FAILED = 'failed',
  PASSED = 'passed'
}

export const EKycStatusTxt = Object.freeze({
  [EKycStatus.NOT_STARTED]: 'Not started',
  [EKycStatus.REVIEW]: 'Pending',
  [EKycStatus.FAILED]: 'Failed',
  [EKycStatus.PASSED]: 'Success'
})

export const getKycStatusTxt = (status?: EKycStatus | string) => {
  return typeof status === 'string' ? EKycStatusTxt[status as EKycStatus] || status : ''
}

export const EKycStatusBadge = Object.freeze({
  [EKycStatus.NOT_STARTED]: 'processing',
  [EKycStatus.REVIEW]: 'warning',
  [EKycStatus.FAILED]: 'error',
  [EKycStatus.PASSED]: 'success'
})

export const getKycStatusBadge = (status?: EKycStatus | string) => {
  const badge = EKycStatusBadge[status as EKycStatus] ||
  // kyc status for individual kyc
  {
    APPLICATION_NOT_STARTED: 'default',
    APPLICATION_ONGOING: 'default',
    ESCALATED: 'default',
    INFO_REQUESTED: 'default',
    ON_HOLD: 'processing',
    IN_PROGRESS: 'processing',
    PENDING: 'processing',
    RESTART_CLOSED: 'warning',
    RESTART_IDENTITY_CHECK_FAILED: 'warning',
    RESTART_REJECTED: 'warning',
    TERMINAL_BLACKLISTED: 'error',
    TERMINAL_IDENTITY_BLACKLISTED: 'error',
    SUCCESS_AUTO: 'success',
    SUCCESS_MANUAL: 'success'
  }[String(status)] || 'default'
  return <Badge status={badge} className="wp-no-wrap" text={getKycStatusTxt(status)}/>
}

export enum EAccountPermission {
  ACC_OFF_CHAIN_OPS = 'ACC_OFF_CHAIN_OPS',
  ACC_OFF_CHAIN_USER_MANAGEMENT = 'ACC_OFF_CHAIN_USER_MANAGEMENT',
  ACC_OFF_CHAIN_ADMIN = 'ACC_OFF_CHAIN_ADMIN',
  ACC_OFF_CHAIN_USER = 'ACC_OFF_CHAIN_USER'
}

export const EAccountPermissionTxt = Object.freeze({
  [EAccountPermission.ACC_OFF_CHAIN_OPS]: 'OPS',
  [EAccountPermission.ACC_OFF_CHAIN_USER_MANAGEMENT]: 'User management',
  [EAccountPermission.ACC_OFF_CHAIN_ADMIN]: 'Admin',
  [EAccountPermission.ACC_OFF_CHAIN_USER]: 'User'
})

export const getAccountPermissionTxt = (status?: EAccountPermission | string) => {
  return typeof status === 'string' ? EAccountPermissionTxt[status as EAccountPermission] || status : ''
}

export enum EKybStatus {
  NOT_STARTED = 'NOT_STARTED',
  ONGOING = 'ONGOING',
  REVIEW_IN_PROGRESS = 'REVIEW_IN_PROGRESS',
  DONE = 'DONE',
  REJECTED = 'REJECTED',
  ON_HOLD = 'ON_HOLD'
}

export const EKybStatusBadge = Object.freeze({
  [EKybStatus.NOT_STARTED]: 'default',
  [EKybStatus.ONGOING]: 'processing',
  [EKybStatus.REVIEW_IN_PROGRESS]: 'processing',
  [EKybStatus.DONE]: 'success',
  [EKybStatus.REJECTED]: 'error',
  [EKybStatus.ON_HOLD]: 'warning'
})

export const getKybStatusBadge = (status?: string) => {
  const badge = EKybStatusBadge[status as EKybStatus] || 'default'
  return <Badge status={badge} text={getKycStatusTxt(status)}/>
}
