import { Button, Input, Modal } from 'antd'
import { ComponentProps, FC, SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { EcoRewardApi } from 'src/api'
import { ErrorMessage } from 'src/atoms'
import { TAdminInvitee } from 'src/graphql'
import { useAsRef, useBehaviorMapper, useUnsubscribe, useValidation } from 'src/hooks'
import { AuthService, MessageService } from 'src/services'
import { EventUtils } from 'src/utils'
import { object, string } from 'yup'

const schema = object().shape({
  points: string().typeError('Points invalid').required('Points is required')
})

export const DialogAddEcosystemPoints: FC<Omit<
  ComponentProps<typeof Modal>,
  'onOk' | 'afterClose'
> & {
  btnProps?: ComponentProps<typeof Button>
  afterClose?: (saved?: boolean) => any
  accountID?: string
}> = ({
  btnProps,
  accountID,
  ...props
}) => {
  const unsubscribe$ = useUnsubscribe()
  const user = useBehaviorMapper(AuthService.user$)
  const [loading, setLoading] = useState(false)
  const [invisible, setInvisible] = useState<boolean | TAdminInvitee>(true)
  const [formData, setFormData] = useState({
    points: ''
  })

  const { validate, errors, reset } = useValidation({
    data: formData,
    schema
  })

  useEffect(() => {
    reset()

    setFormData({
      points: ''
    })
  }, [invisible, user, reset])

  useEffect(() => {
    setInvisible(!props.open)
  }, [props.open])

  const afterCloseRef = useAsRef(props.afterClose)

  const submit = useCallback(async (e: SyntheticEvent) => {
    EventUtils.preventDefault(e)

    const { isValid, value } = await validate()

    if (!isValid || !accountID || !value?.points) {
      return MessageService.error('Something went wrong!')
    }

    setLoading(true)
    from(EcoRewardApi.addEcosystemPoints({
      point_distributions: [
        {
          account_id: accountID,
          giving_point: Number(value.points)
        }
      ]
    }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          MessageService.error(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(() => {
        MessageService.info('Add ecosystem points successfully')
        setInvisible(true)
        afterCloseRef.current?.(true)
      })
  }, [accountID, afterCloseRef, unsubscribe$, validate])

  return (
    <>
      <Button
        type="primary"
        {...btnProps}
        onClick={() => setInvisible(false)}
      >
        {props.children || 'Add Ecosystem Points'}
      </Button>

      <Modal
        title={props.title || 'Add Ecosystem Points'}
        open={!invisible}
        centered
        closable={!loading} // display X icon
        keyboard={false} // disable close on press ESC
        maskClosable={false} // disable close on click outside
        okButtonProps={{ disabled: loading }}
        cancelButtonProps={{ disabled: loading }}
        okText="Ok"
        cancelText="Cancel"
        onOk={submit}
        onCancel={() => setInvisible(true)}
      >
        {!invisible && (
          <form onSubmit={submit} className="fx fx-column fx-extend gap-2">
            <input className="d-none" type="submit"/>

            <Input
              required
              type="string"
              name="points"
              placeholder="Amount"
              readOnly={loading}
              value={formData.points}
              status={errors.hasError('points') ? 'error' : undefined}
              onChange={(e) => {
                const value = e.target.value

                if (value && !/^[1-9]\d*$/.test(value)) {
                  return
                }

                setFormData((prev) => ({
                  ...prev,
                  points: value
                }))
              }}
              disabled={loading}
            />
            <ErrorMessage>{errors.getError('points')}</ErrorMessage>
          </form>
        )}
      </Modal>
    </>
  )
}
