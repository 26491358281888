import { graphql } from 'relay-runtime'

import { KycReviewDocumentsSignedURLQuery } from './__generated__/KycReviewDocumentsSignedURLQuery.graphql'

export type { KycReviewDocumentsSignedURLQuery }

export const kycReviewDocumentsSignedURLQuery = graphql`
  query KycReviewDocumentsSignedURLQuery($input: GenerateKYCReviewDocumentSignedURLInput!) {
    kycReviewDocumentsSignedURL(input: $input) {
      signedURL
      key
    }
  }
`
