export enum EAccountState {
  ACTIVE = 'ACTIVE',
  CLOSED = 'CLOSED',
  DEACTIVATED = 'DEACTIVATED',
  ON_HOLD = 'ON_HOLD'
}

export enum EAccountStateUpdateReason {
  FRAUD = 'FRAUD',
  BLACKLIST = 'BLACKLIST',
  MONEY_LAUNDERING = 'MONEY_LAUNDERING',
  OTHER = 'OTHER'
}
