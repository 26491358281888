import { IRouterOptions } from 'src/router'
import { MarketingArticles, MarketingKolTracker, MarketingShortLinks } from './components'
import { KOLPointDistribution } from './components/kol-tracker/point-distribution'
import { KOLRewardDetails } from './components/kol-tracker/reward-details'
import { EPaths } from './routes.path'

export enum ERoutes {
  MARKETING_ARTICLE = 'MARKETING_ARTICLE',
  MARKETING_SHORT_LINK = 'MARKETING_SHORT_LINK',
  KOL_TRACKER = 'KOL_TRACKER',
  KOL_REWARD_DETAILS = 'KOL_REWARD_DETAILS',
  KOL_POINT_DISTRIBUTION = 'KOL_POINT_DISTRIBUTION'
}

export const routes: IRouterOptions = [
  {
    path: EPaths.MARKETING_ARTICLE,
    name: ERoutes.MARKETING_ARTICLE,
    component: MarketingArticles,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: EPaths.MARKETING_SHORT_LINK,
    name: ERoutes.MARKETING_SHORT_LINK,
    component: MarketingShortLinks,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: EPaths.KOL_TRACKER,
    name: ERoutes.KOL_TRACKER,
    component: MarketingKolTracker,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: EPaths.KOL_REWARD_DETAILS,
    name: ERoutes.KOL_REWARD_DETAILS,
    component: KOLRewardDetails,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: EPaths.KOL_POINT_DISTRIBUTION,
    name: ERoutes.KOL_POINT_DISTRIBUTION,
    component: KOLPointDistribution,
    meta: {
      requiredAuth: true
    }
  }
]

export const MarketingModule = {
  ERoutes,
  routes
}
