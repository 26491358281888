import { Button, Col, Form, Input, message } from 'antd'
import { FC, SyntheticEvent, useCallback, useState } from 'react'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { AuthApi } from 'src/api'
import { useUnsubscribe } from 'src/hooks'
import { AxiosUtils, EventUtils } from 'src/utils'

export const Register: FC = () => {
  const unsubscribe$ = useUnsubscribe()
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [sentMessage, setSentMessage] = useState('')

  const onSubmit = useCallback((values: Parameters<typeof AuthApi.register>[0]) => {
    setLoading(true)
    from(AuthApi.register(values))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          message.error(AxiosUtils.getResponseErrorMessage(error))
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(({ data }) => {
        setEmail(values.email)
        setSentMessage(data.message)
      })
      // .subscribe(() => navigate(generate(ERoutes.LOGIN)))
  }, [unsubscribe$])

  const onResend = useCallback((e: SyntheticEvent) => {
    EventUtils.preventDefault(e)

    setLoading(true)
    from(AuthApi.resendRegisterConfirm({ email }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          message.error(AxiosUtils.getResponseErrorMessage(error))
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(({ data }) => setSentMessage(data.message))
  }, [email, unsubscribe$])

  if (sentMessage) {
    return (
      <section className="fx fx-center">
        <div className="fx fx-column fx-center gap-2">
          {sentMessage}

          <Button
            type="primary"
            htmlType="button"
            block
            disabled={loading}
            onClick={onResend}
          >
            Resend
          </Button>
        </div>
      </section>
    )
  }

  return (
    <section className="fx fx-center">
      <Col
        xs={24}
        sm={{ span: 16 /** , offset: 4 */ }}
        md={{ span: 12 /** , offset: 6 */ }}
        lg={{ span: 10 /** , offset: 7 */ }}
        xl={{ span: 8 /** , offset: 8 */ }}
        xxl={{ span: 6 /** , offset: 9 */ }}
      >
        <Form
          name="register-form"
          autoComplete="off"
          initialValues={{}}
          onFinish={onSubmit}
          onFinishFailed={(errors) => console.log({ errors })}
        >
          <Form.Item noStyle>
            <div className="fx gap-2">
              <Form.Item
                name="first_name"
                rules={[{ required: true, message: 'First Name is required' }]}
                className="fx-1"
              >
                <Input placeholder="First Name" autoComplete="off"/>
              </Form.Item>
              <Form.Item
                name="last_name"
                rules={[{ required: true, message: 'Last Name is required' }]}
                className="fx-1"
              >
                <Input placeholder="Last Name" autoComplete="off"/>
              </Form.Item>
            </div>
          </Form.Item>

          <Form.Item
            name="email"
            rules={[{ required: true, message: 'Please input your email!' }]}
          >
            <Input placeholder="Email" autoComplete="off"/>
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your Password!' }]}
          >
            <Input.Password placeholder="Password" autoComplete="off"/>
          </Form.Item>

          <Form.Item
            name="confirm_password"
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your password!'
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(new Error('The new password that you entered do not match!'))
                }
              })
            ]}
          >
            <Input.Password placeholder="Confirm Password" autoComplete="off"/>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              block
              disabled={loading}
            >
              Register
            </Button>
          </Form.Item>

          {/* // TODO: temporary hide oauth */}
          {/* <Form.Item>
            <GoogleSignInBtn
              block
              disabled={loading}
              params={{
                isSignUp: true
              }}
            >
              Google
            </GoogleSignInBtn>
          </Form.Item>

          <Form.Item>
            <MicrosoftSignInBtn
              block
              disabled={loading}
              params={{
                isSignUp: true
              }}
            >
              Microsoft
            </MicrosoftSignInBtn>
          </Form.Item> */}
        </Form>
      </Col>
    </section>
  )
}
