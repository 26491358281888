import qs, { IStringifyOptions } from 'qs'
import { ValidationError } from 'yup'

/**
 * alphabeticalSort option of qs module
 * @param {String} a
 * @param {String} b
 */
export const alphabeticalSort = (a: string, b: string) => {
  return a.localeCompare(b)
}

/**
 * Compare two object, true if match
 * @param {Object} obj1
 * @param {Object} obj2
 * @return {Boolean}
 */
export const compareObj = <T, K>(obj1: T, obj2: K): boolean => {
  const options: IStringifyOptions = {
    arrayFormat: 'repeat',
    sort: alphabeticalSort
  }
  return (
    qs.stringify(Object.ensure(obj1), options) ===
    qs.stringify(Object.ensure(obj2), options)
  )
}

// ------------------------------------------------------------------------------------

export const convertErrorYup = <T>(error: ValidationError): T => {
  const errors: T = {} as T
  error.inner.forEach((err: ValidationError) => {
    if (err.path && !errors[err.path as keyof T]) {
      (errors as any)[err.path] = err.message
    }
  })
  return errors
}

export const copy = (str: string) => new Promise<void>((resolve, reject) => {
  if (navigator.clipboard) {
    return navigator.clipboard.writeText(str).then(resolve, reject)
  }

  const element = document.createElement('textarea')
  element.value = str
  element.setAttribute('readonly', '')
  element.style.position = 'absolute'
  element.style.left = '-9999px'

  const selection = document.getSelection()
  const selected = (selection?.rangeCount || 0) > 0 // Check if there is any content selected previously
    ? selection?.getRangeAt(0) // Store selection if found
    : false

  document.body.appendChild(element)
  element.select()
  document.execCommand('copy')
  document.body.removeChild(element)

  if (selected) {
    selection?.removeAllRanges() // Unselect everything on the HTML document
    selection?.addRange(selected) // Restore the original selection
  }

  resolve()
})

/**
 * Download text content as a file
 */
export const download = ({
  content,
  href,
  filename = 'text.txt'
}: {
  content?: string
  href?: string
  filename?: string
}) => {
  const element = document.createElement('a')
  element.setAttribute('download', filename)
  element.style.display = 'none'
  if (content) {
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(content))
  }
  if (href) {
    element.setAttribute('href', href)
  }

  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}

export const addProtocolToUrl = (url: string) => {
  if (!url.startsWith('https://') && !url.startsWith('http://')) {
    return 'https://' + url
  }

  return url
}
