import { Button, Input, Modal } from 'antd'
import { ComponentProps, FC, SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { AccountApi } from 'src/api'
import { ErrorMessage } from 'src/atoms'
import { TAdminInvitee } from 'src/graphql'
import { useAsRef, useBehaviorMapper, useUnsubscribe, useValidation } from 'src/hooks'
import { AuthService, MessageService } from 'src/services'
import { EventUtils } from 'src/utils'
import { object, string } from 'yup'

const schema = object().shape({
  referringCode: string().typeError('Referral code invalid').required('Referral code is required')
})

export const DialogAddReferringAccount: FC<Omit<
  ComponentProps<typeof Modal>,
  'onOk' | 'afterClose'
> & {
  btnProps?: ComponentProps<typeof Button>
  afterClose?: (saved?: boolean) => any
  accountID?: string
  referringCode?: string
}> = ({
  btnProps,
  accountID,
  referringCode = '',
  ...props
}) => {
  const unsubscribe$ = useUnsubscribe()
  const user = useBehaviorMapper(AuthService.user$)
  const [loading, setLoading] = useState(false)
  const [invisible, setInvisible] = useState<boolean | TAdminInvitee>(true)
  const [formData, setFormData] = useState({
    referringCode
  })

  const type = referringCode ? 'Edit' : 'Add'

  const { validate, errors, reset } = useValidation({
    data: formData,
    schema
  })

  useEffect(() => {
    reset()
    setFormData({
      referringCode
    })
  }, [invisible, user, reset, referringCode])

  useEffect(() => {
    setInvisible(!props.open)
  }, [props.open])

  const afterCloseRef = useAsRef(props.afterClose)

  const submit = useCallback(async (e: SyntheticEvent) => {
    EventUtils.preventDefault(e)

    const { isValid } = await validate()

    if (!isValid || !accountID) {
      return
    }

    setLoading(true)
    from(AccountApi.update({
      input: {
        accountID,
        referringAccountReferralCode: formData.referringCode
      }
    }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          MessageService.error(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(() => {
        MessageService.info(`${type} Referral code successfully`)
        setInvisible(true)
        afterCloseRef.current?.(true)
      })
  }, [accountID, afterCloseRef, formData.referringCode, unsubscribe$, validate, type])

  return (
    <>
      <Button
        type="primary"
        {...btnProps}
        onClick={() => setInvisible(false)}
      >
        {props.children || `${type} Referring code`}
      </Button>

      <Modal
        title={props.title || `${type} Referring code`}
        open={!invisible}
        centered
        closable={!loading} // display X icon
        keyboard={false} // disable close on press ESC
        maskClosable={false} // disable close on click outside
        okButtonProps={{ disabled: loading }}
        cancelButtonProps={{ disabled: loading }}
        okText="Ok"
        cancelText="Cancel"
        onOk={submit}
        onCancel={() => setInvisible(true)}
      >
        {!invisible && (
          <form onSubmit={submit} className="fx fx-column fx-extend gap-2">
            <input className="d-none" type="submit"/>

            <Input
              required
              type="text"
              name="referringCode"
              placeholder="Referring code"
              readOnly={loading}
              value={formData.referringCode}
              status={errors.hasError('referringCode') ? 'error' : undefined}
              onChange={(e) => setFormData((prev) => ({
                ...prev,
                referringCode: e.target.value
              }))}
              disabled={loading}
            />
            <ErrorMessage>{errors.getError('referringCode')}</ErrorMessage>
          </form>
        )}
      </Modal>
    </>
  )
}
