import { graphql } from 'relay-runtime'

import { QuestionSetQuery } from './__generated__/QuestionSetQuery.graphql'

export type { QuestionSetQuery }

export type TKycQuestion = DeepWriteable<TArrayItem<QuestionSetQuery['response']['questionSet']['questions']>>

export const questionSetQuery = graphql`
  query QuestionSetQuery($input: GetQuestionSetInput!) {
    questionSet(input: $input) {
      questionSetID
      questions {
        ... on Question {
          id
          display
          type
          options {
            ... on QuestionAnswerOption {
              id
              display
            }
          }
        }
      }
    }
  }
`
