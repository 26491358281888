export enum EEntityType {
  FUND = 'FUND',
  LIMITED_PARTNERSHIP = 'LIMITED_PARTNERSHIP',
  LLC = 'LLC',
  PTE_LTD = 'PTE_LTD',
  TRUST = 'TRUST',
  VARIABLE_CAPITAL_COMPANIES = 'VARIABLE_CAPITAL_COMPANIES'
}

export const EEntityTypeTxt = Object.freeze({
  [EEntityType.FUND]: 'Fund',
  [EEntityType.LIMITED_PARTNERSHIP]: 'Limited Partnership',
  [EEntityType.LLC]: 'LLC (Limited Liability Company)',
  [EEntityType.PTE_LTD]: 'Private Limited Company (Pte Ltd)',
  [EEntityType.TRUST]: 'Trust',
  [EEntityType.VARIABLE_CAPITAL_COMPANIES]: 'Variable Capital Company'
})

export const getEntityTypeTxt = (status?: EEntityType | string) => {
  return typeof status === 'string' ? EEntityTypeTxt[status as EEntityType] || status : ''
}

export enum ESourceOfFund {
  BUSINESS_OPERATIONS = 'BUSINESS_OPERATIONS',
  INVESTMENTS = 'INVESTMENTS',
  OTHERS = 'OTHERS',
  STARTUP_CAPITAL = 'STARTUP_CAPITAL',
  VIRTUAL_ASSETS = 'VIRTUAL_ASSETS'
}

export const ESourceOfFundTxt = Object.freeze({
  [ESourceOfFund.BUSINESS_OPERATIONS]: 'Business Operations',
  [ESourceOfFund.INVESTMENTS]: 'Investments',
  [ESourceOfFund.OTHERS]: 'Others',
  [ESourceOfFund.STARTUP_CAPITAL]: 'Startup Capital',
  [ESourceOfFund.VIRTUAL_ASSETS]: 'Virtual Assets'
})

export const getSourceOfFundTxt = (value?: ESourceOfFund | string) => {
  return typeof value === 'string' ? ESourceOfFundTxt[value as ESourceOfFund] || value : ''
}
