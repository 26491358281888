import { Collapse, CollapseProps } from 'antd'
import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { EMPTY, catchError, from, takeUntil } from 'rxjs'
import { UserApi } from 'src/api'
import { TUser } from 'src/graphql'
import { useUnsubscribeEffect } from 'src/hooks'
import { EPaths } from 'src/modules/market/routes.path'
import { BreadcrumbService, MessageService } from 'src/services'
import { AccountDocuments } from '../account-documents'
import { ReferralInfo } from '../referral-info'
import { UserProfile } from '../user-profile'

export const BusinessUserInfo: FC = () => {
  const { businessId, userId } = useParams()
  const [user, setUser] = useState<TUser | null>(null)

  /**
   * breadcrumb
   */
  useEffect(() => {
    BreadcrumbService.items = [
      {
        route: EPaths.MARKET_BUSINESS,
        label: 'Business'
      },
      {
        route: [EPaths.MARKET_BUSINESS_DETAIL, { id: businessId }],
        label: businessId
      },
      {
        route: [EPaths.MARKET_BUSINESS_USER_DETAIL, { businessId, userId }],
        label: userId
      }
    ]
    return () => {
      BreadcrumbService.items = []
    }
  }, [businessId, userId])

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: 'User profile',
      children: <UserProfile user={user}/>
    },
    {
      key: '2',
      label: 'Document',
      children: <AccountDocuments accountID={user?.account.id}/>
    },
    {
      key: '3',
      label: 'Referrals',
      children: <ReferralInfo user={user}/>
    }
  ]

  useUnsubscribeEffect((unsubscribe$) => {
    if (!userId) return

    from(UserApi.find(userId))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          MessageService.error(error)
          return EMPTY
        })
      )
      .subscribe((user) => {
        setUser(user)
      })
  }, [userId])

  return (
    <section className="fx fx-column fx-extend">
      <Collapse
        items={items}
        defaultActiveKey={['1']}
      />
    </section>
  )
}
