export enum EAdminPortalPermission {
  ADMIN_PORTAL_ADMIN = 'ADMIN_PORTAL_ADMIN',
  ADMIN_PORTAL_REFERRAL = 'ADMIN_PORTAL_REFERRAL',
  ADMIN_PORTAL_ENGINEERING = 'ADMIN_PORTAL_ENGINEERING',
  ADMIN_PORTAL_COMPLIANCE = 'ADMIN_PORTAL_COMPLIANCE',
  ADMIN_PORTAL_USER = 'ADMIN_PORTAL_USER'
}

export const EAdminPortalPermissionTxt = Object.freeze({
  [EAdminPortalPermission.ADMIN_PORTAL_ADMIN]: 'Admin',
  [EAdminPortalPermission.ADMIN_PORTAL_REFERRAL]: 'Referral',
  [EAdminPortalPermission.ADMIN_PORTAL_ENGINEERING]: 'Engineering',
  [EAdminPortalPermission.ADMIN_PORTAL_COMPLIANCE]: 'Compliance',
  [EAdminPortalPermission.ADMIN_PORTAL_USER]: 'User'
})

export const getAdminPortalPermissionTxt = (value?: EAdminPortalPermission | string) => {
  return typeof value === 'string' ? EAdminPortalPermissionTxt[value as EAdminPortalPermission] || value : ''
}
