import { FC } from 'react'
import { useNavigate } from 'react-router'
import { finalize, from, takeUntil } from 'rxjs'
import { AuthApi } from 'src/api'
import { Loading } from 'src/atoms'
import { useQueryParams, useUnsubscribeEffect } from 'src/hooks'
import { ERoutes, generate } from 'src/router'

export const AuthConfirm: FC = () => {
  const navigate = useNavigate()
  const { cnf } = useQueryParams<{ cnf?: string }>()

  useUnsubscribeEffect((unsubscribe$) => {
    if (!cnf) {
      return navigate(generate(ERoutes.LOGIN))
    }

    from(AuthApi.confirm({ cnf }))
      .pipe(
        takeUntil(unsubscribe$),
        // catchError((error) => {
        //   console.log({ error })
        //   message.error(AxiosUtils.getResponseErrorMessage(error))
        //   return EMPTY
        // }),
        finalize(() => navigate(generate(ERoutes.LOGIN)))
      )
      .subscribe()
  }, [cnf])

  return (
    <div className="fx fx-column">
      <Loading show/>
    </div>
  )
}
