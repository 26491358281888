import { Descriptions, DescriptionsProps } from 'antd'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { EMPTY, catchError, from, takeUntil } from 'rxjs'
import { ReferralApi } from 'src/api'
import { TAccountRaffleInfo, TReferralStatistics, TUser } from 'src/graphql'
import { useUnsubscribe } from 'src/hooks'
import { DialogAddRaffleTickets } from 'src/partials'
import { MessageService } from 'src/services'
import { NumberUtils } from 'src/utils'

interface IProps {
  user: TUser | null
}

export const ReferralInfo: FC<IProps> = ({ user }) => {
  const unsubscribe$ = useUnsubscribe()
  const [referralStatistics, setReferralStatistics] = useState<TReferralStatistics['data'] | null>(null)
  const [accountRaffleInfo, setAccountRaffleInfo] = useState<TAccountRaffleInfo | null>(null)

  const fetchAccountRaffleInfo = useCallback(() => {
    if (!user?.accountID) return
    from(ReferralApi.accountRaffleInfo({ accountID: user.accountID }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          MessageService.error(error)
          return EMPTY
        })
      )
      .subscribe((res) => {
        setAccountRaffleInfo(res)
      })
  }, [unsubscribe$, user?.accountID])

  useEffect(() => {
    if (!user?.accountID) return
    from(ReferralApi.referralStatistics({ accountID: user.accountID }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          MessageService.error(error)
          return EMPTY
        })
      )
      .subscribe((userIdentity) => {
        setReferralStatistics(userIdentity.data)
      })

    fetchAccountRaffleInfo()
  }, [unsubscribe$, user?.accountID, fetchAccountRaffleInfo])

  const items: DescriptionsProps['items'] = useMemo(() => ([
    {
      label: 'Your weekly invite quota',
      children: `${NumberUtils.getValueNumber(referralStatistics?.availableQuota, '--')} / ${NumberUtils.getValueNumber(referralStatistics?.totalQuota, '--')}`
    },
    {
      label: 'Account Referred By',
      children: user?.account?.referringAccount?.id || '--'
    },
    {
      label: 'Tasks Done from task library',
      children: accountRaffleInfo?.doneTasks?.length || 0
    },
    {
      label: 'Your ranking',
      children: NumberUtils.getValueNumber(referralStatistics?.rank, '--')
    },
    {
      label: 'Points phase 1',
      children: NumberUtils.getValueNumber(referralStatistics?.pointsPhaseOne, '--')
    },
    {
      label: 'Points phase 0',
      children: NumberUtils.getValueNumber(referralStatistics?.pointsPhaseZero, '--')
    },
    {
      label: 'Direct invites phase 1',
      children: NumberUtils.getValueNumber(referralStatistics?.directReferralPhaseOne, '--')
    },
    {
      label: 'Direct invites phase 0',
      children: NumberUtils.getValueNumber(referralStatistics?.directReferralPhaseZero, '--')
    },
    {
      label: 'Indirect invites phase 1',
      children: NumberUtils.getValueNumber(referralStatistics?.indirectReferralPhaseOne, '--')
    },
    {
      label: 'Indirect invites phase 0',
      children: NumberUtils.getValueNumber(referralStatistics?.indirectReferralPhaseZero, '--')
    },
    {
      label: 'Raffle tickets',
      children: (
        <div>
          {Math.max((accountRaffleInfo?.totalTickets || 0) - (accountRaffleInfo?.totalUsedTickets || 0), 0)}

          <DialogAddRaffleTickets
            accountID={user?.accountID}
            btnProps={{ size: 'small', type: 'link' }}
            afterClose={(fresh) => fresh && fetchAccountRaffleInfo()}
          >
            Add
          </DialogAddRaffleTickets>
        </div>
      )
    }
  ].map((el, index) => ({
    ...el,
    key: index,
    style: {
      width: '50%'
    }
  }))), [referralStatistics, user, accountRaffleInfo, fetchAccountRaffleInfo])

  return (
    <section className="fx fx-column fx-extend">
      <Descriptions layout="vertical" size="small" column={2} bordered items={items}/>
    </section>
  )
}
