/**
 * @generated SignedSource<<3e0dbfbe55449a006d6ab28e1142d40e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type StatusCode = "ABORTED" | "ALREADY_EXISTS" | "CANCELLED" | "DATA_LOSS" | "DEADLINE_EXCEEDED" | "FAILED_PRECONDITION" | "INTERNAL" | "INVALID_ARGUMENT" | "NOT_FOUND" | "OK" | "OUT_OF_RANGE" | "PERMISSION_DENIED" | "RESOURCE_EXHAUSTED" | "UNAUTHENTICATED" | "UNAVAILABLE" | "UNIMPLEMENTED" | "UNKNOWN" | "%future added value";
export type AccountIdentityQuery$variables = {
  id: string;
};
export type AccountIdentityQuery$data = {
  readonly accountIdentity: {
    readonly accountIdentity: {
      readonly companyName: string | null;
      readonly companyRegistrationNumber: string | null;
      readonly countryOfIncorporation: string | null;
      readonly dateOfIncorporation: string | null;
      readonly entityType: string | null;
      readonly registeredOfficeAddress: string | null;
      readonly sourceOfFunds: string | null;
    } | null;
    readonly code: StatusCode;
    readonly message: string;
  };
};
export type AccountIdentityQuery = {
  response: AccountIdentityQuery$data;
  variables: AccountIdentityQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "AccountIdentityStatus",
    "kind": "LinkedField",
    "name": "accountIdentity",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "code",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountIdentity",
        "kind": "LinkedField",
        "name": "accountIdentity",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "companyName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "countryOfIncorporation",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "companyRegistrationNumber",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dateOfIncorporation",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "registeredOfficeAddress",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "entityType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sourceOfFunds",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountIdentityQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AccountIdentityQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "09f665bcf35dce98f0c35dc8fdf910a2",
    "id": null,
    "metadata": {},
    "name": "AccountIdentityQuery",
    "operationKind": "query",
    "text": "query AccountIdentityQuery(\n  $id: ID!\n) {\n  accountIdentity(id: $id) {\n    code\n    message\n    accountIdentity {\n      companyName\n      countryOfIncorporation\n      companyRegistrationNumber\n      dateOfIncorporation\n      registeredOfficeAddress\n      entityType\n      sourceOfFunds\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "20c870fc774e204852901ba38604b318";

export default node;
