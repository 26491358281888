import { graphql } from 'relay-runtime'

import { InviteAdminPortalUserMutation } from './__generated__/InviteAdminPortalUserMutation.graphql'

export type { InviteAdminPortalUserMutation }

export type TAdminInvitee = DeepWriteable<DeepExclude<InviteAdminPortalUserMutation['response']['inviteAdminPortalUser']['invitee'], null>>

export const inviteAdminPortalUserMutation = graphql`
  mutation InviteAdminPortalUserMutation($input: CreateAdminPortalInviteeInput!) {
    inviteAdminPortalUser(input: $input) {
      code
      message
      invitee {
        ... on AdminPortalInvitee {
          id
          createTime
          updateTime
          adminPortalUserID
          email
          invitationStatus
          # invitingAdminPortalUser {
          #   ... on AdminPortalUser {}
          # }
        }
      }
    }
  }
`
