export class NumberUtils {
  /**
   * Format a Number by locales, default en-EN
   */
  static formatNumber(
    value?: number | string | null,
    options?: Intl.NumberFormatOptions & {
      locales?: string | string[]
    }
  ) {
    if (value === null || value === undefined) {
      return ''
    }
    const { locales } = options || {}
    return new Intl.NumberFormat(locales || 'en-EN', options).format(+value)
  }

  static getValueNumber<T = unknown>(value: any, defaultValue: T = 0 as T) {
    return typeof value === 'number' ? NumberUtils.formatNumber(value) : defaultValue
  }
}
