export const KYC_APPROVE_ATTENTION = (
  <div className="p-2 border-r_4" style={{ maxHeight: '50vh', overflowY: 'auto', backgroundColor: '#D9D9D9' }}>
    Attention: Compliance Personnel
    <br/>
    <br/>
    Before you proceed to approve this Know Your Customer (KYC) request, please take a moment to consider the gravity of your decision. Approving a KYC means that you are attesting to the accuracy, completeness, and legitimacy of the submitted information. Your approval signifies your commitment to upholding the highest standards of compliance, regulatory adherence, and data protection.
    <br/>
    <br/>
    By approving this KYC request, you are agreeing to the following:
    <ul>
      <li>Accuracy and Verification: You confirm that you have diligently verified the authenticity of the provided information, ensuring that it aligns with the required regulatory standards and our internal compliance policies.</li>
      <li>Legal Compliance: You acknowledge that the submitted data complies with all applicable laws, regulations, and standards, including those related to Anti-Money Laundering (AML), Counter-Terrorism Financing (CTF), and data protection.</li>
      <li>Risk Assessment: You understand that your approval impacts our risk assessment and management processes. Your careful evaluation helps us identify potential risks and make informed decisions that safeguard our operations and reputation.</li>
      <li>Data Privacy: You recognize the sensitivity of the personal and business identity information submitted in this KYC. By approving, you commit to treating this information with the utmost confidentiality and protecting it from unauthorized access.</li>
      <li>Audit and Accountability: You are aware that your approval leaves an audit trail that can be reviewed internally or by regulatory authorities. Your decision contributes to our commitment to transparency and accountability.</li>
    </ul>
    <br/>
    Your approval plays a pivotal role in maintaining the integrity of our compliance efforts and fostering trust with our stakeholders. As a compliance professional, your judgment and diligence significantly impact our ability to operate ethically and responsibly.
    <br/>
    <br/>
    If you are confident that the submitted information meets our compliance criteria and regulatory requirements, you may proceed to approve the KYC request. However, if you have any doubts or concerns, we strongly encourage you to seek guidance from your compliance team or supervisor before proceeding.
    <br/>
    <br/>
    Thank you for your dedication to maintaining our high compliance standards. Your commitment ensures that GRVT Exchange remains a trusted and responsible financial institution.
    <br/>
    <br/>
    By clicking "Approve," you affirm that you have thoroughly reviewed and understood the implications of your decision.
  </div>
)

export const KYC_REJECT_ATTENTION = (
  <div className="p-2 border-r_4" style={{ maxHeight: '50vh', overflowY: 'auto', backgroundColor: '#D9D9D9' }}>
    Attention: Compliance Personnel
    <br/>
    <br/>
    Before you proceed to reject this Know Your Customer (KYC) request, please be aware of the implications of your decision. Rejecting a KYC request requires thoughtful consideration to ensure that our actions are consistent with our compliance standards and regulatory obligations.
    <br/>
    <br/>
    By rejecting this KYC request, you are indicating that the submitted information does not meet our compliance criteria or regulatory requirements. This decision could be based on factors such as incomplete or inaccurate information, non-compliance with anti-money laundering (AML) guidelines, or other relevant concerns.
    <br/>
    <br/>
    Your rejection is an essential step in maintaining the integrity of our compliance processes and risk management efforts. It contributes to our commitment to upholding regulatory standards and protecting our operations and reputation.
    <br/>
    <br/>
    If you are certain that the information provided in the KYC request does not align with our compliance requirements, you may proceed to reject the request. However, if you have any doubts or concerns, we encourage you to consult with your compliance team or supervisor before making a final decision.
    <br/>
    <br/>
    Thank you for your dedication to maintaining our high compliance standards. Your diligence ensures that GRVT Exchange remains a trusted and responsible financial institution.
    <br/>
    <br/>
    By clicking "Reject," you acknowledge the significance of your decision and affirm that you have considered the implications.
  </div>
)
