import { Button, Col, Form, Input, message } from 'antd'
import { FC, useCallback, useState } from 'react'
import { useNavigate } from 'react-router'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { AUTH_FALLBACK_KEY } from 'src/constants'
import { useUnsubscribe } from 'src/hooks'
import { GoogleSignInBtn } from 'src/partials'
import { AuthService } from 'src/services'
import { AxiosUtils, StorageUtils } from 'src/utils'

export const Login: FC = () => {
  const navigate = useNavigate()
  const unsubscribe$ = useUnsubscribe()
  const [loading, setLoading] = useState(false)

  const onSubmit = useCallback((values: {
    email: string
    password: string
  }) => {
    setLoading(true)
    from(AuthService.signIn(values))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          console.log({ error })
          message.error(AxiosUtils.getResponseErrorMessage(error))
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(() => {
        const toURL = StorageUtils.getItem<string>(AUTH_FALLBACK_KEY)
        if (toURL) {
          StorageUtils.removeItem(AUTH_FALLBACK_KEY)
          navigate(toURL)
        }
      })
  }, [navigate, unsubscribe$])

  return (
    <section className="fx-center">
      <Col
        xs={24}
        sm={{ span: 16 /** , offset: 4 */ }}
        md={{ span: 12 /** , offset: 6 */ }}
        lg={{ span: 10 /** , offset: 7 */ }}
        xl={{ span: 8 /** , offset: 8 */ }}
        xxl={{ span: 6 /** , offset: 9 */ }}
      >
        <Form
          name="login-form"
          autoComplete="off"
          initialValues={{}}
          onFinish={onSubmit}
          onFinishFailed={(errors) => console.log({ errors })}
        >
          <Form.Item
            name="email"
            rules={[{ required: true, message: 'Please input your email!' }]}
          >
            <Input placeholder="Email" autoComplete="off"/>
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password placeholder="Password" autoComplete="off"/>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              block
              disabled={loading}
            >
              Sign In
            </Button>
          </Form.Item>

          <Form.Item>
            <GoogleSignInBtn
              block
              disabled={loading}
            >
              Sign in with Google
            </GoogleSignInBtn>
          </Form.Item>

          {/* <Form.Item>
            <MicrosoftSignInBtn
              block
              disabled={loading}
            >
              Microsoft
            </MicrosoftSignInBtn>
          </Form.Item> */}
        </Form>
      </Col>
    </section>
  )
}
