import axios, { AxiosRequestConfig } from 'axios'
import { TAxiosResponseData } from 'types/axios'

export class S3Service {
  static presignedUpload<T extends Blob>(presignedUrl: string, file: T, requestConfig?: AxiosRequestConfig<T>): TAxiosResponseData<void> {
    return axios.put(presignedUrl, file, {
      ...requestConfig,
      headers: { 'Content-Type': file.type }
    })
  }
}
