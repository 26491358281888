import { Space, Tag } from 'antd'
import { Link } from 'react-router-dom'
import { EDocumentKey, TAccountDocuments } from 'src/graphql'
import { DialogDocumentPreview } from 'src/partials'

const getFileExtension = (url?: string | null) => {
  return url?.match(/\.([^./?]+)($|\?)/)?.[1] || ''
}

interface Actions {
  onUploadDocuments?: (files: File[], documentKey: EDocumentKey) => void
}

export const renderColumns = ({ onUploadDocuments }: Actions) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    width: 80,
    render: (_: any, record: TAccountDocuments) => <Tag color="red">{getFileExtension(record.url)}</Tag>
  },
  {
    title: 'Link',
    dataIndex: 'url',
    key: 'url',
    ellipsis: true,
    render: (url: string) => <Link target="_blank" to={url} title={url} className="oneline-text">{url}</Link>
  },
  {
    title: 'Create time',
    dataIndex: 'createTime',
    key: 'createTime',
    width: 200,
    ellipsis: true
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    width: 200,
    render: (_: any, record: TAccountDocuments) => (
      <Space>
        <DialogDocumentPreview
          url={record.url}
          fileExtension={getFileExtension(record.url)}
          btnProps={{
            size: 'small',
            type: 'link'
          }}
        />

        {record.url && (
          <Link
            target="_blank"
            to={record.url}
            title={record.url}
          >
            Download
          </Link>
        )}
      </Space>
    )
  }
]
