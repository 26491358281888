import { graphql } from 'relay-runtime'

import { KolStatisticsQuery } from './__generated__/KolStatisticsQuery.graphql'

export type { KolStatisticsQuery }

export type TKolStatisticsStatus = Partial<DeepExclude<DeepWriteable<KolStatisticsQuery>, null>['response']['kolStatistics']>
export type TKolStatistics = Partial<TArrayItem<TKolStatisticsStatus['data']>>

export const kolStatisticsQuery = graphql`
  query KolStatisticsQuery($input: KOLStatisticsInput!) {
    kolStatistics(input: $input) {
      data {
        ...on KOLStatistic {
          accountID
          email
          name
          joinedDate
          tier
          totalDirectReferrals
          totalIndirectReferrals
          totalDirectPhase0Referrals
          totalIndirectPhase0Referrals
          totalDirectPhase1Referrals
          totalIndirectPhase1Referrals
          totalPoints
          totalPhase0Points
          totalPhase1Points
          rank
          projectedTotalCodes
          projectedEfficiencyRate
        }
      }
      total
    }
  }
`
