export const renderColumns = () => [
  {
    title: 'Comment',
    dataIndex: 'comment',
    key: 'comment'
  },
  {
    title: 'Previous status & details',
    dataIndex: 'pre-status',
    key: 'pre-status'
  },
  {
    title: 'New status & details',
    dataIndex: 'new-status',
    key: 'new-status'
  },
  {
    title: 'Added by',
    dataIndex: 'added-by',
    key: 'added-by'
  },
  {
    title: 'Added time',
    dataIndex: 'added-time',
    key: 'added-time'
  }
]
