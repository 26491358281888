/**
 * @generated SignedSource<<dc2e988bfb75cecfae157ed7ecb297a6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CountryDocumentCcDocumentType = "driving_license" | "national_identity_card" | "other" | "passport" | "residence_permit" | "%future added value";
export type CountryEditLogAction = "documents" | "nationality_status" | "nationality_tier" | "residency_status" | "residency_tier" | "%future added value";
export type CountryNationalityStatus = "active" | "inactive" | "%future added value";
export type CountryStatus = "active" | "inactive" | "%future added value";
export type SupportedCountriesQuery$variables = {};
export type SupportedCountriesQuery$data = {
  readonly supportedCountries: {
    readonly countries: ReadonlyArray<{
      readonly code: string;
      readonly createTime: any | null;
      readonly documents: ReadonlyArray<{
        readonly ccDocumentType: CountryDocumentCcDocumentType;
        readonly countryID: string;
        readonly createTime: any | null;
        readonly id: string;
        readonly updateTime: any | null;
      }> | null;
      readonly editLogs: ReadonlyArray<{
        readonly action: CountryEditLogAction;
        readonly comment: string | null;
        readonly countryID: string;
        readonly createTime: any | null;
        readonly data: string;
        readonly editorEmail: string;
        readonly editorID: string;
        readonly id: string;
        readonly updateTime: any | null;
      }> | null;
      readonly id: string;
      readonly name: string;
      readonly nationalityStatus: CountryNationalityStatus;
      readonly nationalityTier: number;
      readonly residencyTier: number;
      readonly status: CountryStatus;
      readonly updateTime: any | null;
    }>;
  };
};
export type SupportedCountriesQuery = {
  response: SupportedCountriesQuery$data;
  variables: SupportedCountriesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createTime",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updateTime",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "countryID",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ListCountries",
    "kind": "LinkedField",
    "name": "supportedCountries",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Country",
        "kind": "LinkedField",
        "name": "countries",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nationalityTier",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "residencyTier",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nationalityStatus",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CountryDocument",
            "kind": "LinkedField",
            "name": "documents",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ccDocumentType",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CountryEditLog",
            "kind": "LinkedField",
            "name": "editLogs",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "editorID",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "editorEmail",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "action",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "comment",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "data",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SupportedCountriesQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SupportedCountriesQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "113b5a85dbf501adf06052d41c232468",
    "id": null,
    "metadata": {},
    "name": "SupportedCountriesQuery",
    "operationKind": "query",
    "text": "query SupportedCountriesQuery {\n  supportedCountries {\n    countries {\n      id\n      createTime\n      updateTime\n      code\n      name\n      nationalityTier\n      residencyTier\n      status\n      nationalityStatus\n      documents {\n        id\n        createTime\n        updateTime\n        countryID\n        ccDocumentType\n      }\n      editLogs {\n        id\n        createTime\n        updateTime\n        countryID\n        editorID\n        editorEmail\n        action\n        comment\n        data\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "93d8c47c33d162c62b6c6548c7c8b6b8";

export default node;
