import { graphql } from 'relay-runtime'

import { ConfigCountryMutation } from './__generated__/ConfigCountryMutation.graphql'

export type { ConfigCountryMutation }

export const configCountryMutation = graphql`
  mutation ConfigCountryMutation($input: ConfigCountryInput!) {
    configCountry(input: $input) {
      data {
        ... on Country {
          id
          createTime
          updateTime
          code
          name
          nationalityTier
          residencyTier
          status
          nationalityStatus
          documents {
            ... on CountryDocument {
              id
              createTime
              updateTime
              countryID
              ccDocumentType
            }
          }
          editLogs {
            ... on CountryEditLog {
              id
              createTime
              updateTime
              countryID
              editorID
              editorEmail
              action
              comment
              data
            }
          }
        }
      }
    }
  }
`
