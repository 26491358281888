import { Button, Modal } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { ComponentProps, FC, SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { EcoRewardApi } from 'src/api'
import { ErrorMessage } from 'src/atoms'
import { TAdminInvitee } from 'src/graphql'
import { useAsRef, useUnsubscribe, useValidation } from 'src/hooks'
import { MessageService } from 'src/services'
import { EventUtils } from 'src/utils'
import { object, string } from 'yup'

const schema = object().shape({
  data: string().required('Data is required')
})

export const DialogBulkReward: FC<Omit<
  ComponentProps<typeof Modal>,
  'onOk' | 'afterClose'
> & {
  btnProps?: ComponentProps<typeof Button>
  afterClose?: (saved?: boolean) => any
}> = ({
  btnProps,
  ...props
}) => {
  const unsubscribe$ = useUnsubscribe()
  const [loading, setLoading] = useState(false)
  const [invisible, setInvisible] = useState<boolean | TAdminInvitee>(true)
  const [formData, setFormData] = useState({
    data: ''
  })

  const afterCloseRef = useAsRef(props.afterClose)

  const { validate, errors, reset } = useValidation({
    data: formData,
    schema
  })

  useEffect(() => {
    reset()
    setFormData({
      data: ''
    })
  }, [invisible, reset])

  useEffect(() => {
    setInvisible(!props.open)
  }, [props.open])

  const submit = useCallback(async (e: SyntheticEvent) => {
    EventUtils.preventDefault(e)

    const { isValid } = await validate()
    if (!isValid) {
      return
    }

    const payload = formData.data.split('\n').map((line) => {
      const [accountID, points] = line.trim().split(/\s+/)
      return { accountID, points: parseInt(points) }
    })
    if (payload.some(({ accountID, points }) =>
      !/^ACC:[a-zA-Z0-9]{27}$/.test(accountID) ||
      !/^[0-9]+$/.test(points.toString()))
    ) {
      MessageService.error('Invalid data format')
      return
    }

    const promise = EcoRewardApi.addEcosystemPoints({
      point_distributions: payload.map(({ accountID, points }) => ({
        account_id: accountID,
        giving_point: points
      }))
    })
    setLoading(true)
    from(promise)
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          MessageService.error(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(() => {
        MessageService.info('You have successfully awarded Ecosystem Points to the selected users.')
        setInvisible(true)
        afterCloseRef.current?.(true)
      })
  }, [unsubscribe$, formData, afterCloseRef, validate])

  return (
    <>
      <Button
        type="primary"
        {...btnProps}
        onClick={() => setInvisible(false)}
      >
        {props.children || 'Bulk Reward'}
      </Button>

      <Modal
        title={props.title || 'Reward Ecosystem Points'}
        open={!invisible}
        centered
        closable={!loading} // display X icon
        keyboard={false} // disable close on press ESC
        maskClosable={false} // disable close on click outside
        okButtonProps={{ disabled: loading }}
        cancelButtonProps={{ disabled: loading }}
        okText="Confirm"
        cancelText="Cancel"
        onOk={submit}
        onCancel={() => setInvisible(true)}
      >
        {!invisible && (
          <form onSubmit={submit} className="fx fx-column fx-extend gap-2">
            <input className="d-none" type="submit"/>

            <TextArea
              required
              name="data"
              rows={5}
              placeholder={'Enter Account IDs and rewards, example:\nACC:2aAohu0OsdBD2L08yQG4wYvH20c 1000\nACC:2aAohu0OsdBD2L08yQG4wYvH20c 1000\nACC:2aAohu0OsdBD2L08yQG4wYvH20c 1000\n...'}
              readOnly={loading}
              value={formData.data}
              status={errors.hasError('data') ? 'error' : undefined}
              onChange={(e) => setFormData((prev) => ({
                ...prev,
                data: e.target.value
              }))}
            />
            <ErrorMessage>{errors.getError('data')}</ErrorMessage>
          </form>
        )}
      </Modal>
    </>
  )
}
