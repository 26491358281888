/**
 * @generated SignedSource<<47a50ad33ecc1760e3e9b0f865ed26a2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ShortLinkActiveStatus = "activated" | "deactivated" | "%future added value";
export type StatusCode = "ABORTED" | "ALREADY_EXISTS" | "CANCELLED" | "DATA_LOSS" | "DEADLINE_EXCEEDED" | "FAILED_PRECONDITION" | "INTERNAL" | "INVALID_ARGUMENT" | "NOT_FOUND" | "OK" | "OUT_OF_RANGE" | "PERMISSION_DENIED" | "RESOURCE_EXHAUSTED" | "UNAUTHENTICATED" | "UNAVAILABLE" | "UNIMPLEMENTED" | "UNKNOWN" | "%future added value";
export type UpdateShortLinkInput = {
  activeStatus?: ShortLinkActiveStatus | null;
  clearDeleteTime?: boolean | null;
  deleteTime?: any | null;
};
export type UpdateShortLinkMutation$variables = {
  id: string;
  input: UpdateShortLinkInput;
};
export type UpdateShortLinkMutation$data = {
  readonly updateShortLink: {
    readonly code: StatusCode;
    readonly message: string;
  };
};
export type UpdateShortLinkMutation = {
  response: UpdateShortLinkMutation$data;
  variables: UpdateShortLinkMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "BaseStatus",
    "kind": "LinkedField",
    "name": "updateShortLink",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "code",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateShortLinkMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateShortLinkMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b5c63adc4fec0c50c0fc292142e0b754",
    "id": null,
    "metadata": {},
    "name": "UpdateShortLinkMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateShortLinkMutation(\n  $id: ID!\n  $input: UpdateShortLinkInput!\n) {\n  updateShortLink(id: $id, input: $input) {\n    code\n    message\n  }\n}\n"
  }
};
})();

(node as any).hash = "85c1ebb04512fc62d8ad8742386ae9af";

export default node;
