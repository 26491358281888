import { Button, Form, Input, Modal, Select } from 'antd'
import { ComponentProps, FC, useCallback, useEffect, useState } from 'react'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { PiiApi } from 'src/api'
import { TAdminInvitee, TUserPii } from 'src/graphql'
import { useAsRef, useBehaviorMapper, useUnsubscribe } from 'src/hooks'
import { MessageService } from 'src/services'
import { CountriesStore } from 'src/store'
import { StringUtils } from 'src/utils'

type FieldType = {
  addressLineOne: string
  addressLineTwo: string
  city: string
  postalCode: string
  stateOrProvince: string
  country: string
}

export const DialogEditKycAddress: FC<Omit<
  ComponentProps<typeof Modal>,
  'onOk' | 'afterClose'
> & {
  user: TUserPii
  btnProps?: ComponentProps<typeof Button>
  afterClose?: (saved?: boolean) => any
}> = ({
  user,
  btnProps,
  ...props
}) => {
  const unsubscribe$ = useUnsubscribe()
  const [loading, setLoading] = useState(false)
  const [invisible, setInvisible] = useState<boolean | TAdminInvitee>(true)

  const countriesOptions = useBehaviorMapper(CountriesStore.countriesOptions$)

  useEffect(() => {
    setInvisible(!props.open)
  }, [props.open])

  const afterCloseRef = useAsRef(props.afterClose)

  const [form] = Form.useForm<FieldType>()
  useEffect(() => {
    if (!invisible) {
      form.setFieldsValue({
        addressLineOne: user?.currentKyc?.address?.addressLineOne || '',
        addressLineTwo: user?.currentKyc?.address?.addressLineTwo || '',
        city: user?.currentKyc?.address?.city || '',
        postalCode: user?.currentKyc?.address?.postalCode || '',
        stateOrProvince: user?.currentKyc?.address?.stateProvince || '',
        country: user?.currentKyc?.countryOfResidence || ''
      })
    }
  }, [invisible, user, form])

  const submit = useCallback((values: FieldType) => {
    setLoading(true)
    from(PiiApi.adminUpdatePersonalAddress({
      input: {
        userID: user.userID as string,
        accountID: user.accountID as string,
        addressLine1: values.addressLineOne!,
        addressLine2: values.addressLineTwo!,
        city: values.city!,
        postalCode: values.postalCode!,
        stateOrProvince: values.stateOrProvince!,
        country: values.country!
      }
    }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          MessageService.error(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(() => {
        MessageService.info('Edit User Address successfully')
        setInvisible(true)
        afterCloseRef.current?.(true)
      })
  }, [afterCloseRef, unsubscribe$, user.accountID, user.userID])

  return (
    <>
      <Button
        type="primary"
        {...btnProps}
        onClick={() => setInvisible(false)}
      >
        {props.children || 'Edit'}
      </Button>

      <Modal
        title={props.title || 'Edit User Address'}
        open={!invisible}
        centered
        closable={!loading} // display X icon
        keyboard={false} // disable close on press ESC
        maskClosable={false} // disable close on click outside
        okButtonProps={{ disabled: loading }}
        cancelButtonProps={{ disabled: loading }}
        okText="Ok"
        cancelText="Cancel"
        onOk={() => form.submit()}
        onCancel={() => setInvisible(true)}
      >
        {!invisible && (
          <Form
            form={form}
            name="edit-kyc-address"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            onFinish={submit}
            autoComplete="off"
          >
            <Form.Item<FieldType>
              label="Address line one"
              name="addressLineOne"
              rules={[{ required: true }]}
            >
              <Input.TextArea/>
            </Form.Item>

            <Form.Item<FieldType>
              label="Address line two"
              name="addressLineTwo"
            >
              <Input.TextArea/>
            </Form.Item>

            <Form.Item<FieldType>
              label="City"
              name="city"
              rules={[{ required: true }]}
            >
              <Input/>
            </Form.Item>

            <Form.Item<FieldType>
              label="State/Province"
              name="stateOrProvince"
              rules={[{ required: true }]}
            >
              <Input/>
            </Form.Item>

            <Form.Item<FieldType>
              label="Postal Code"
              name="postalCode"
              rules={[{ required: true }]}
            >
              <Input/>
            </Form.Item>

            <Form.Item<FieldType>
              label="Country of residence"
              name="country"
              rules={[{ required: true }]}
            >
              <Select
                allowClear
                showSearch
                options={countriesOptions}
                filterOption={(input, option) => (
                  StringUtils.containsWithoutDiacritics(option?.label, input) ||
                  StringUtils.containsWithoutDiacritics(option?.value, input)
                )}
              />
            </Form.Item>
          </Form>
        )}
      </Modal>
    </>
  )
}
