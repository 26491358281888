import { RewardAxios } from 'src/services'
import { TAxiosResponseData } from 'types/axios'

interface IUserEcosystemPointDistributionData {
  account_id: string
  giving_point: number
}

interface IEcosystemPointDistributionRequest {
  point_distributions: IUserEcosystemPointDistributionData[]
}

export interface IPointDistributionData {
  account_id: string
  epoch_start_time: string
  total_point: number
}

interface IPointDistributionsResponse {
  point_distributions: IPointDistributionData[]
}

export class EcoRewardApi {
  static addEcosystemPoints(payload: IEcosystemPointDistributionRequest): TAxiosResponseData<any> {
    return RewardAxios.post('/api/admin/v1/point-distributions/batch', payload)
  }

  static getListPointDistributions(): TAxiosResponseData<IPointDistributionsResponse> {
    return RewardAxios.get('/api/admin/v1/point-distributions')
  }
}
