import { cloneDeep } from 'lodash'
import {
  Environment,
  GraphQLTaggedNode,
  MutationConfig,
  MutationParameters,
  Observable,
  OperationType,
  commitMutation,
  fetchQuery
} from 'relay-runtime'
import { ignore } from 'src/constants'
import { EdgeEnvironment, PiiEnvironment } from 'src/relay'

enum EGraphqlStatusCode {
  OK = 'OK',
  ABORTED = 'ABORTED',
  ALREADY_EXISTS = 'ALREADY_EXISTS',
  CANCELLED = 'CANCELLED',
  DATA_LOSS = 'DATA_LOSS',
  DEADLINE_EXCEEDED = 'DEADLINE_EXCEEDED',
  FAILED_PRECONDITION = 'FAILED_PRECONDITION',
  INTERNAL = 'INTERNAL',
  INVALID_ARGUMENT = 'INVALID_ARGUMENT',
  NOT_FOUND = 'NOT_FOUND',
  OUT_OF_RANGE = 'OUT_OF_RANGE',
  PERMISSION_DENIED = 'PERMISSION_DENIED',
  RESOURCE_EXHAUSTED = 'RESOURCE_EXHAUSTED',
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  UNAVAILABLE = 'UNAVAILABLE',
  UNIMPLEMENTED = 'UNIMPLEMENTED',
  UNKNOWN = 'UNKNOWN'
}

class _GraphqlService {
  environment: Environment
  constructor(environment: Environment) {
    this.environment = environment
  }

  /**
   * https://relay.dev/docs/v10.1.3/fetch-query/
   */
  query<T extends Omit<OperationType, 'variables'> & { variables?: OperationType['variables'] }>(
    taggedNode: GraphQLTaggedNode,
    variables: T['variables'] = {},
    cacheConfig: Parameters<typeof fetchQuery>[2] = {
      fetchPolicy: 'network-only',
      networkCacheConfig: { force: true }
    }
  ): Observable<T['response']> {
    return fetchQuery(this.environment, taggedNode, variables, cacheConfig)
  }

  async queryAsPromise<T extends Omit<OperationType, 'variables'> & { variables?: OperationType['variables'] }>(
    taggedNode: GraphQLTaggedNode,
    variables: T['variables'] = {},
    cacheConfig?: Parameters<typeof fetchQuery>[2]
  ): Promise<DeepWriteable<T['response']>> {
    return new Promise((resolve, reject) => {
      this.query(taggedNode, variables, cacheConfig)
        .catch((error) => {
          reject(error)
          throw error
        })
        .map((res) => {
          const response = res as Record<string, any>
          const query = Object.keys(response)[0]
          if (!response[query]?.code || response[query]?.code === EGraphqlStatusCode.OK) {
            return resolve(cloneDeep(response) as DeepWriteable<T['response']>)
          }

          return reject(new Error(response[query]?.message))
        })
        .toPromise()
        .catch(ignore)
    })
  }

  async getNode<T = any>(
    taggedNode: Parameters<typeof this['queryAsPromise']>[0],
    variables: { id: string },
    cacheConfig?: Parameters<typeof this['queryAsPromise']>[2]
  ): Promise<T> {
    const response = await this.queryAsPromise(taggedNode, variables, cacheConfig) as Record<string, any>
    return response?.node as T
  }

  /**
   * https://relay.dev/docs/api-reference/commit-mutation/#internaldocs-banner
   */
  mutation<T extends MutationParameters = MutationParameters>(
    config: MutationConfig<T>
  ) {
    return commitMutation(this.environment, config)
  }

  mutationAsPromise<T extends MutationParameters = MutationParameters>(
    mutation: GraphQLTaggedNode,
    variables: T['variables'] = {},
    uploadables?: MutationConfig<T>['uploadables']
  ): Promise<DeepWriteable<T['response']>> {
    return new Promise((resolve, reject) => {
      commitMutation(this.environment, {
        mutation,
        variables,
        uploadables,
        onError: reject,
        onCompleted: (res) => {
          const response = res as Record<string, any>
          const mutation = Object.keys(response)[0]
          if (
            !response[mutation]?.code ||
            response[mutation]?.code === EGraphqlStatusCode.OK ||
            response[mutation]?.data
          ) {
            return resolve(cloneDeep(res))
          }

          return reject(new Error(response[mutation]?.message))
        }
      })
    })
  }

  /**
   * https://relay.dev/docs/api-reference/load-query/
   */
  // load() {
  //   loadQuery()
  // }
}

export const EdgeGraphqlService = new _GraphqlService(EdgeEnvironment)

export const PiiGraphqlService = new _GraphqlService(PiiEnvironment)
