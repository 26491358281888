import { Button, Form, Input, Modal } from 'antd'
import { ComponentProps, FC, SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { AccountApi } from 'src/api'
import { ErrorMessage } from 'src/atoms'
import { useAsRef, useUnsubscribe, useValidation } from 'src/hooks'
import { MessageService } from 'src/services'
import { EventUtils } from 'src/utils'
import { number, object } from 'yup'

const schema = object().shape({
  feeLevel: number()
    .typeError('Fee level must be a number')
    .integer('Fee level must be an integer')
    .min(1, 'Fee level must be greater than or equal to 1')
    .required('Fee level is required')
})

export const DialogFeeLevel: FC<Omit<
  ComponentProps<typeof Modal>,
  'onOk' | 'afterClose'
> & {
  uid?: string
  value?: number
  btnProps?: ComponentProps<typeof Button>
  afterClose?: (saved?: boolean) => any
  accountID?: string
}> = ({
  uid,
  btnProps,
  accountID,
  ...props
}) => {
  const unsubscribe$ = useUnsubscribe()
  const [loading, setLoading] = useState(false)
  const [invisible, setInvisible] = useState(true)
  const [formData, setFormData] = useState<{ feeLevel?: number }>({})
  const { validate, errors, reset } = useValidation({
    data: formData,
    schema
  })
  console.log('index.tsx:38', formData, errors)

  const afterCloseRef = useAsRef(props.afterClose)

  useEffect(() => {
    if (props.value && !invisible) {
      setFormData({ feeLevel: props.value })
    }
    return () => {
      reset()
      setFormData({})
    }
  }, [props.value, invisible, reset])

  useEffect(() => {
    setInvisible(!props.open)
  }, [props.open])

  const onSubmit = useCallback(async (e: SyntheticEvent) => {
    EventUtils.preventDefault(e)

    const { isValid, value } = await validate()

    if (!isValid) {
      return
    }

    if (!value?.feeLevel || !accountID) {
      return
    }

    setLoading(true)
    from(AccountApi.update({
      input: {
        accountID,
        feeLevel: value.feeLevel
      }
    }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          MessageService.error(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(() => {
        MessageService.info('Fee level updated successfully')
        setInvisible(true)
        afterCloseRef.current?.(true)
      })
  }, [accountID, afterCloseRef, unsubscribe$, validate])

  return (
    <>
      <Button
        type="primary"
        {...btnProps}
        onClick={() => setInvisible(false)}
      >
        {props.children || 'Fee level'}
      </Button>

      <Modal
        title={props.title || 'Fee level'}
        open={!invisible}
        centered
        closable={!loading} // display X icon
        keyboard={false} // disable close on press ESC
        maskClosable={false} // disable close on click outside
        okButtonProps={{ disabled: loading }}
        cancelButtonProps={{ disabled: loading }}
        okText="Ok"
        cancelText="Cancel"
        onOk={onSubmit}
        onCancel={() => setInvisible(true)}
      >
        {!invisible && (
          <Form.Item label="Fee level">
            <Input
              required
              type="number"
              placeholder="Fee level"
              readOnly={loading}
              value={formData.feeLevel}
              onChange={(e) => setFormData({ feeLevel: parseInt(e.target.value) || undefined })}
              status={errors.hasError('feeLevel') ? 'error' : undefined}
            />
            <ErrorMessage>{errors.getError('feeLevel')}</ErrorMessage>
          </Form.Item>
        )}
      </Modal>
    </>
  )
}
