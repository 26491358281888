import { BehaviorSubject } from 'rxjs'

export enum SearchParamKey {
  INDIVIDUAL_KYC = 'INDIVIDUAL_KYC',
  BUSINESS_KYB = 'BUSINESS_KYB'
}

export const SearchParamsService = new (
  class {
    private readonly _searchParams$ = new BehaviorSubject<{[k in SearchParamKey]?: string}>({})

    setSearchParams(key: SearchParamKey, value: string) {
      this._searchParams$.next({
        ...this._searchParams$.value,
        [key]: value
      })
    }

    getSearchParams(key: SearchParamKey) {
      return this._searchParams$.value[key] || ''
    }
  }
)()
