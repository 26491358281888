/**
 * @generated SignedSource<<f2c3b54797fff32af024e12d1bbcdab3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PersonalAddressInput = {
  accountID: string;
  addressLine1: string;
  addressLine2?: string | null;
  city: string;
  country: string;
  postalCode: string;
  stateOrProvince?: string | null;
  userID: string;
};
export type AdminUpdatePersonalAddressMutation$variables = {
  input: PersonalAddressInput;
};
export type AdminUpdatePersonalAddressMutation$data = {
  readonly adminUpdatePersonalAddress: {
    readonly data: {
      readonly createTime: any | null;
      readonly id: string;
      readonly updateTime: any | null;
    };
  };
};
export type AdminUpdatePersonalAddressMutation = {
  response: AdminUpdatePersonalAddressMutation$data;
  variables: AdminUpdatePersonalAddressMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "KYCAttemptStatus",
    "kind": "LinkedField",
    "name": "adminUpdatePersonalAddress",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "KYCAttempt",
        "kind": "LinkedField",
        "name": "data",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updateTime",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AdminUpdatePersonalAddressMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AdminUpdatePersonalAddressMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "131249dc96d82ed2eb7c2b54f3a6d263",
    "id": null,
    "metadata": {},
    "name": "AdminUpdatePersonalAddressMutation",
    "operationKind": "mutation",
    "text": "mutation AdminUpdatePersonalAddressMutation(\n  $input: PersonalAddressInput!\n) {\n  adminUpdatePersonalAddress(input: $input) {\n    data {\n      id\n      createTime\n      updateTime\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0c0b8f8f3a8d8221e0f1b3596779ab00";

export default node;
