import { Layout, Menu } from 'antd'
import clsx from 'clsx'
import { ComponentProps, FC, useCallback, useState } from 'react'
import { NavLink, matchPath, useLocation } from 'react-router-dom'
import { useBehaviorMapper } from 'src/hooks'
import LogoSVG from 'src/logo.svg'
import { LayoutService } from 'src/services'
import { IMenuItem, getOpenKeys, menus } from './menus'
import Style from './style.module.scss'

export const LayoutSidebar: FC = (props) => {
  const { pathname } = useLocation()
  const { sidebar } = useBehaviorMapper(LayoutService.layoutConfig$)
  const [defaultOpenKeys] = useState(getOpenKeys(pathname))

  const handleResponsive = useCallback((collapsed: boolean) => {
    LayoutService.setSidebarCollapsed(collapsed)
  }, [])

  const getActiveClassName = useCallback(
    (path?: string) => {
      const _matchPath = path && matchPath(pathname, path)
      return _matchPath ? Style.active : ''
    },
    [pathname]
  )

  const getMenuItems = useCallback((items: IMenuItem[]) => Array.ensure(items).reduce<Required<ComponentProps<typeof Menu>>['items']>(
    (result, item) => {
      if (item.subMenus) {
        const subMenus = getMenuItems(item.subMenus)
        if (!subMenus?.length) {
          return result
        }

        result.push({
          key: item.key,
          title: item.label,
          label: item.label,
          icon: item.icon && <item.icon/>,
          children: subMenus
        })
      } else {
        result.push({
          key: item.key,
          icon: item.icon && <item.icon/>,
          className: clsx(Style.sidebarMenuItem, getActiveClassName(item.pathname)),
          label: (
            <NavLink to={item.pathname || '/'}>
              {item.label}
            </NavLink>
          )
        }
        )
      }

      return result
    }, []
  ), [getActiveClassName])

  return (
    <Layout.Sider
      width={280}
      trigger={null}
      collapsible
      collapsed={sidebar.collapsed}
      collapsedWidth={0}
      className={clsx(Style.sidebar, 'noselect')}
      breakpoint="lg"
      onBreakpoint={handleResponsive}
    >
      <div className={Style.sidebarLogo}>
        <img src={LogoSVG} alt="gravity"/>
      </div>

      <Menu
        theme="dark"
        mode="inline"
        className={clsx(Style.sidebarMenu, 'hide-scrollbar')}
        defaultOpenKeys={defaultOpenKeys}
        selectable={!true}
        items={getMenuItems(menus)}
      />
    </Layout.Sider>
  )
}
