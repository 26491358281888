/**
 * @generated SignedSource<<1171d95da78d135e6396cc7b65fb0367>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateUserIdentityInput = {
  countryOfResidence?: string | null;
  dateOfBirth: string;
  documentNumber: string;
  firstName: string;
  lastName: string;
  nationality: string;
  userID?: string | null;
};
export type AdminUpdateUserIdentityMutation$variables = {
  input: UpdateUserIdentityInput;
};
export type AdminUpdateUserIdentityMutation$data = {
  readonly adminUpdateUserIdentity: {
    readonly data: {
      readonly currentKyc: {
        readonly createTime: any | null;
        readonly id: string;
        readonly updateTime: any | null;
      } | null;
    };
  };
};
export type AdminUpdateUserIdentityMutation = {
  response: AdminUpdateUserIdentityMutation$data;
  variables: AdminUpdateUserIdentityMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "KYCAttempt",
  "kind": "LinkedField",
  "name": "currentKyc",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updateTime",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AdminUpdateUserIdentityMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserIdentityStatus",
        "kind": "LinkedField",
        "name": "adminUpdateUserIdentity",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserIdentity",
            "kind": "LinkedField",
            "name": "data",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AdminUpdateUserIdentityMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserIdentityStatus",
        "kind": "LinkedField",
        "name": "adminUpdateUserIdentity",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserIdentity",
            "kind": "LinkedField",
            "name": "data",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4e9a4d75d80fbd316da860d4f17f5ba3",
    "id": null,
    "metadata": {},
    "name": "AdminUpdateUserIdentityMutation",
    "operationKind": "mutation",
    "text": "mutation AdminUpdateUserIdentityMutation(\n  $input: UpdateUserIdentityInput!\n) {\n  adminUpdateUserIdentity(input: $input) {\n    data {\n      currentKyc {\n        id\n        createTime\n        updateTime\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c1ce49ec2c93e61f635bbce379eed6e4";

export default node;
