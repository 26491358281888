import { CopyOutlined } from '@ant-design/icons'
import { Button, Descriptions, DescriptionsProps, Modal } from 'antd'
import { ComponentProps, FC, useEffect, useMemo, useState } from 'react'
import { TAdminInvitee, TUser } from 'src/graphql'
import { ClipboardService } from 'src/services'

export const DialogReferringAccount: FC<Omit<
  ComponentProps<typeof Modal>,
  'onOk' | 'afterClose'
> & {
  btnProps?: ComponentProps<typeof Button>
  afterClose?: (saved?: boolean) => any
  accountID?: string
  referringAccount: TUser['account']['referringAccount']
}> = ({
  btnProps,
  accountID,
  referringAccount,
  ...props
}) => {
  const [loading] = useState(false)
  const [invisible, setInvisible] = useState<boolean | TAdminInvitee>(true)

  useEffect(() => {
    setInvisible(!props.open)
  }, [props.open])

  const items: DescriptionsProps['items'] = useMemo(() => ([
    {
      key: '1',
      label: 'Account ID',
      children: (
        <div className="fx fx-ai-center gap-1">
          {referringAccount.id}
          <CopyOutlined
            className="pointer"
            onClick={() => ClipboardService.copy(referringAccount.id)}
          />
        </div>
      )
    },
    {
      key: '2',
      label: 'Name',
      children: referringAccount.name
    },
    {
      key: '3',
      label: 'Display name',
      children: referringAccount.displayName
    },
    {
      key: '4',
      label: 'Referral code',
      children: referringAccount.referralCode
    }
  ].map(el => ({
    ...el,
    labelStyle: {
      width: '50%'
    }
  }))), [referringAccount])

  return (
    <>
      <Button
        type="primary"
        {...btnProps}
        onClick={() => setInvisible(false)}
      >
        {props.children || 'Referring account'}
      </Button>

      <Modal
        title={props.title || 'Referring account'}
        open={!invisible}
        centered
        closable={!loading} // display X icon
        keyboard={false} // disable close on press ESC
        maskClosable={false} // disable close on click outside
        okButtonProps={{ disabled: loading }}
        cancelButtonProps={{ style: { display: 'none' } }}
        okText="Close"
        cancelText="Cancel"
        onOk={() => setInvisible(true)}
        onCancel={() => setInvisible(true)}
      >
        <Descriptions layout="horizontal" size="small" column={1} bordered items={items}/>
      </Modal>
    </>
  )
}
