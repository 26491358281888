import { CopyOutlined } from '@ant-design/icons'
import { Descriptions, DescriptionsProps } from 'antd'
import { FC, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { EMPTY, catchError, from, takeUntil } from 'rxjs'
import { UserApi } from 'src/api'
import { TUser, TUserIdentity } from 'src/graphql'
import { useUnsubscribeEffect } from 'src/hooks'
import { ClipboardService, MessageService } from 'src/services'
import { CountriesStore } from 'src/store'

interface IProps {
  user: TUser | null
}

export const UserProfile: FC<IProps> = ({ user }) => {
  const { id } = useParams<{ id: string }>()
  const [userIdentity, setUserIdentity] = useState<TUserIdentity | null>(null)

  useUnsubscribeEffect((unsubscribe$) => {
    if (!id) return
    from(UserApi.userIdentity({ id }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          MessageService.error(error)
          return EMPTY
        })
      )
      .subscribe((userIdentity) => {
        setUserIdentity(userIdentity)
      })
  }, [id])

  const items: DescriptionsProps['items'] = useMemo(() => ([
    {
      key: '1',
      label: 'Display name',
      children: user?.account.displayName
    },
    {
      key: '2',
      label: 'User ID',
      children: user?.id
    },
    {
      key: '3',
      label: 'Account ID',
      children: user?.account.id
        ? (
          <div className="fx fx-ai-center gap-1">
            {user.account.id}
            <CopyOutlined
              className="pointer"
              onClick={() => ClipboardService.copy(user.account.id)}
            />
          </div>
        )
        : '--'
    },
    {
      key: '4',
      label: 'First name',
      children: user?.firstName
    },
    {
      key: '5',
      label: 'Last name',
      children: user?.lastName
    },
    {
      key: '6',
      label: 'Full address',
      children: userIdentity?.fullAddress
    },
    {
      key: '7',
      label: 'Date of birth',
      children: userIdentity?.dateOfBirth
    },
    {
      key: '8',
      label: 'Country of residence',
      children: CountriesStore.getCountryName(userIdentity?.countryOfResidence)
    },
    {
      key: '9',
      label: 'Nationality',
      children: CountriesStore.getCountryName(userIdentity?.nationality)
    },
    {
      key: '10',
      label: 'Country of birth',
      children: CountriesStore.getCountryName(userIdentity?.countryOfBirth)
    }
  ].map(el => ({
    ...el,
    labelStyle: {
      width: '50%'
    }
  }))), [user, userIdentity])

  return (
    <section className="fx fx-column fx-extend">
      <Descriptions layout="horizontal" size="small" column={1} bordered items={items}/>
    </section>
  )
}
