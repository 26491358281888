import { graphql } from 'relay-runtime'

import { DocumentVisibility, GenerateDocumentPreSignedURLQuery } from './__generated__/GenerateDocumentPreSignedURLQuery.graphql'

export type { DocumentVisibility, GenerateDocumentPreSignedURLQuery }

export enum EDocumentKey {
  ACC_FILE_BENEFICIAL_OWNER_DECLARATION_FORM = 'ACC_FILE_BENEFICIAL_OWNER_DECLARATION_FORM',
  ACC_FILE_BOARD_RESOLUTION = 'ACC_FILE_BOARD_RESOLUTION',
  ACC_FILE_CERTIFICATE_OF_INCORPORATION = 'ACC_FILE_CERTIFICATE_OF_INCORPORATION',
  ACC_FILE_CERTIFICATE_OF_INCUMBENCY = 'ACC_FILE_CERTIFICATE_OF_INCUMBENCY',
  ACC_FILE_COMPANY_DETAILS = 'ACC_FILE_COMPANY_DETAILS',
  ACC_FILE_CORPORATE_STRUCTURE_OWNERSHIP_CHART = 'ACC_FILE_CORPORATE_STRUCTURE_OWNERSHIP_CHART',
  ACC_FILE_MEMORANDUM_AND_ARTICLES_OF_ASSOCIATION = 'ACC_FILE_MEMORANDUM_AND_ARTICLES_OF_ASSOCIATION',
  ACC_FILE_REGISTER_OF_SHAREHOLDERS = 'ACC_FILE_REGISTER_OF_SHAREHOLDERS',
  ACC_FILE_REGISTERS_OF_DIRECTORS = 'ACC_FILE_REGISTERS_OF_DIRECTORS',
  USER_FILE_PROOF_OF_ADDRESS = 'USER_FILE_PROOF_OF_ADDRESS',
  USER_FILE_USER_DETAILS = 'USER_FILE_USER_DETAILS',
  STAKEHOLDER_FILE_STAKEHOLDER_DETAILS = 'STAKEHOLDER_FILE_STAKEHOLDER_DETAILS',
  STAKEHOLDER_FILE_STAKEHOLDER_IDS = 'STAKEHOLDER_FILE_STAKEHOLDER_IDS'
}

export const EDocumentTxt = {
  [EDocumentKey.ACC_FILE_BENEFICIAL_OWNER_DECLARATION_FORM]: 'Beneficial owner declaration form',
  [EDocumentKey.ACC_FILE_BOARD_RESOLUTION]: 'Board resolution',
  [EDocumentKey.ACC_FILE_CERTIFICATE_OF_INCORPORATION]: 'Certificate of incorporation',
  [EDocumentKey.ACC_FILE_CERTIFICATE_OF_INCUMBENCY]: 'Certificate of incumbency',
  [EDocumentKey.ACC_FILE_COMPANY_DETAILS]: 'Company details',
  [EDocumentKey.ACC_FILE_CORPORATE_STRUCTURE_OWNERSHIP_CHART]: 'Corporate structure ownership chart & Date & Director signature',
  [EDocumentKey.ACC_FILE_MEMORANDUM_AND_ARTICLES_OF_ASSOCIATION]: 'Memorandum & Articles of Association',
  [EDocumentKey.ACC_FILE_REGISTER_OF_SHAREHOLDERS]: 'Register of shareholders/members',
  [EDocumentKey.ACC_FILE_REGISTERS_OF_DIRECTORS]: 'Register of directors',
  [EDocumentKey.USER_FILE_PROOF_OF_ADDRESS]: 'Proof of address',
  [EDocumentKey.USER_FILE_USER_DETAILS]: 'User details',
  [EDocumentKey.STAKEHOLDER_FILE_STAKEHOLDER_DETAILS]: 'Stakeholder details',
  [EDocumentKey.STAKEHOLDER_FILE_STAKEHOLDER_IDS]: 'Stakeholder IDS'
}

export enum EDocumentVisibility {
  ACCOUNT = 'account',
  USER = 'user',
  STAKEHOLDER = 'stakeholder'
}

export const generateDocumentPreSignedURLQuery = graphql`
  query GenerateDocumentPreSignedURLQuery ($input: GenerateDocumentPreSignedURLInput!) {
    generateDocumentPreSignedURL(input: $input) {
      code
      message
      url
    }
  }
`
