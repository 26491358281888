import { Table } from 'antd'
import { cloneDeep } from 'lodash'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { AccountApi, StakeholderApi } from 'src/api'
import { EDocumentKey, TAccountDocuments, TStakeholderIdentities } from 'src/graphql'
import { useUnsubscribe } from 'src/hooks'
import { MessageService } from 'src/services'
import { renderColumns } from './columns'

interface IProps {
  accountID?: string
}

export const AccountStakeholders: FC<IProps> = ({ accountID }) => {
  const unsubscribe$ = useUnsubscribe()
  const [loading, setLoading] = useState(false)
  const [stakeholders, setStakeholders] = useState<TStakeholderIdentities[]>([])
  const [documents, setDocuments] = useState<TAccountDocuments[]>([])

  const stakeholdersInfo = useMemo(() => {
    const list = cloneDeep(stakeholders).map(stakeholder => ({
      ...stakeholder,
      docs: [] as TAccountDocuments[]
    }))

    documents
      .filter(doc => doc.key === EDocumentKey.STAKEHOLDER_FILE_STAKEHOLDER_IDS)
      .forEach(doc => {
        const stakeholder = list.find(stakeholder => stakeholder.stakeholderID === doc.stakeholderID)

        if (stakeholder) {
          stakeholder.docs.push(doc)
        }
      })

    return list
  }, [documents, stakeholders])

  const fetchStakeholders = useCallback(() => {
    if (!accountID) {
      MessageService.error('Something went wrong!')
      return
    }

    setLoading(true)
    from(StakeholderApi.stakeholderIdentities({
      accountID
    }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          MessageService.error(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe((stakeholders) => {
        setStakeholders(stakeholders)
      })
  }, [accountID, unsubscribe$])

  const fetchDocuments = useCallback(() => {
    if (!accountID) {
      MessageService.error('Something went wrong!')
      return
    }

    setLoading(true)
    from(AccountApi.accountDocuments({
      input: {
        accID: accountID
      }
    }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          MessageService.error(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe((documents) => {
        setDocuments(documents)
      })
  }, [accountID, unsubscribe$])

  useEffect(() => {
    fetchStakeholders()
    fetchDocuments()
  }, [fetchDocuments, fetchStakeholders])

  return (
    <Table
      bordered
      className="fx-extend"
      pagination={false}
      loading={loading}
      dataSource={stakeholdersInfo}
      columns={renderColumns({ accountID, onFresh: fetchDocuments })}
      scroll={{ x: true }}
    />
  )
}
