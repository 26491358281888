import { graphql } from 'relay-runtime'

import { UpdateUserMutation } from './__generated__/UpdateUserMutation.graphql'

export type { UpdateUserMutation }

export const updateUserMutation = graphql`
  mutation UpdateUserMutation($input: UpdateUserByAdminPortalInput!) {
    updateUserByAdminPortal(input: $input) {
      code
      message
    }
  }
`
