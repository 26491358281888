import { FC, useEffect } from 'react'
import { useParams } from 'react-router'
import { BreadcrumbService } from 'src/services'
import { EPaths } from '../../routes.path'

export const MarketingShortLinks: FC = () => {
  const { id } = useParams<{ id: string }>()

  /**
   * breadcrumb
   */
  useEffect(() => {
    BreadcrumbService.items = [{
      route: EPaths.MARKETING_SHORT_LINK,
      label: 'Short Links'
    }]
    return () => {
      BreadcrumbService.items = []
    }
  }, [id])

  return (
    <section>
      <b>Feature under development</b>
    </section>
  )
}
