/**
 * @generated SignedSource<<617ba52ee758460356cb1ff02dfe28d1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type DocumentKey = "ACC_FILE_BENEFICIAL_OWNER_DECLARATION_FORM" | "ACC_FILE_BOARD_RESOLUTION" | "ACC_FILE_CERTIFICATE_OF_INCORPORATION" | "ACC_FILE_CERTIFICATE_OF_INCUMBENCY" | "ACC_FILE_COMPANY_DETAILS" | "ACC_FILE_CORPORATE_STRUCTURE_OWNERSHIP_CHART" | "ACC_FILE_MEMORANDUM_AND_ARTICLES_OF_ASSOCIATION" | "ACC_FILE_REGISTERS_OF_DIRECTORS" | "ACC_FILE_REGISTER_OF_SHAREHOLDERS" | "STAKEHOLDER_FILE_STAKEHOLDER_DETAILS" | "STAKEHOLDER_FILE_STAKEHOLDER_IDS" | "USER_FILE_PROOF_OF_ADDRESS" | "USER_FILE_USER_DETAILS" | "%future added value";
export type StatusCode = "ABORTED" | "ALREADY_EXISTS" | "CANCELLED" | "DATA_LOSS" | "DEADLINE_EXCEEDED" | "FAILED_PRECONDITION" | "INTERNAL" | "INVALID_ARGUMENT" | "NOT_FOUND" | "OK" | "OUT_OF_RANGE" | "PERMISSION_DENIED" | "RESOURCE_EXHAUSTED" | "UNAUTHENTICATED" | "UNAVAILABLE" | "UNIMPLEMENTED" | "UNKNOWN" | "%future added value";
export type AccountDocumentsInput = {
  accID: string;
  documentKeys?: ReadonlyArray<DocumentKey> | null;
};
export type AccountDocumentsQuery$variables = {
  input: AccountDocumentsInput;
};
export type AccountDocumentsQuery$data = {
  readonly accountDocuments: {
    readonly code: StatusCode;
    readonly documents: ReadonlyArray<{
      readonly createTime: string | null;
      readonly id: string | null;
      readonly key: string | null;
      readonly stakeholderID: string | null;
      readonly url: string | null;
    }>;
    readonly message: string;
  };
};
export type AccountDocumentsQuery = {
  response: AccountDocumentsQuery$data;
  variables: AccountDocumentsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AccountDocumentsStatus",
    "kind": "LinkedField",
    "name": "accountDocuments",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "code",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "DocumentURLAndKey",
        "kind": "LinkedField",
        "name": "documents",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "key",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "stakeholderID",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountDocumentsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AccountDocumentsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "02be0d8d3b1c2bcdc007dfd9bdbe30df",
    "id": null,
    "metadata": {},
    "name": "AccountDocumentsQuery",
    "operationKind": "query",
    "text": "query AccountDocumentsQuery(\n  $input: AccountDocumentsInput!\n) {\n  accountDocuments(input: $input) {\n    code\n    message\n    documents {\n      id\n      key\n      url\n      createTime\n      stakeholderID\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e6d0bfb6a97467ea3300899c2c270653";

export default node;
