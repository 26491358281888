import { ComponentProps, FC, useMemo } from 'react'
import ReactInfiniteScrollComponent from 'react-infinite-scroll-component'
import { useDebounce } from 'src/hooks'
import Style from './style.module.scss'

let IFScrollerCount = 0

export const InfiniteScroll: FC<Omit<ComponentProps<typeof ReactInfiniteScrollComponent>, 'scrollableTarget'>> = (props) => {
  const scrollerId = useMemo(() => `__infinite-scroll-${IFScrollerCount++}`, [])

  useDebounce(() => {
    if (props.hasMore) {
      const scrollerEl = document.getElementById(scrollerId)
      if (scrollerEl && scrollerEl.scrollHeight <= scrollerEl.clientHeight) {
        props.next()
      }
    }
  }, 300, [props.dataLength, props.hasMore])

  return (
    <div id={scrollerId} className={Style.infiniteScroll}>
      <ReactInfiniteScrollComponent
        {...props}
        dataLength={props.dataLength || 0}
        scrollableTarget={scrollerId}
      >
        {props.children}
      </ReactInfiniteScrollComponent>
    </div>
  )
}
