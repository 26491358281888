import { graphql } from 'relay-runtime'

import { AccountRaffleInfoQuery } from './__generated__/AccountRaffleInfoQuery.graphql'

export type { AccountRaffleInfoQuery }

export type TAccountRaffleInfo = DeepWriteable<Partial<AccountRaffleInfoQuery['response']['accountRaffleInfo']['data']>>

export const accountRaffleInfoQuery = graphql`
  query AccountRaffleInfoQuery($accountID: ID!) {
    accountRaffleInfo(id: $accountID) {
      data {
        ...on AccountRaffleData {
          totalTickets
          totalUsedTickets
          currentBox {
            ...on MysteryBox {
              id
              name
              rarity
            }
          }
          doneTasks {
            ...on RaffleTask {
              id
              type
              name
              ticketQuantity
            }
          }
        }
      }
    }
  }
`
