import { Button, Input, Modal } from 'antd'
import { ComponentProps, FC, SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { EMPTY, catchError, finalize, from, map, takeUntil } from 'rxjs'
import { ArticleApi, ShortLinkApi } from 'src/api'
import { ErrorMessage } from 'src/atoms'
import { TArticle, TShortLink } from 'src/graphql'
import { useDidMountEffect, useUnsubscribe, useUnsubscribeEffect, useValidation } from 'src/hooks'
import { MessageService } from 'src/services'
import { EventUtils } from 'src/utils'
import { object, string } from 'yup'

const schema = object().shape({
  title: string().typeError('Article title invalid').required('Article title is required'),
  url: string().typeError('Article link invalid').url('Article link invalid').required('Article link is required'),
  shortLinkUrl: string().typeError('Article short link invalid').url('Article short link invalid').required('Article short link is required'),
  description: string().typeError('Description invalid')
  // activeStatus: string().typeError('Status invalid')
})

export const DialogArticle: FC<Omit<
  ComponentProps<typeof Modal>,
  'onOk' | 'afterClose'
> & {
  id?: string
  btnProps?: ComponentProps<typeof Button>
  afterClose?: (doc?: TArticle) => any
}> = ({
  btnProps,
  ...props
}) => {
  const unsubscribe$ = useUnsubscribe()
  const [loading, setLoading] = useState(false)
  const [invisible, setInvisible] = useState<boolean | TArticle>(true)
  const [formData, setFormData] = useState({
    title: '',
    url: '',
    shortLinkUrl: '',
    description: ''
  })

  const { validate, errors, reset } = useValidation({
    data: formData,
    schema
  })

  useEffect(() => {
    reset()
    setFormData({
      title: '',
      url: '',
      shortLinkUrl: '',
      description: ''
    })
  }, [invisible, reset])

  useEffect(() => {
    setInvisible(!props.open)
  }, [props.open])

  useDidMountEffect(() => {
    if (invisible) {
      props.afterClose?.(
        typeof invisible === 'boolean'
          ? undefined
          : invisible
      )
    }
  }, [invisible])

  useUnsubscribeEffect((unsubscribe$) => {
    if (!invisible && props.id) {
      setLoading(true)
      from(ArticleApi.find(props.id))
        .pipe(
          takeUntil(unsubscribe$),
          catchError((error) => {
            MessageService.error(error)
            return EMPTY
          }),
          finalize(() => setLoading(false))
        )
        .subscribe((article) => setFormData({
          title: article.title || '',
          url: article.url || '',
          shortLinkUrl: article.shortLink?.[0]?.url || '',
          description: article.description || ''
        }))
    }
  }, [invisible])

  const sendApi = useCallback(() => {
    return props.id
      ? ArticleApi.update({
        id: props.id,
        input: {
          title: formData.title,
          description: formData.description
        }
      }).then((data) => ({
        ...data,
        article: {
          id: props.id,
          title: formData.title,
          description: formData.description
        }
      }))
      : ArticleApi.create({
        input: {
          url: formData.url,
          title: formData.title,
          description: formData.description
        }
      }).then(async (data) => {
        if (!data.article) {
          throw new Error('Create Article failed')
        }

        const { shortLink } = await ShortLinkApi.create({
          input: {
            url: formData.shortLinkUrl,
            articleID: data.article.id
          }
        })

        data.article.shortLink = shortLink as unknown as TShortLink

        return data
      })
  }, [formData, props.id])

  const submit = useCallback(async (e: SyntheticEvent) => {
    console.log(e)
    EventUtils.preventDefault(e)

    const { isValid } = await validate()

    if (!isValid) {
      return
    }

    setLoading(true)
    from(sendApi())
      .pipe(
        takeUntil(unsubscribe$),
        map((data) => (data.article as TArticle) || true),
        catchError((error) => {
          MessageService.error(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe((doc) => {
        MessageService.info(`${props.id ? 'Update' : 'Create'} Article success`)
        setInvisible(doc)
      })
  }, [validate, sendApi, unsubscribe$, props.id])

  return (
    <>
      <Button
        type="primary"
        {...btnProps}
        onClick={() => setInvisible(false)}
      >
        {props.children || `${props.id ? 'Edit' : 'Add'} Article`}
      </Button>

      <Modal
        title={props.title || `${props.id ? 'Edit' : 'Add'} Article`}
        open={!invisible}
        centered
        closable={!loading} // display X icon
        keyboard={false} // disable close on press ESC
        maskClosable={false} // disable close on click outside
        okButtonProps={{ disabled: loading }}
        cancelButtonProps={{ disabled: loading }}
        okText="Ok"
        cancelText="Cancel"
        onOk={submit}
        onCancel={() => setInvisible(true)}
      >
        {!invisible && (
          <form onSubmit={submit} className="fx fx-column fx-extend gap-2">
            <input className="d-none" type="submit"/>

            <Input
              required
              type="text"
              name="title"
              placeholder="Article title"
              readOnly={loading}
              value={formData.title}
              status={errors.hasError('title') ? 'error' : undefined}
              onChange={(e) => setFormData((prev) => ({
                ...prev,
                title: e.target.value
              }))}
            />
            <ErrorMessage>{errors.getError('title')}</ErrorMessage>

            <Input
              required
              type="text"
              name="url"
              placeholder="Article link"
              readOnly={loading}
              value={formData.url}
              status={errors.hasError('url') ? 'error' : undefined}
              onChange={(e) => setFormData((prev) => ({
                ...prev,
                url: e.target.value
              }))}
            />
            <ErrorMessage>{errors.getError('url')}</ErrorMessage>

            <Input
              required
              type="text"
              name="shortLinkUrl"
              placeholder="Article short link"
              readOnly={loading}
              value={formData.shortLinkUrl}
              status={errors.hasError('shortLinkUrl') ? 'error' : undefined}
              onChange={(e) => setFormData((prev) => ({
                ...prev,
                shortLinkUrl: e.target.value
              }))}
            />
            <ErrorMessage>{errors.getError('shortLinkUrl')}</ErrorMessage>

            <Input.TextArea
              name="description"
              placeholder="Article description"
              readOnly={loading}
              value={formData.description}
              status={errors.hasError('description') ? 'error' : undefined}
              onChange={(e) => setFormData((prev) => ({
                ...prev,
                description: e.target.value
              }))}
            />
            <ErrorMessage>{errors.getError('description')}</ErrorMessage>
          </form>
        )}
      </Modal>
    </>
  )
}
