import { BehaviorSubject } from 'rxjs'
import { StorageUtils } from 'src/utils'
import { WithOutNextComplete } from 'types/rxjs'

interface ILayoutConfig {
  loading: boolean
  sidebar: {
    collapsed: boolean
  }
}

export const LayoutService = new (
  class {
    private readonly _layoutConfig$ = new BehaviorSubject<ILayoutConfig>({
      loading: false,
      sidebar: {
        collapsed: StorageUtils.getItem<boolean>('UI.sidebar.collapsed') ?? window.innerWidth < 992
      }
    })

    get layoutConfig$(): WithOutNextComplete<typeof this._layoutConfig$> {
      return this._layoutConfig$
    }

    setLoading(loading: boolean) {
      this._layoutConfig$.next({
        ...this.layoutConfig$.value,
        loading
      })
    }

    setSidebarCollapsed(collapsed: boolean) {
      const { sidebar } = this.layoutConfig$.value
      StorageUtils.setItem('UI.sidebar.collapsed', collapsed)
      this._layoutConfig$.next({
        ...this.layoutConfig$.value,
        sidebar: {
          ...sidebar,
          collapsed
        }
      })
    }
  }
)()
