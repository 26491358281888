import { Editor, IAllProps } from '@tinymce/tinymce-react'
import { FC, useState } from 'react'
import { EEnv } from 'src/constants'

const CustomEditor = Editor as unknown as React.FC<IAllProps>

interface IProps {
  initialValue: string
  onChange: (value: string) => void
}

export const TinyEditor: FC<IProps> = ({ initialValue, onChange }) => {
  const [value, setValue] = useState(initialValue ?? '')

  return (
    <CustomEditor
      apiKey={EEnv.REACT_APP_TINYMCE_KEY}
      initialValue={initialValue}
      value={value}
      onEditorChange={(newValue) => {
        setValue(newValue)
      }}
      onBlur={() => onChange(value)}
      init={{
        height: 250,
        menubar: false,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount'
        ],
        toolbar: 'undo redo | formatselect | ' +
           'bold italic backcolor | alignleft aligncenter ' +
           'alignright alignjustify | bullist numlist outdent indent | ' +
           'removeformat | help',
        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
      }}
    />
  )
}
