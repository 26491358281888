import { Button, Modal, message } from 'antd'
import { ComponentProps, FC, SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { AdminApi } from 'src/api'
import { ErrorMessage } from 'src/atoms'
import { TAdminPortalUser } from 'src/graphql'
import { useAsRef, useUnsubscribe, useValidation } from 'src/hooks'
import { EAdminPortalPermission } from 'src/interfaces'
import { MessageService } from 'src/services'
import { EventUtils } from 'src/utils'
import { array, object, string } from 'yup'
import { SelectPermissions } from '../select-permissions'

const schema = object().shape({
  permissions: array().of(string().required())
})

export const DialogChangePermissions: FC<Omit<
  ComponentProps<typeof Modal>,
  'onOk' | 'afterClose'
> & {
  uid?: string
  value?: string[]
  btnProps?: ComponentProps<typeof Button>
  afterClose?: (saved?: boolean) => any
}> = ({
  uid,
  value,
  btnProps,
  ...props
}) => {
  const unsubscribe$ = useUnsubscribe()
  const [loading, setLoading] = useState(false)
  const [invisible, setInvisible] = useState<boolean | TAdminPortalUser>(true)
  const [formData, setFormData] = useState<{ permissions?: string[] }>({ permissions: value })
  const afterCloseRef = useAsRef(props.afterClose)

  const { validate, errors, reset } = useValidation({
    data: formData,
    schema
  })

  useEffect(() => {
    reset()
    setFormData({ permissions: value })
  }, [invisible, reset, value])

  useEffect(() => {
    setInvisible(!props.open)
  }, [props.open])

  const submit = useCallback(async (e: SyntheticEvent) => {
    EventUtils.preventDefault(e)

    const { isValid, value } = await validate()

    if (!isValid || !value?.permissions || !uid) {
      MessageService.error('Something went wrong!')
      return
    }

    setLoading(true)
    from(AdminApi.updateAdminPortalUserByAdminPortalUser({
      input: {
        adminPortalUserID: uid,
        permissions: value.permissions as EAdminPortalPermission[]
      }
    }))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          MessageService.error(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(() => {
        message.success('Edit permissions successfully')
        setInvisible(true)
        afterCloseRef.current?.(true)
      })
  }, [afterCloseRef, uid, unsubscribe$, validate])

  return (
    <>
      <Button
        type="primary"
        {...btnProps}
        onClick={() => setInvisible(false)}
      >
        {props.children || 'Change permissions'}
      </Button>

      <Modal
        title={props.title || 'Change permissions'}
        open={!invisible}
        centered
        closable={!loading} // display X icon
        keyboard={false} // disable close on press ESC
        maskClosable={false} // disable close on click outside
        okButtonProps={{ disabled: loading }}
        cancelButtonProps={{ disabled: loading }}
        okText="Ok"
        cancelText="Cancel"
        onOk={submit}
        onCancel={() => setInvisible(true)}
      >
        {!invisible && (
          <form onSubmit={submit} className="fx fx-column fx-extend gap-2">
            <SelectPermissions
              value={formData.permissions}
              onChange={(permissions) => setFormData((prev) => ({
                ...prev,
                permissions
              }))}
            />
            <ErrorMessage>{errors.getError('permissions')}</ErrorMessage>
          </form>
        )}
      </Modal>
    </>
  )
}
