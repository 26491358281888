import { graphql } from 'relay-runtime'

import { AccountDocumentsQuery } from './__generated__/AccountDocumentsQuery.graphql'

export type { AccountDocumentsQuery }

export type TAccountDocuments = Writeable<TArrayItem<AccountDocumentsQuery['response']['accountDocuments']['documents']>>

export const accountDocumentsQuery = graphql`
  query AccountDocumentsQuery($input: AccountDocumentsInput!) {
    accountDocuments(input: $input) {
      code
      message
      documents {
        id
        key
        url
        createTime
        stakeholderID
      }
    }
  }
`
