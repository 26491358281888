import { graphql } from 'relay-runtime'

import { SupportedCountriesQuery } from './__generated__/SupportedCountriesQuery.graphql'

export type { SupportedCountriesQuery }

export type TCountry = DeepWriteable<TArrayItem<SupportedCountriesQuery['response']['supportedCountries']['countries']>>

export const supportedCountriesQuery = graphql`
  query SupportedCountriesQuery {
    supportedCountries {
      countries {
        ... on Country {
          id
          createTime
          updateTime
          code
          name
          nationalityTier
          residencyTier
          status
          nationalityStatus
          documents {
            ... on CountryDocument {
              id
              createTime
              updateTime
              countryID
              ccDocumentType
            }
          }
          editLogs {
            ... on CountryEditLog {
              id
              createTime
              updateTime
              countryID
              editorID
              editorEmail
              action
              comment
              data
            }
          }
        }
      }
    }
  }
`
