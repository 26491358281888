import { graphql } from 'relay-runtime'

import { KycReviewDocumentsQuery } from './__generated__/KycReviewDocumentsQuery.graphql'

export type { KycReviewDocumentsQuery }

export const kycReviewDocumentsQuery = graphql`
  query KycReviewDocumentsQuery($input: KYCReviewDocumentInput!) {
    kycReviewDocuments(input: $input) {
      documents {
        ... on KYCReviewDocument {
          id
          createTime
          updateTime
          uploaderUserID
          url
          type
          comment
        }
      }
    }
  }
`
