import { FC, ReactNode, Suspense, useEffect, useState } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { AUTH_FALLBACK_KEY, ignore } from 'src/constants'
import { useBehaviorMapper } from 'src/hooks'
import { ERoutes, generate, routes } from 'src/router'
import { IRouterOption } from 'src/router/interface'
import { AuthService } from 'src/services'
import { StorageUtils } from 'src/utils'
import Style from './style.module.scss'

const Authenticating: FC<{ children?: ReactNode }> = (props) => {
  const isAuthenticated = useBehaviorMapper(AuthService.isAuthenticated$)

  useEffect(() => {
    isAuthenticated === null && AuthService.getProfile().catch(ignore)
  }, [isAuthenticated])

  if (isAuthenticated === null) {
    return null
  }

  return (<>{props.children}</>)
}

const ForbiddenAuthenticated: FC<{ children: ReactNode }> = ({ children }) => {
  const isAuthenticated = useBehaviorMapper(AuthService.isAuthenticated$)

  /**
   * Only handle first time rendering
   * Self handle change effect and navigation in children component
   */
  const [_isAuthenticated] = useState(isAuthenticated)
  // useEffect(() => {
  //   if (_isAuthenticated) {
  //     message.info('You are already logged in')
  //   }
  // }, [_isAuthenticated])

  return (
    _isAuthenticated === true
      ? <Navigate replace to={generate(ERoutes.HOME)}/>
      : <>{children}</>
  )
}

const RequiredAuthenticated: FC<{ children: ReactNode }> = ({ children }) => {
  const location = useLocation()
  const isAuthenticated = useBehaviorMapper(AuthService.isAuthenticated$)

  if (!isAuthenticated) {
    StorageUtils.setItem(AUTH_FALLBACK_KEY, `${location.pathname}${location.search}`)
    return <Navigate replace to={generate(ERoutes.LOGIN)}/>
  }

  return <>{children}</>
}

/**
 * Default true for all routes
 */
const isMiddlewareNeeded = (route: IRouterOption) => {
  return true
  // const { meta = {} } = route
  // return 'requiredAuth' in meta
}

const getRouteMiddlewareWrapper = (route: IRouterOption) => {
  const { meta = {} } = route
  return meta.requiredAuth !== false
    ? RequiredAuthenticated
    : ForbiddenAuthenticated
}

const RoutesItems = (
  <Routes>
    {Array.ensure(routes).map((item) => {
      if (isMiddlewareNeeded(item)) {
        const MiddlewareWrapper = getRouteMiddlewareWrapper(item)

        return (
          <Route
            key={item.name}
            path={item.path}
            element={(
              <Authenticating>
                <MiddlewareWrapper>
                  <item.component/>
                </MiddlewareWrapper>
              </Authenticating>
            )}
          />
        )
      }

      return (
        <Route
          key={item.name}
          path={item.path}
          element={<item.component/>}
        />
      )
    })}
  </Routes>
)

export const RouterView: FC = () => {
  return (
    <section className={Style.routerView}>
      {/* {RoutesItems} */}
      <Suspense fallback={null}>
        {RoutesItems}
      </Suspense>
    </section>
  )
}
