import { Modal } from 'antd'
import clsx from 'clsx'
import { ComponentProps, FC } from 'react'
import Style from './style.module.scss'

interface IProps extends Omit<ComponentProps<typeof Modal>, 'width' | 'wrapClassName'> {
  wrapClassName?: string
}

export const ModalFullscreen: FC<IProps> = (props) => {
  return (
    <Modal
      {...props}
      wrapClassName={clsx(Style.modalFullscreen, props.wrapClassName)}
      width="100%"
    />
  )
}
