/**
 * @generated SignedSource<<ef17505568a45e2280b2f82a7858b731>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CountryDocumentCcDocumentType = "driving_license" | "national_identity_card" | "other" | "passport" | "residence_permit" | "%future added value";
export type CountryEditLogAction = "documents" | "nationality_status" | "nationality_tier" | "residency_status" | "residency_tier" | "%future added value";
export type CountryNationalityStatus = "active" | "inactive" | "%future added value";
export type CountryStatus = "active" | "inactive" | "%future added value";
export type ConfigCountryInput = {
  comment?: string | null;
  documentTypes?: ReadonlyArray<CountryDocumentCcDocumentType> | null;
  id: string;
  nationalityStatus?: CountryNationalityStatus | null;
  nationalityTier?: number | null;
  residencyStatus?: CountryStatus | null;
  residencyTier?: number | null;
};
export type ConfigCountryMutation$variables = {
  input: ConfigCountryInput;
};
export type ConfigCountryMutation$data = {
  readonly configCountry: {
    readonly data: {
      readonly code: string;
      readonly createTime: any | null;
      readonly documents: ReadonlyArray<{
        readonly ccDocumentType: CountryDocumentCcDocumentType;
        readonly countryID: string;
        readonly createTime: any | null;
        readonly id: string;
        readonly updateTime: any | null;
      }> | null;
      readonly editLogs: ReadonlyArray<{
        readonly action: CountryEditLogAction;
        readonly comment: string | null;
        readonly countryID: string;
        readonly createTime: any | null;
        readonly data: string;
        readonly editorEmail: string;
        readonly editorID: string;
        readonly id: string;
        readonly updateTime: any | null;
      }> | null;
      readonly id: string;
      readonly name: string;
      readonly nationalityStatus: CountryNationalityStatus;
      readonly nationalityTier: number;
      readonly residencyTier: number;
      readonly status: CountryStatus;
      readonly updateTime: any | null;
    };
  };
};
export type ConfigCountryMutation = {
  response: ConfigCountryMutation$data;
  variables: ConfigCountryMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createTime",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updateTime",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "countryID",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CountryConfigurationStatus",
    "kind": "LinkedField",
    "name": "configCountry",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Country",
        "kind": "LinkedField",
        "name": "data",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nationalityTier",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "residencyTier",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nationalityStatus",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CountryDocument",
            "kind": "LinkedField",
            "name": "documents",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ccDocumentType",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CountryEditLog",
            "kind": "LinkedField",
            "name": "editLogs",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "editorID",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "editorEmail",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "action",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "comment",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "data",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ConfigCountryMutation",
    "selections": (v5/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ConfigCountryMutation",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "e0d1ee8a188ea4c5fc7b3c46442a7dc0",
    "id": null,
    "metadata": {},
    "name": "ConfigCountryMutation",
    "operationKind": "mutation",
    "text": "mutation ConfigCountryMutation(\n  $input: ConfigCountryInput!\n) {\n  configCountry(input: $input) {\n    data {\n      id\n      createTime\n      updateTime\n      code\n      name\n      nationalityTier\n      residencyTier\n      status\n      nationalityStatus\n      documents {\n        id\n        createTime\n        updateTime\n        countryID\n        ccDocumentType\n      }\n      editLogs {\n        id\n        createTime\n        updateTime\n        countryID\n        editorID\n        editorEmail\n        action\n        comment\n        data\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0c6f65fef160d51ae5fe4705c290a5ea";

export default node;
