import { Button, Space } from 'antd'
import { Link } from 'react-router-dom'
import { EReferralLevelTxt } from 'src/graphql'
import { generate } from 'src/router'
import { ClipboardService, TzService } from 'src/services'
import { EPaths } from '../../routes.path'

export const renderColumns = () => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    className: 'break-all min-w-150'
  },
  {
    title: 'Account ID',
    dataIndex: 'accountID',
    key: 'accountID',
    className: 'break-all min-w-200',
    render: (accountID: string) => (
      <div
        className="pointer"
        onClick={() => ClipboardService.copy(accountID)}
      >
        {accountID}
      </div>
    )
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    className: 'break-all min-w-200'
  },
  {
    title: 'Date of join',
    dataIndex: 'joinedDate',
    key: 'joinedDate',
    render: (val: string) => TzService.format(Number(val) * 1000, 'YYYY-MM-DD'),
    className: 'wp-no-wrap'
  },
  {
    title: 'Tier',
    dataIndex: 'tier',
    key: 'tier',
    render: (val: string) => EReferralLevelTxt[val] || val,
    className: 'wp-no-wrap'
  },
  {
    title: 'Direct invites',
    dataIndex: 'totalDirectReferrals',
    key: 'totalDirectReferrals',
    width: 140,
    className: 'break-word min-w-100'
  },
  {
    title: 'Indirect invites',
    dataIndex: 'totalIndirectReferrals',
    key: 'totalIndirectReferrals',
    width: 140,
    className: 'break-word min-w-100'
  },
  {
    title: 'Direct invites (Phase 0)',
    dataIndex: 'totalDirectPhase0Referrals',
    key: 'totalDirectPhase0Referrals',
    width: 140,
    className: 'break-word min-w-100'
  },
  {
    title: 'Indirect invites (Phase 0)',
    dataIndex: 'totalIndirectPhase0Referrals',
    key: 'totalIndirectPhase0Referrals',
    width: 140,
    className: 'break-word min-w-100'
  },
  {
    title: 'Direct invites (Phase 1)',
    dataIndex: 'totalDirectPhase1Referrals',
    key: 'totalDirectPhase1Referrals',
    width: 140,
    className: 'break-word min-w-100'
  },
  {
    title: 'Indirect invites (Phase 1)',
    dataIndex: 'totalIndirectPhase1Referrals',
    key: 'totalIndirectPhase1Referrals',
    width: 140,
    className: 'break-word min-w-100'
  },
  {
    title: 'Total points',
    dataIndex: 'totalPoints',
    key: 'totalPoints',
    width: 140,
    className: 'break-word min-w-100'
  },
  {
    title: 'Total points (Phase 0)',
    dataIndex: 'totalPhase0Points',
    key: 'totalPhase0Points',
    width: 140,
    className: 'break-word min-w-100'
  },
  {
    title: 'Total points (Phase 1)',
    dataIndex: 'totalPhase1Points',
    key: 'totalPhase1Points',
    width: 140,
    className: 'break-word min-w-100'
  },
  {
    title: 'Ranking',
    dataIndex: 'rank',
    key: 'rank',
    width: 140,
    className: 'break-word min-w-100'
  },
  {
    title: 'Total codes (Projected)',
    dataIndex: 'projectedTotalCodes',
    key: 'projectedTotalCodes',
    width: 150,
    className: 'break-word min-w-150'
  },
  {
    title: 'Efficiency rate',
    dataIndex: 'projectedEfficiencyRate',
    key: 'projectedEfficiencyRate',
    width: 150,
    className: 'break-word min-w-150',
    render: (val: string) => (Number(val) * 100).toFixed(4) + '%'
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    fixed: 'right' as any,
    render: (_: any, record: any) => (
      <Space>
        <Button
          size="small"
          type="link"
        >
          <Link
            to={generate([EPaths.KOL_REWARD_DETAILS, {
              accountID: record.accountID
            }])}
          >
            Reward Details
          </Link>
        </Button>
      </Space>
    )
  }
]
