import 'src/polyfills'

import React from 'react'
import ReactDOM from 'react-dom/client'
import { App } from './App'
import { EEnv } from './constants'
import { register, unregister } from './registerServiceWorker'

import './styles'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>
)

if (EEnv.NODE_ENV === 'production' && EEnv.REACT_APP_ENABLE_SW) {
  register()
} else {
  unregister()
}
