import { CopyOutlined } from '@ant-design/icons'
import { Descriptions, DescriptionsProps, Tag } from 'antd'
import { FC, useCallback, useMemo } from 'react'
import { TUserPii } from 'src/graphql'
import { ClipboardService, TzService } from 'src/services'
import { CountriesStore } from 'src/store'

interface IProps {
  user?: TUserPii | null
}

export const UserIdentity: FC<IProps> = ({ user }) => {
  const isEdited = useCallback((key?: string) => {
    return user?.currentKyc?.kycActionLogs?.some((log) => {
      try {
        return (
          log.subjectEmail === user.email &&
          Array.ensure(JSON.parse(log.data || '{}')?.field_changes, [])
            .some((change) => change.field_name === key)
        )
      } catch {
        return false
      }
    })
  }, [user?.currentKyc?.kycActionLogs, user?.email])

  const items = useMemo(
    () => [
      {
        key: 'firstName',
        label: 'First name',
        children: user?.firstName || '--' // [user?.firstName, user?.lastName].filter(Boolean).join(' '),
      },
      {
        key: 'lastName',
        label: 'Last name',
        children: user?.lastName || '--'
      },
      {
        key: 'accountID',
        label: 'Account ID',
        children: user?.accountID
          ? (
            <div className="fx fx-ai-center gap-1">
              {user.accountID}
              <CopyOutlined
                className="pointer"
                onClick={() => ClipboardService.copy(user.accountID)}
              />
            </div>
          )
          : '--'
      },
      {
        key: 'userID',
        label: 'User ID',
        children: user?.userID || '--'
      },
      {
        key: 'complyCubeClientID',
        label: 'Comply cube ID',
        children: user?.complyCubeClientID || '--'
      },
      {
        key: 'dateOfBirth',
        label: 'Date of birth',
        children: user?.dateOfBirth
          ? TzService.format(String(user?.dateOfBirth)?.split('T')[0], 'DD MMM, YYYY')
          : '--'
      },
      {
        key: 'nationality',
        label: 'Nationality',
        children: CountriesStore.getCountryName(user?.nationality) || '--'
      },
      {
        key: 'countryOfResidence',
        label: 'Country of residence',
        children: CountriesStore.getCountryName(user?.currentKyc?.countryOfResidence) || '--'
      },
      {
        key: 'documentNumber',
        label: 'Document number',
        children: user?.documentNumber || '--'
      },
      {
        key: 'documentType',
        label: 'Document type',
        children: user?.documentType || '--'
      },
      {
        key: 'email',
        label: 'Email',
        children: user?.email
          ? (
            <div className="fx fx-ai-center gap-1">
              {user.email}
              <CopyOutlined
                className="pointer"
                onClick={() => ClipboardService.copy(user.email)}
              />
            </div>
          )
          : '--'
      }
    ].map((el) => ({
      ...el,
      key: el.key,
      style: { width: '50%' },
      label: isEdited(el.key)
        ? (
          <div className="fx gap-1">
            {el.label}
            <Tag color="orange">Edited</Tag>
          </div>
        )
        : el.label
    })) as DescriptionsProps['items'],
    [user, isEdited]
  )

  return (
    <section>
      <Descriptions
        layout="vertical"
        size="small"
        column={2}
        bordered
        items={items}
      />
    </section>
  )
}
