import { BehaviorSubject } from 'rxjs'
import { PiiApi } from 'src/api'
import { ignore } from 'src/constants'
import { TCountry } from 'src/graphql'
import { WithOutNextComplete } from 'types/rxjs'

export const CountriesStore = new (
  class {
    private readonly _supportedCountries$ = new BehaviorSubject<TCountry[]>([])
    get supportedCountries$(): WithOutNextComplete<typeof this._supportedCountries$> {
      return this._supportedCountries$
    }

    private readonly _countriesOptions$ = new BehaviorSubject<{value: string; label: string}[]>([])
    get countriesOptions$(): WithOutNextComplete<typeof this._countriesOptions$> {
      return this._countriesOptions$
    }

    private readonly _loading$ = new BehaviorSubject(false)
    get loading$(): WithOutNextComplete<typeof this._loading$> {
      return this._loading$
    }

    constructor() {
      this.fetchCountryList()

      this._supportedCountries$.subscribe((countries) => {
        this._countriesOptions$.next(countries.map((country) => ({
          value: country.code,
          label: country.name
        })))
      })
    }

    getCountryName(code?: string | null): string {
      return this._supportedCountries$.value.find((country) => country.code === code)?.name || code || ''
    }

    fetchCountryList() {
      this._loading$.next(true)
      PiiApi.supportedCountries({})
        .then((countries) => this._supportedCountries$.next(
          countries.sort((a, b) => a.name.localeCompare(b.name))
        ))
        .catch((error) => {
          ignore(error)
        })
        .finally(() => {
          this._loading$.next(false)
        })
    }
  }
)()
