/**
 * @generated SignedSource<<a19db39a2b4b5e45dc75dcee6983034f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type BusinessAccountIdentityBusinessType = "CORP" | "INC" | "LLC" | "LLP" | "LP" | "LTD" | "OTHER" | "PLC" | "SP" | "%future added value";
export type SubmitBusinessProfileInput = {
  countryOfIncorporation: string;
  dateOfIncorporation: string;
  dbaName?: string | null;
  email: string;
  fullLegalName: string;
  id?: string | null;
  incorporationNumber: string;
  legalAddress: string;
  phone: string;
  primaryAddress: string;
  type: BusinessAccountIdentityBusinessType;
  website?: string | null;
};
export type AdminUpdateBusinessProfileMutation$variables = {
  input: SubmitBusinessProfileInput;
};
export type AdminUpdateBusinessProfileMutation$data = {
  readonly adminUpdateBusinessProfile: {
    readonly data: {
      readonly id: string;
    };
  };
};
export type AdminUpdateBusinessProfileMutation = {
  response: AdminUpdateBusinessProfileMutation$data;
  variables: AdminUpdateBusinessProfileMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "BusinessAccountIdentityStatus",
    "kind": "LinkedField",
    "name": "adminUpdateBusinessProfile",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BusinessAccountIdentity",
        "kind": "LinkedField",
        "name": "data",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AdminUpdateBusinessProfileMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AdminUpdateBusinessProfileMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "51b3f66f473ff33eb5f797b9e3be8f55",
    "id": null,
    "metadata": {},
    "name": "AdminUpdateBusinessProfileMutation",
    "operationKind": "mutation",
    "text": "mutation AdminUpdateBusinessProfileMutation(\n  $input: SubmitBusinessProfileInput!\n) {\n  adminUpdateBusinessProfile(input: $input) {\n    data {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b9a4f3d4d349265332b81f6eb96369bf";

export default node;
